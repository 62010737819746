import { Dialog } from 'primereact/dialog';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as S from './mapEditAddress.styles';
import { putEditCliCoords } from 'client/api';
import { ClientesProps } from '../../regioes.types';
import { useMap } from '../../regioes.context';
import { MapContainer, TileLayer, Marker, LayersControl, useMap as useRMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import InputText from 'components/Inputs/InputText/text.input';
import { useJsApiLoader } from '@react-google-maps/api';
import redPinMarker from 'assets/svgs/redPin';
import { mapLayersList } from 'components/MapLayers/mapLayers.index';
import { trackGaGeolocation, trackGCatchError } from 'utils/analytics';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';

const redPinIcon = new L.DivIcon({
  html: redPinMarker,
  className: '',
  iconSize: [44, 44],
  iconAnchor: [22, 44],
  popupAnchor: [0, -44],
});

interface IAddressModal {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  cliData?: ClientesProps;
}

interface ICoordinates {
  lat: number;
  lng: number;
}

interface AddressProps {
  desc: string;
  endereco: string;
  numero: string;
  bairro: string;
  cidade: string;
  uf: string;
  cep: string;
}

export default function MapEditAddress({ showModal, setShowModal, cliData }: IAddressModal) {
  try {
    const { handleGetAllData, params, setLocalCliToUpdateCoordinate, allowMapCenterAndZoom, lastCoordinateZoomed } =
      useMap();

    const mapRef: any = useRef(null);

    const { isLoaded } = useJsApiLoader({
      googleMapsApiKey: 'AIzaSyAUHxQUnO76uq2HBu2X6xzaLZPapIFv--0',
      libraries: ['drawing', 'places'],
    });

    const [currentCoords, setCurrentCoords] = useState<ICoordinates>({ lat: 0, lng: 0 });
    const defaultAddress = {
      desc: '',
      endereco: '',
      numero: '',
      bairro: '',
      cidade: '',
      uf: '',
      cep: '',
    };

    const [address, setAddress] = useState<AddressProps>(defaultAddress);

    useEffect(() => {
      if (currentCoords.lat === undefined || currentCoords.lng === undefined) {
        setCurrentCoords({ lat: 0, lng: 0 });
      }
      if (cliData)
        setCurrentCoords({
          lat: cliData.LATITUDE.includes('.') ? +cliData.LATITUDE : 0,
          lng: cliData.LONGITUDE.includes('.') ? +cliData.LONGITUDE : 0,
        });
    }, [showModal]);

    const geolocalizar = () => {
      let query = '';
      query = address.endereco !== '' ? address.endereco : '';
      query = address.numero !== '' && query !== '' ? query + ', ' + address.numero : query + address.numero;
      query = address.bairro !== '' && query !== '' ? query + ', ' + address.bairro : query + address.bairro;
      query = address.cidade !== '' && query !== '' ? query + ', ' + address.cidade : query + address.cidade;
      query = address.uf !== '' && query !== '' ? query + ', ' + address.uf : query + address.uf;
      query = address.cep !== '' && query !== '' ? query + ', ' + address.cep : query + address.cep;

      query = query
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replaceAll('%', '%25');

      const geocoder = new window.google.maps.Geocoder();

      trackGaGeolocation();
      geocoder.geocode({ address: query }, (results, status) => {
        if (status === 'OK') {
          let location = results[0].geometry.location;
          const filteredResults =
            results.length > 1
              ? results.filter(
                  (result: any) =>
                    result.formatted_address && result.formatted_address.toUpperCase().includes(address.cidade),
                )
              : [];
          location = filteredResults.length > 0 ? filteredResults[0].geometry.location : location;
          const coords = { lat: location.lat() ?? 0, lng: location.lng() ?? 0 };
          setCurrentCoords(coords);
        } else {
          toast.error('Endereço não localizado!');
          console.error('Geocode was not successful for the following reason:', status);
        }
      });
    };

    useEffect(() => {
      if (cliData != null && showModal) {
        setAddress({
          desc: cliData.CODCLI && cliData.RAZAOSOCIAL ? `[${cliData.CODCLI}] - ${cliData.RAZAOSOCIAL}` : '',
          endereco: cliData.ENDLOGRADOURO,
          numero: cliData.ENDNUMERO,
          bairro: cliData.ENDBAIRRO,
          cidade: cliData.ENDCIDADE,
          uf: cliData.ENDUF ?? '',
          cep: cliData.END_CEP ?? '',
        });
        if (mapRef.current != null) {
          mapRef.current.flyTo({ lat: +(cliData.LATITUDE ?? 0), lng: +(cliData.LONGITUDE ?? 0) }, 18, {
            animate: true,
            duration: 1.2,
          });
        }
      }
    }, [cliData, showModal]);

    const clearAllData = () => {
      setCurrentCoords({ lat: 0, lng: 0 });
      setAddress(defaultAddress);
    };

    const DraggableMarker = () => {
      const [dragging, setDragging] = useState(false);

      const markerRef = useRef<L.Marker>(null);

      const handleDragStart = () => setDragging(true);
      const handleDragEnd = () => {
        const marker = markerRef.current;
        if (marker) {
          setCurrentCoords(marker.getLatLng());
          setDragging(false);
        }
      };

      return (
        <Marker
          draggable
          position={currentCoords}
          icon={redPinIcon}
          ref={markerRef}
          eventHandlers={{
            dragstart: handleDragStart,
            dragend: handleDragEnd,
          }}
        />
      );
    };

    async function handleGeoRegister() {
      try {
        if (!address.desc) {
          toast.error('Campo "Descrição" não preenchido!');
          return;
        }

        if (!cliData) {
          toast.error('Dados do cliente inconsistentes!');
          setAddress({
            desc: cliData.CODCLI && cliData.RAZAOSOCIAL ? `[${cliData.CODCLI}] - ${cliData.RAZAOSOCIAL}` : '',
            endereco: cliData.ENDLOGRADOURO,
            numero: cliData.ENDNUMERO,
            bairro: cliData.ENDBAIRRO,
            cidade: cliData.ENDCIDADE,
            uf: cliData.ENDUF ?? '',
            cep: cliData.END_CEP ?? '',
          });
          if (mapRef.current != null) {
            mapRef.current.flyTo({ lat: +(cliData.LATITUDE ?? 0), lng: +(cliData.LONGITUDE ?? 0) }, 18, {
              animate: true,
              duration: 1.2,
            });
          }
        }

        if (+cliData.LATITUDE == currentCoords.lat && +cliData.LONGITUDE == currentCoords.lng) {
          toast.warn('Coordenadas não foram alteradas!');
          return;
        }

        const toEditCliCoordsPointParams = {
          codCli: cliData.CODCLI,
          latitude: currentCoords.lat.toString().slice(0, 20),
          longitude: currentCoords.lng.toString().slice(0, 20),
          codEmpresa: params.codEmpresa ? params.codEmpresa.toString() : cliData.COD_EMPRESA.toString(),
        };

        putEditCliCoords(toEditCliCoordsPointParams)
          .then((res: any) => {
            lastCoordinateZoomed.current = [
              +toEditCliCoordsPointParams.latitude,
              +toEditCliCoordsPointParams.longitude,
            ];
            toast.success('Coordendada editado com sucesso!');
            setShowModal(false);
            handleGetAllData();
            setLocalCliToUpdateCoordinate({
              codCli: toEditCliCoordsPointParams.codCli,
              lat: +toEditCliCoordsPointParams.latitude,
              lng: +toEditCliCoordsPointParams.longitude,
            });
          })
          .catch((err: Error) => {
            toast.error('Falha ao editar Coordendada!');
          });
      } catch (err) {
        toast.error(err.message ? `Falha inesperada: ${err.message}` : 'Falha inesperada ao editar coordenadas');
      }
    }

    useEffect(() => {
      if (mapRef.current) {
        mapRef.current.setView([+currentCoords.lat, +currentCoords.lng], 17, {
          animate: true,
        });
      }
    }, [currentCoords]);

    function UpdateMapCenter({ center }: { center: [number, number] }) {
      const map = useRMap();
      useEffect(() => {
        if (center) {
          map.setView(center);
        }
      }, [center, map]);
      return null;
    }

    return (
      <div>
        <Dialog
          header={'Editar Coordenadas do Cliente'}
          visible={showModal}
          onHide={() => {
            setShowModal(false);
            clearAllData();
          }}
          style={{ width: '50vw' }}
          breakpoints={{ '960px': '75vw', '641px': '100vw' }}
        >
          <S.ModalMainBox>
            <S.ModalSubBox>
              <InputText
                label="Descrição *"
                id="desc"
                value={address.desc}
                onChange={(e) => {
                  setAddress((prev: AddressProps) => ({ ...prev, desc: e }));
                }}
                width="100%"
                placeholder="Descrição"
              />

              <InputText
                id="endereco"
                value={address.endereco}
                onChange={(e) => {
                  setAddress((prev: AddressProps) => ({ ...prev, endereco: e }));
                }}
                placeholder="Endereço"
                label="Endereço"
                width="100%"
              />

              <S.InputRow className="d-flex">
                <InputText
                  id="numero"
                  value={address.numero}
                  onChange={(e) => {
                    setAddress((prev: AddressProps) => ({ ...prev, numero: e }));
                  }}
                  placeholder="Número"
                  label="Número"
                  width="47%"
                />

                <InputText
                  id="bairro"
                  value={address.bairro}
                  onChange={(e) => {
                    setAddress((prev: AddressProps) => ({ ...prev, bairro: e }));
                  }}
                  placeholder="Bairro"
                  label="Bairro"
                  width="47%"
                />
              </S.InputRow>

              <S.InputRow className={'d-flex'}>
                <InputText
                  id="cidade"
                  value={address.cidade}
                  onChange={(e) => {
                    setAddress((prev: AddressProps) => ({ ...prev, cidade: e }));
                  }}
                  placeholder="Cidade"
                  label="Cidade"
                  width="47%"
                />

                <InputText
                  id="uf"
                  value={address.uf}
                  onChange={(e) => {
                    setAddress((prev: AddressProps) => ({ ...prev, uf: e }));
                  }}
                  placeholder="UF"
                  label="UF"
                  width="47%"
                />
              </S.InputRow>

              <InputText
                id="cep"
                value={address.cep}
                onChange={(e) => {
                  setAddress((prev: AddressProps) => ({ ...prev, cep: e }));
                }}
                placeholder="CEP"
                label="CEP"
                width="47%"
              />

              <S.InputRow className="d-flex">
                <Button
                  text="Geolocalizar"
                  icon={<icons.MapPin />}
                  disabled={address.desc === '' && address.endereco === ''}
                  color="blue"
                  tooltip={
                    address.desc === '' && address.endereco === ''
                      ? 'Descrição e ou endereço não informados'
                      : 'Geolocalizar'
                  }
                  onClick={() => geolocalizar()}
                />
                <Button
                  text="Salvar"
                  icon={<icons.Check />}
                  color="green"
                  tooltip={
                    address.desc === '' && address.endereco === '' ? 'Descrição e ou endereço não informados' : 'Salvar'
                  }
                  disabled={address.desc === '' && address.endereco === ''}
                  onClick={() => handleGeoRegister()}
                />
              </S.InputRow>
            </S.ModalSubBox>

            <S.ModalSubBox>
              <S.InputRow className="d-flex">
                <InputText
                  id="Lat"
                  value={currentCoords.lat}
                  onChange={(e) =>
                    setCurrentCoords((prev: any) => {
                      return {
                        ...prev,
                        lat: e,
                      };
                    })
                  }
                  placeholder="Lat"
                  label="Lat"
                  width="47%"
                />

                <InputText
                  id="Lng"
                  value={currentCoords.lng}
                  onChange={(e) =>
                    setCurrentCoords((prev: any) => {
                      return {
                        ...prev,
                        lng: e,
                      };
                    })
                  }
                  placeholder="Lng"
                  label="Lng"
                  width="47%"
                />
              </S.InputRow>
              <MapContainer center={currentCoords} zoom={18} style={{ height: '40vh', width: '100%' }}>
                <LayersControl position="topright">
                  {mapLayersList?.map((layer, i) => {
                    return (
                      <LayersControl.BaseLayer checked={i == 0} name={layer.name}>
                        <TileLayer url={layer.url} />
                      </LayersControl.BaseLayer>
                    );
                  })}
                </LayersControl>
                <DraggableMarker />
                <UpdateMapCenter center={[currentCoords.lat, currentCoords.lng]} />
              </MapContainer>
            </S.ModalSubBox>
          </S.ModalMainBox>
        </Dialog>
      </div>
    );
  } catch (err) {
    trackGCatchError(err, 'mapEditAddress.index.tsx');
  }
}
