import { BoxRow, BoxTable } from 'components/Containers';
import styled from 'styled-components';

export const PedListTableBox = styled(BoxTable)`
  flex: 1;
  display: flex;
  flex-direction: column;

  .p-datatable-tbody > tr:hover {
    background-color: #00000008 !important;
    cursor: pointer;
  }

  .p-datatable {
    height: 100% !important;
    width: 100% !important;
  }

  .danger-row {
    color: var(--v-red) !important;
    &:hover {
      background-color: var(--v-red-20) !important;
    }
  }
  .info-row {
    color: var(--v-blue) !important;
    &:hover {
      background-color: var(--v-blue-20) !important;
    }
  }
  .warning-row {
    color: var(--v-orange) !important;
    &:hover {
      background-color: var(--v-orange-20) !important;
    }
  }
`;

export const PedListTableHeader = styled(BoxRow)`
  background-color: transparent;
  box-shadow: none;
  justify-content: space-between;
`;

interface IMenuItemActive {
  active: boolean;
}

export const FloatMenuItem = styled(BoxRow)<IMenuItemActive>`
  box-shadow: none;
  background-color: transparent;
  justify-content: flex-start;
  align-items: center;
  gap: 7.5px;
  white-space: nowrap;
  color: ${(props) => (props.active ? '#000' : '#60606062')};

  &:hover {
    background-color: #00000010;
    cursor: pointer;
  }
`;

interface IPedTagProps {
  textColor: string;
  bgColor: string;
}

export const PedTag = styled(BoxRow)<IPedTagProps>`
  box-shadow: none;
  justify-content: flex-start;
  align-items: center;
  gap: 7.5px;
  white-space: nowrap;
  color: ${(props) => (props.textColor ? props.textColor : '#60606062')};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#00000010')};
  font-size: 0.85rem;
  border-radius: 1rem;
  padding: 3px 10px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
