import { useState, useEffect } from 'react';
import style from './novoPedido.module.css';
import { MdReceipt } from 'react-icons/md';
import ProdList from './components/prodList/prodList';
import useProds from 'providers/produtosProvider';
import * as S from './styles';
import { alterPageTitle } from 'utils/alterPageTitle';
import { trackGPageView } from 'utils/analytics';

export default function NovoPedido() {
  const [pedCli, setPedCli] = useState(null);

  const [prodList, setProdList] = useState([]);

  useEffect(() => {
    trackGPageView('/pedido/novo');
    alterPageTitle('Novo Pedido');
  }, []);

  useEffect(() => {
    getProdData();
  }, []);

  async function getProdData() {
    const prodsReponse = await useProds.getAllProds(pedCli);
    let prods = prodsReponse.prods ? prodsReponse.prods : [];
    for (let i = 0; i < prods.length; i++) {
      prods[i].PVENDA = +prods[i].PVENDA.toFixed(2);
      prods[i].DESC = 0;

      prods[i].EMB = '1X12';
      prods[i].UN = 'UN';

      prods[i].QTD = 0;
      //   cartProdsList.find((el) => el.CODPROD == prods[i].CODPROD) != undefined
      //     ? cartProdsList.find((el) => el.CODPROD == prods[i].CODPROD).QTD
      //     : 0;

      prods[i].SUBTOTAL = +prods[i].PVENDA.toFixed(2) * prods[i].QTD;

      prods[i].PUNIT = 'R$' + +(prods[i].PVENDA / prods[i].QTUNITCX).toFixed(2);

      prods[i].ESTOQUE = prods[i].CODPROD.toString().includes('6') ? 0 : 1;
      prods[i].ESTOQUE_CRITICO =
        prods[i].CODPROD.toString().includes('2') && prods[i].CODPROD.toString().includes('4') ? 'S' : 'N';
    }

    setProdList(prods);
  }

  return (
    <S.NovoPedidoMainBox>
      <section className={style.newPedPaymentData}>
        <div className={style.clientPedData}>
          <div className={style.clientInfoDados}>
            <MdReceipt color={'#F64f3d'} />
            <span>
              {pedCli != null && pedCli.CLIENTE} <br />
              {pedCli != null && pedCli.CNPJCPF}
              <br />
            </span>
          </div>

          <div className={style.paymentInputBox}>
            <label>Filial </label>
            <select>
              <option>F1</option>
              <option>F2</option>
              <option>F3</option>
            </select>
          </div>

          <div className={style.paymentInputBox}>
            <label>Vendedor </label>
            <select>
              <option>9001 - Fulano</option>
              <option>9002 - Cicrano</option>
              <option>9003 - Beltrano</option>
            </select>
          </div>

          <div className={style.paymentInputBox}>
            <label>Forma PG / Cobrança</label>
            <select>
              <option>Cartão Crédito</option>
              <option>Boleto</option>
              <option>PIX</option>
            </select>
          </div>

          <div className={style.paymentInputBox}>
            <label>
              Prazo PG <span style={{ fontSize: '.7rem' }}>[Mín R$250,00]</span>
            </label>
            <select>
              <option>1x sem Juros</option>
              <option>2x sem Juros</option>
              <option>3x sem Juros</option>
            </select>
          </div>
        </div>

        <div className={style.pedTotalData}>
          <div className={style.pedTotalDataHeader}>
            <span>VERBA</span>
            <span>CAIXA</span>
            <span>COMBO</span>
            <span className={style.totalqtd}>TOTAL</span>
          </div>
          <div className={style.pedTotalDataValues}>
            <span>R$00,00</span>
            <span>R$00,00</span>
            <span>R$00,00</span>
            <span>R$00,00</span>
            <span className={style.totalqtd}>R$00,00</span>
          </div>
        </div>
      </section>

      <ProdList cliente={pedCli} prodList={prodList} />
    </S.NovoPedidoMainBox>
  );
}
