export default function formatCurrency(value: number | string) {
  if (!value) return '0,00';
  
  // Convert to number and fix decimal places
  let numericValue = parseFloat(value.toString().replace(/[^0-9,.]/g, '').replace(',', '.'));
  if (isNaN(numericValue)) return '0,00';
  
  // Format number with two decimal places
  numericValue = Math.floor(numericValue * 100) / 100; // Ensure two decimal precision without rounding issues
  let formattedValue = numericValue.toFixed(2).toString();
  
  // Split integer and decimal parts
  let [integerPart, decimalPart] = formattedValue.split('.');
  
  // Add thousand separators
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  
  return `${formattedInteger},${decimalPart}`;
}