/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, ReactNode, useContext, useState } from 'react';

export interface PageTitleProviderProps {
  children: ReactNode;
}

export interface PageTitleProps {
  text: string;
  customObject: any;
  tree: any[] | null;
}

type PageTitleListProps = {
  [key: string]: PageTitleProps;
};

export interface PageTitleContextProps {
  pageTitle: PageTitleProps;
  setPageTitle: React.Dispatch<React.SetStateAction<PageTitleProps>>;
  pageTitlesList: PageTitleListProps;
}

export const PageTitleContext = createContext({} as PageTitleContextProps);

export const PageTitleProvider = ({ children }: PageTitleProviderProps) => {
  const [pageTitle, setPageTitle] = useState<PageTitleProps>({
    text: '',
    customObject: null,
    tree: null,
  });

  const pageTitlesList: PageTitleListProps = {
    dashboard: {
      text: 'Dashboard',
      customObject: null,
      tree: null,
    },
    sql: {
      text: 'VMais SQL',
      customObject: null,
      tree: null,
    },
    'itens-menu': {
      text: 'Cadastro Menu',
      customObject: null,
      tree: null,
    },
    'acesso-usuarios/redefinir-acesso': {
      text: 'Redefinir Acessos de Usuário',
      customObject: null,
      tree: null,
    },
    empresas: {
      text: 'Cadastro Empresas',
      customObject: null,
      tree: null,
    },
    'empresas/adicionar': {
      text: 'Cadastrar Empresa',
      customObject: null,
      tree: [
        {
          text: 'Empresas',
          icon: '',
          path: '/empresas',
        },
      ],
    },
    'empresas/editar': {
      text: 'Editar Empresa',
      customObject: null,
      tree: [
        {
          text: 'Empresas',
          icon: '',
          path: '/empresas',
        },
      ],
    },
    'foto-produto': {
      text: 'Foto Produto',
      customObject: null,
      tree: null,
    },
    'grupo-clientes': {
      text: 'Grupo de Clientes',
      customObject: null,
      tree: null,
    },
    'grupo-clientes/listar/membros': {
      text: 'Grupo:',
      customObject: null,
      tree: [
        {
          text: 'Grupo de Clientes',
          icon: '',
          path: '/grupo-clientes',
        },
      ],
    },
    'acesso-usuarios': {
      text: 'Acessos do Usuário',
      customObject: null,
      tree: null,
    },
    'parametros/vendedor/lista': {
      text: 'Parâmetros do Vendedor',
      customObject: null,
      tree: null,
    },
    'parametros/vendedor/editar': {
      text: 'Editar parâmetros do Vendedor',
      customObject: null,
      tree: [
        {
          text: 'Parâmetros Vendedor',
          icon: '',
          path: '/parametros/vendedor/lista',
        },
      ],
    },
    'parametros/geral/lista': {
      text: 'Parâmetros Gerais',
      customObject: null,
      tree: null,
    },
    'parametros/geral/configurar': {
      text: 'Configurar Parâmetros Gerais',
      customObject: null,
      tree: [
        {
          text: 'Parâmetros Gerais',
          icon: '',
          path: '/parametros/geral/lista',
        },
      ],
    },
    formulario: {
      text: 'FV Formulários',
      customObject: null,
      tree: null,
    },
    respostas: {
      text: 'Respostas Formulários',
      customObject: null,
      tree: null,
    },
    'respostas/itens': {
      text: 'Itens',
      customObject: null,
      tree: [
        {
          text: 'Respostas Formulário',
          icon: '',
          path: '/respostas',
        },
      ],
    },
    'pesquisas/analise-dados': {
      text: 'Análise Formulários',
      customObject: null,
      tree: null,
    },
    mapa: {
      text: 'Mapas / Setor',
      customObject: null,
      tree: null,
    },
    'mapa/novo': {
      text: 'Novo Mapa',
      customObject: null,
      tree: [
        {
          text: 'Mapas / Setor',
          icon: '',
          path: '/mapa',
        },
      ],
    },
    'mapa/editar': {
      text: 'Editar Mapa',
      customObject: null,
      tree: [
        {
          text: 'Mapas / Setor',
          icon: '',
          path: '/mapa',
        },
      ],
    },
    'mapa/regioes': {
      text: 'Mapa / Regioes',
      customObject: null,
      tree: [
        {
          text: 'Roteiro',
          icon: '',
          path: 'mapa',
        },
      ],
    },
    roteirizador: {
      text: 'Roteirizador',
      customObject: null,
      tree: [
        {
          text: 'Mapas / Regioes',
          icon: '',
          path: '/mapa',
        },
      ],
    },
    'agenda-vendedor': {
      text: 'Agenda do Vendedor',
      customObject: null,
      tree: [
        {
          text: 'Mapa / Regioes',
          icon: '',
          path: '/mapa',
        },
        {
          text: 'Roteirizador',
          icon: '',
          path: null,
        },
      ],
    },
    'cadastro/ponto': {
      text: 'Meus Lugares',
      customObject: null,
      tree: [
        {
          text: 'Mapa / Regioes',
          icon: '',
          path: '/mapa',
        },
        {
          text: 'Roteirizador',
          icon: '',
          path: null,
        },
      ],
    },
    'controle-visitas': {
      text: 'Painel GPS',
      customObject: null,
      tree: null,
    },
    'analise-visitas': {
      text: 'Análise Localização',
      customObject: null,
      tree: null,
    },
    'mapa/acompanhamento-diario': {
      text: 'Acompanhamento',
      customObject: null,
      tree: null,
    },
    pedido: {
      text: 'Pedido de Venda',
      customObject: null,
      tree: null,
    },
    kpi: {
      text: 'KPI',
      customObject: null,
      tree: null,
    },
    'kpi/configurar': {
      text: "Configurar KPI's",
      customObject: null,
      tree: [
        {
          text: 'KPI',
          icon: '',
          path: '/kpi',
        },
      ],
    },
    verbas: {
      text: 'Controle de Verbas',
      customObject: null,
      tree: null,
    },
    'verbas/extrato/': {
      text: 'Extrato',
      customObject: null,
      tree: [
        {
          text: 'Controle de Verbas',
          icon: '',
          path: '/verbas',
        },
      ],
    },
    'verbas/extrato/supervisor': {
      text: 'Extrato do Supervisor',
      customObject: null,
      tree: [
        {
          text: 'Controle de Verbas',
          icon: '',
          path: '/verbas',
        },
      ],
    },
    'verbas/extrato/vendedor': {
      text: 'Extrato do Vendedor',
      customObject: null,
      tree: [
        {
          text: 'Controle de Verbas',
          icon: '',
          path: '/verbas',
        },
      ],
    },
    relatorios: {
      text: 'Gerador de Relatorios',
      customObject: null,
      tree: null,
    },
    'relatorios/apurar': {
      text: 'Apurar Relatorio',
      customObject: null,
      tree: [
        {
          text: 'Gerador de Relatorios',
          icon: '',
          path: '/relatorios',
        },
      ],
    },
    'relatorios/novo': {
      text: 'Novo Relatório',
      customObject: null,
      tree: [
        {
          text: 'Gerador de Relatorios',
          icon: '',
          path: '/relatorios',
        },
      ],
    },
    'relatorios/editar': {
      text: 'Editar Relatório',
      customObject: null,
      tree: [
        {
          text: 'Gerador de Relatorios',
          icon: '',
          path: '/relatorios',
        },
      ],
    },
    'regras-comerciais/campanhas': {
      text: 'Campanhas',
      customObject: null,
      tree: null,
    },
    'regras-comerciais/mix-sugerido': {
      text: 'Mix Sugerido',
      customObject: null,
      tree: null,
    },
    'regras-comerciais/itens': {
      text: 'Lista de Itens',
      customObject: null,
      tree: null,
    },
  };

  return (
    <PageTitleContext.Provider
      value={{
        pageTitle,
        setPageTitle,
        pageTitlesList,
      }}
    >
      {children}
    </PageTitleContext.Provider>
  );
};

export const usePageTitle = () => useContext(PageTitleContext);
