import { Dialog } from 'primereact/dialog';
import * as S from './mapEditSetor.styles';
import { SetStateAction, useEffect, useState } from 'react';
import { Button } from 'reactivus';
import { toast } from 'react-toastify';
import { editSetor } from 'client/api';
import { useMap } from '../../regioes.context';
import { SetoresProps } from '../../regioes.types';
import UseSelectColor from 'hooks/UseSelectColor/selectColor.index';
import InputSwitch from 'components/Inputs/InputSwitch/switch.input';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import UseSelectUsuario from 'hooks/UseSelectUsuario/selectUsuario';
import UseSelectSupervisor from 'hooks/UseSelectSupervisor/selectSupervisor';
import UseSelectVendedor from 'hooks/UseSelectVendedor/selectVendedor';
import InputText from 'components/Inputs/InputText/text.input';
import { trackGCatchError } from 'utils/analytics';
import icons from 'components/Icons/icons.index';
import Dropdown from 'components/Dropdown/dropdown';

export default function MapEditSetor({
  showMapEditSetor,
  setShowMapEditSetor,
  setor,
}: {
  showMapEditSetor: boolean;
  setShowMapEditSetor: React.Dispatch<SetStateAction<boolean>>;
  setor: SetoresProps;
}) {
  try {
    const { handleGetAllData, params, allowMapCenterAndZoom } = useMap();

    const { SelectColor, handleUpdateSelectedColorByColorCode } = UseSelectColor();
    const { selectedEmpresa } = UseSelectEmpresa();
    const { selectedUsuario } = UseSelectUsuario(selectedEmpresa.codEmpresa);

    const { SelectSupervisor, selectedSupervisor, supervisores, handleSelectSupervisorByCode } = UseSelectSupervisor(
      selectedEmpresa.codEmpresa,
      selectedUsuario.codUsuario,
    );

    const { SelectVendedor, vendedores, handleSelectVendedorByCode } = UseSelectVendedor(
      selectedEmpresa.codEmpresa,
      selectedUsuario.codUsuario,
      undefined,
      selectedSupervisor.codSupervisor,
    );

    useEffect(() => {
      if (setor && setor.COR) {
        handleUpdateSelectedColorByColorCode(setor.COR ?? '');
      }
      if (setor && setor.COD_RCA) {
        handleSelectVendedorByCode(+setor.COD_RCA);
      }
      if (setor && setor.CODSUP) {
        handleSelectSupervisorByCode(+setor.CODSUP);
      }
    }, [setor, showMapEditSetor]);

    const defaultNewSetorData = {
      codSetor: 0,
      setor: '',
      codRca: 0,
      nomeRca: '',
      ativo: true,
      codFilial: 1,
      codGerente: 0,
      nomeGerente: '',
      codMapa: 0,
      cor: '000000',
      CodEmpresa: 0,
      atualizaAutomatico: true,
    };

    useEffect(() => {
      if (setor) {
        setNewSetorData((prev: any) => {
          return {
            ...prev,
            codSetor: setor.CODSETOR,
            setor: setor.NOMESETOR,
            codRca: setor.COD_RCA,
            nomeRca: setor.NOMERCA,
            ativo: setor.ATIVO == 'S',
            codFilial: 1,
            codGerente: setor.CODSUP,
            nomeGerente: setor.NOMESUP,
            cor: setor.COR,
            atualizaAutomatico: setor.ATUALIZA_AUTOMATICO == 'S',
          };
        });
      }
    }, [setor, showMapEditSetor]);

    const [newSetorData, setNewSetorData] = useState(defaultNewSetorData);

    useEffect(() => {
      setNewSetorData((prev: any) => {
        return {
          ...prev,
          codMapa: params.codMapa,
          CodEmpresa: params.codEmpresa,
        };
      });
    }, [params]);

    const DialogFooterCadastrarSetor = (
      <S.BoxButtons>
        <Button
          icon={<icons.Check />}
          label="Salvar"
          iconPosition="left"
          color="success"
          onClick={() => handleSaveSetor()}
        />
      </S.BoxButtons>
    );

    const handleSaveSetor = () => {
      if (newSetorData.setor === '') {
        toast.error('Nome do setor não informado!');
        return;
      } else if (newSetorData.cor == '') {
        toast.error('Cor não informada!');
        return;
      }
      const setorDataToEdit: any = newSetorData;
      setorDataToEdit.CodRca = newSetorData.codRca;
      setorDataToEdit.atualizaAutomatico = setorDataToEdit.atualizaAutomatico ? 'S' : 'N';
      allowMapCenterAndZoom.current = false;

      editSetor(setorDataToEdit)
        .then((res) => {
          toast.success('Setor editado com sucesso!');
          handleGetAllData();
        })
        .catch((err) => {
          toast.error('Não foi possível editar o setor!');
          allowMapCenterAndZoom.current = true;
        });
      setShowMapEditSetor(false);
    };

    useEffect(() => {
      if (!newSetorData.codGerente || newSetorData.codGerente.toString() == '') {
        setNewSetorData((prev: any) => {
          return {
            ...prev,
            codRca: '',
            nomeRca: '',
          };
        });
      }
    }, [newSetorData.codGerente]);

    return (
      <Dialog
        visible={showMapEditSetor}
        style={{ width: '32rem' }}
        breakpoints={{ '960px': '75vw', '641px': '90vw' }}
        header={'Editar Setor'}
        modal
        className="p-fluid"
        onHide={() => setShowMapEditSetor(false)}
        footer={DialogFooterCadastrarSetor}
        draggable={false}
      >
        <S.NovoSetorForm>
          <InputText
            id="name"
            placeholder="Setor"
            onChange={(e) => {
              setNewSetorData((prev: any) => {
                return {
                  ...prev,
                  setor: e,
                };
              });
            }}
            value={newSetorData.setor}
            label="Nome do Setor*"
            width="100%"
          />

          {/* <SelectSupervisor
            label="Supervisor"
            width="100%"
            onChange={(e) => {
              setNewSetorData((prev: any) => {
                return {
                  ...prev,
                  codGerente: e ? e.codSupervisor : '',
                  nomeGerente: e ? e.label : '',
                };
              });
            }}
          /> */}
          <Dropdown
            label="Supervisor"
            width="100%"
            options={supervisores}
            value={supervisores?.filter((rca) => rca.codSupervisor == newSetorData.codGerente)[0] ?? null}
            showClear
            onChange={(e) => {
              setNewSetorData((prev: any) => {
                return {
                  ...prev,
                  codGerente: e && e.value ? e.value.codSupervisor : '',
                  nomeGerente: e && e.value ? e.value.nomeSupervisor : '',
                };
              });
            }}
          />

          <Dropdown
            label="Vendedor"
            width="100%"
            options={vendedores}
            value={vendedores?.filter((rca) => rca.codVendedor == newSetorData.codRca)[0] ?? null}
            showClear
            onChange={(e) => {
              setNewSetorData((prev: any) => {
                return {
                  ...prev,
                  codRca: e && e.value ? e.value.codVendedor : '',
                  nomeRca: e && e.value ? e.value.nome : '',
                };
              });
            }}
          />

          {/* <SelectVendedor
            label="Vendedor"
            width="100%"
            onChange={(e) => {
              setNewSetorData((prev: any) => {
                return {
                  ...prev,
                  codRca: e ? e.codVendedor : '',
                  nomeRca: e ? e.nomeVendedor : '',
                };
              });
            }}
          /> */}

          <SelectColor
            onChange={(color) => {
              setNewSetorData((prev: any) => {
                return {
                  ...prev,
                  cor: color.COR,
                };
              });
            }}
            width={'100%'}
          />

          <S.NovoSetorFormRow>
            <InputSwitch
              label={'Ativo/Inativo'}
              checked={newSetorData.ativo}
              onChange={(e) => {
                setNewSetorData((prev: any) => {
                  return {
                    ...prev,
                    ativo: e,
                  };
                });
              }}
            />
            <InputSwitch
              label="Roteiriza Automático"
              checked={newSetorData.atualizaAutomatico}
              onChange={(e) => {
                setNewSetorData((prev: any) => {
                  return {
                    ...prev,
                    atualizaAutomatico: e,
                  };
                });
              }}
            />
          </S.NovoSetorFormRow>
        </S.NovoSetorForm>
      </Dialog>
    );
  } catch (err) {
    trackGCatchError(err, 'mapEditSetor.index.tsx');
  }
}
