import * as S from './vendedorTable.styles';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Accordion } from 'reactivus';
import { useVerbas } from 'modules/verbas/verbas.context';
import { trackGCatchError } from 'utils/analytics';
import icons from 'components/Icons/icons.index';
import Button from 'components/Button/button.index';
import { IVerbaRcaParamsState, IVerbasRca } from 'modules/verbas/verbas.types';
import formatCurrency from 'utils/formatCurrency';
import { useEffect, useState } from 'react';
import { useLoaderEffect } from 'providers/loaderEffect';
import { toast } from 'react-toastify';
import UseSelectVendedor from 'hooks/UseSelectVendedor/selectVendedor';
import VinculoRcaModal from '../vinculoRcaModal/vinculoRcaModal.index';
import InputText from 'components/Inputs/InputText/text.input';
import { postVinculoVerbaVendedor } from 'client/api/verbas';
import SelectClientModal from '../selectClientModal/selectClientModal.index';

export default function VendedorTable() {
  try {
    const {
      vendedores: verbasRca,
      selectedVerba,
      handleHistoricoPageLink,
      handleGetVerbasList,
      selectedEmpresa,
      selectedUsuario,
      selectedVerbaGa,
    } = useVerbas();

    const { vendedores } = UseSelectVendedor(selectedEmpresa.codEmpresa, selectedUsuario.codUsuario);

    const { setLoaderHeader } = useLoaderEffect();

    const [showVinculoRcaModal, setShowVinculoRcaModal] = useState(false);
    const [showSelectCliModal, setShowSelectCliModal] = useState(false);
    const [rcaToSelectCli, setRcaToSelectCli] = useState(null);

    const [handledRcas, setHandledRcas] = useState<IVerbaRcaParamsState>({
      origin: [],
      selected: [],
      included: [],
      edited: [],
      removed: [],
    });

    useEffect(() => {
      setHandledRcas({
        origin: [],
        selected: [],
        included: [],
        edited: [],
        removed: [],
      });
    }, [selectedVerbaGa]);

    const vendedorTemplate = (verbaRca: IVerbasRca) => {
      return <div>{`[${verbaRca.CODRCA}] ${verbaRca.NOME}`}</div>;
    };

    const clienteTemplate = (verbaRca: IVerbasRca) => {
      return <div>{`[${verbaRca.CODCLIP}] ${''}`}</div>;
    };

    const extratoTemplate = (verbaRca: IVerbasRca) => {
      const isOnOrigin = handledRcas?.origin?.some((sup) => sup.CODRCA == verbaRca.CODRCA);
      return (
        <Button
          icon={<icons.Receipt />}
          color="gray"
          tooltip="Extrato"
          rounded
          onClick={() => {
            if (isOnOrigin) {
              handleHistoricoPageLink('vendedor', verbaRca.NUMVERBA, verbaRca.COD_EMPRESA, -1, verbaRca.CODRCA);
            } else {
              toast.error('Vendedor ainda não foi salvo');
            }
          }}
        />
      );
    };
    const removerTemplate = (verbaRca: IVerbasRca) => {
      const isVerbaRcaAlreadyOnRemovedList = handledRcas?.removed?.some(
        (rca) => rca && rca.CODRCA == verbaRca.CODRCA && rca.CODCLIP == verbaRca.CODCLIP,
      );

      return (
        <Button
          icon={isVerbaRcaAlreadyOnRemovedList ? <icons.X /> : <icons.Remove />}
          color="red"
          tooltip={isVerbaRcaAlreadyOnRemovedList ? 'Cancelar remoção' : 'Remover'}
          rounded
          textMode
          onClick={() => {
            if (verbaRca && verbaRca.VLAPLICADO > 0) {
              toast.error(`Vendedor não pode ser removido pois já possui valor aplicado`);
              return;
            }
            setHandledRcas((prev: IVerbaRcaParamsState) => {
              const newRemoved = isVerbaRcaAlreadyOnRemovedList
                ? handledRcas?.removed?.filter(
                    (rca) => rca && !(rca.CODRCA == verbaRca.CODRCA) && !(rca.CODCLIP == verbaRca.CODCLIP),
                  )
                : [...handledRcas.removed, ...[verbaRca]];
              return {
                ...prev,
                removed: newRemoved,
              };
            });
          }}
        />
      );
    };

    const fixedNumber = (number: number | string) => {
      return `${formatCurrency(+number.toString().replace(',', '.'))}`;
    };

    const formattedNumberTemplate = (verbaRca: IVerbasRca, field: string) => {
      return <p>{fixedNumber(verbaRca[field] ?? 0)}</p>;
    };

    const AccordionHeaderTemplate = () => {
      return (
        <S.AccordionHeaderTemplateBox>
          <span>Distribuição por Vendedor</span>
          <S.AccordionHeaderButtonsBox>
            <Button
              icon={<icons.Plus />}
              color={selectedVerba ? 'green' : 'black'}
              text="Adicionar Vendedor"
              size="sm"
              textMode
              tag
              disabled={!selectedVerbaGa}
              onClick={() => {
                setShowVinculoRcaModal(true);
              }}
            />
            {!(
              handledRcas.edited.length == 0 &&
              handledRcas.removed.length == 0 &&
              handledRcas.included.length == 0
            ) && (
              <Button
                icon={<icons.Check />}
                color={'green'}
                text="Salvar Alterações"
                size="sm"
                // textMode
                tag
                disabled={
                  handledRcas.edited.length == 0 && handledRcas.removed.length == 0 && handledRcas.included.length == 0
                }
                onClick={() => {
                  handleSaveSupervisores();
                }}
              />
            )}
          </S.AccordionHeaderButtonsBox>
        </S.AccordionHeaderTemplateBox>
      );
    };

    useEffect(() => {
      if (handledRcas && verbasRca) {
        const newSelectedRcas = [];
        vendedores?.map((rca) => {
          verbasRca?.map((verbaRca) => {
            if (verbaRca.CODRCA == rca.codVendedor) {
              newSelectedRcas.push(verbaRca);
            }
          });
        });
        setHandledRcas((prev) => ({
          ...prev,
          origin: newSelectedRcas,
          selected: newSelectedRcas,
        }));
      }
    }, [vendedores, verbasRca]);

    const handleSaveSupervisores = () => {
      const rcasEditedToSave = handledRcas?.edited.map((sup) => {
        return {
          operacao: 'ALTERACAO',
          codEmpresa: sup.COD_EMPRESA,
          numverba: sup.NUMVERBA,
          codrca: sup.CODRCA,
          codclip: sup.CODCLIP,
          valor: +sup.VALOR.toString().replace(',', '.'),
          vlaplicado: sup.VLAPLICADO,
        };
      });

      const rcasIncludedToSave = handledRcas?.included.map((sup) => {
        return {
          operacao: 'INCLUSAO',
          codEmpresa: sup.COD_EMPRESA,
          numverba: sup.NUMVERBA,
          codrca: sup.CODRCA,
          codclip: sup.CODCLIP,
          valor: +sup.VALOR.toString().replace(',', '.'),
          vlaplicado: sup.VLAPLICADO,
        };
      });

      let rcasRemovedToSave = handledRcas?.removed.map((sup) => {
        return {
          operacao: 'EXCLUSAO',
          codEmpresa: sup.COD_EMPRESA,
          numverba: sup.NUMVERBA,
          codrca: sup.CODRCA,
          codclip: sup.CODCLIP,
          valor: +sup.VALOR.toString().replace(',', '.'),
          vlaplicado: sup.VLAPLICADO,
        };
      });

      rcasRemovedToSave = rcasRemovedToSave?.filter((rca) =>
        handledRcas?.origin?.some((ori) => ori && ori.CODRCA == rca.codrca && ori.CODCLIP == rca.codclip),
      );

      const rcasToSave = [...rcasEditedToSave, ...rcasIncludedToSave, ...rcasRemovedToSave];

      setLoaderHeader(true);
      postVinculoVerbaVendedor(rcasToSave)
        .then((res) => {
          if (res.succeeded) {
            toast.success('Sucesso ao salvar alteraçõs');
            handleGetVerbasList();
            setHandledRcas((prev) => ({
              ...prev,
              included: [],
              removed: [],
              edited: [],
            }));
          } else {
            toast.error('Falha inesperada ao salvar alteraçõs');
          }
        })
        .catch((err) => {
          console.log('Err :', err);
          toast.error('Falha inesperada ao salvar alteraçõs');
        })
        .finally(() => {
          setLoaderHeader(false);
        });
    };

    const selectedRowClassName = (verbaRca: IVerbasRca) => {
      return {
        'danger-table-row': handledRcas?.removed.some(
          (rca) => rca && +rca.CODRCA == +verbaRca.CODRCA && +rca.CODCLIP == +verbaRca.CODCLIP,
        ),
      };
    };

    const numberEditor = (options) => {
      return (
        <InputText
          value={options.value}
          onChange={(e) => options.editorCallback(e)}
          width="100%"
          number
          icon={options.field == 'CODCLIP' ? <icons.Search /> : undefined}
          iconAction={
            options.field == 'CODCLIP'
              ? () => {
                  setShowSelectCliModal(true);
                  setRcaToSelectCli(options.rowData);
                }
              : undefined
          }
        />
      );
    };

    const handleNumberEditorComplete = (field: string, newValue: string, verbaRca: IVerbasRca) => {
      const newSelectedRcas = handledRcas?.selected?.map((sup) => {
        if (sup && sup.CODRCA === verbaRca.CODRCA && sup.CODCLIP == verbaRca.CODCLIP) {
          return { ...sup, [field]: newValue };
        }
        return sup;
      });
      verbaRca[field] = newValue;

      let editedRcas = handledRcas?.edited?.filter((rca) => +rca.CODRCA != +verbaRca.CODRCA);
      editedRcas.push(verbaRca);

      setHandledRcas((prev) => ({
        ...prev,
        selected: newSelectedRcas,
        edited: editedRcas,
      }));
    };

    const onCellEditComplete = (e) => {
      let { rowData, newValue, field } = e;

      const verbaMaxValue = +selectedVerbaGa.VALOR;
      let totalNewDistValue = 0;
      let totalDistValue = 0;

      handledRcas?.selected?.map((rca) => {
        totalDistValue = totalDistValue + +rca.VALOR;
        if (rca && rca.CODRCA === rowData.CODRCA) {
          totalNewDistValue = totalNewDistValue + +newValue;
          return { ...rca, [field]: newValue };
        }
        totalNewDistValue = totalNewDistValue + +rca.VALOR;
        return rca;
      });
      console.log('totalNewDistValue :', totalNewDistValue);
      console.log('totalDistValue :', totalDistValue);

      if (totalNewDistValue > verbaMaxValue) {
        toast.error(
          `Valor de saldo do GA utrapassado! Total: ${verbaMaxValue} / Livre: ${
            verbaMaxValue - totalDistValue
          } / Informado: ${newValue}`,
        );
        return;
      } else {
        handleNumberEditorComplete(field, newValue, rowData);
      }
    };

    const handleClientSelection = (client: any) => {
      onCellEditComplete({ rowData: rcaToSelectCli, newValue: client.CODCLI, field: 'CODCLIP' });

      setRcaToSelectCli(null);
    };

    return (
      <Accordion title={'Distribuição por vendedor'} alwaysOpen headerTemplate={<AccordionHeaderTemplate />}>
        <S.VerbasTableMainBox>
          <S.VerbasBodyBox>
            <DataTable
              value={handledRcas.selected ?? []}
              paginator={false}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              rows={100}
              rowHover
              stripedRows
              style={{ maxWidth: '100%' }}
              emptyMessage={'Nenhum vendedor vinculado'}
              size="small"
              rowClassName={selectedRowClassName}
            >
              <Column field="CODRCA" header="Vendedor" sortable align="left" body={vendedorTemplate} />
              <Column
                field="CODCLIP"
                header="Cliente"
                sortable
                align="left"
                body={clienteTemplate}
                editor={(options) => numberEditor(options)}
                onCellEditComplete={onCellEditComplete}
              />
              <Column
                field="VALOR"
                header="Vl. Dist. RCA"
                sortable
                align="left"
                body={(verbaRca) => {
                  return formattedNumberTemplate(verbaRca, 'VALOR');
                }}
                editor={(options) => numberEditor(options)}
                onCellEditComplete={onCellEditComplete}
              />
              <Column
                field="VLAPLICADO"
                header="Vl. Aplic."
                sortable
                align="left"
                body={(verbaRca) => {
                  return formattedNumberTemplate(verbaRca, 'VLAPLICADO');
                }}
              />
              <Column
                field="VLR_SALDO"
                header="Vl. Saldo"
                sortable
                align="left"
                body={(verbaRca) => {
                  return formattedNumberTemplate(verbaRca, 'VLR_SALDO');
                }}
              />
              <Column field="" header="" align="left" body={extratoTemplate} />
              <Column field="" header="" align="left" body={removerTemplate} />
            </DataTable>
          </S.VerbasBodyBox>
        </S.VerbasTableMainBox>
        {showVinculoRcaModal && (
          <VinculoRcaModal
            showModal={showVinculoRcaModal}
            setShowModal={setShowVinculoRcaModal}
            handledRcas={handledRcas}
            setHandledRcas={setHandledRcas}
          />
        )}
        {showSelectCliModal && rcaToSelectCli && (
          <SelectClientModal
            showModal={showSelectCliModal}
            setShowModal={setShowSelectCliModal}
            codRca={rcaToSelectCli.CODRCA}
            handleCliente={handleClientSelection}
          />
        )}
      </Accordion>
    );
  } catch (err) {
    trackGCatchError(err, 'verbas/components/vendedorTable/vendedorTable.index.tsx');
  }
}
