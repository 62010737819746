import { BoxColumn, BoxRow, BoxTable, Container, Header } from 'components/Containers';
import styled from 'styled-components';

export const VincSupervFormBox = styled(BoxColumn)`
  justify-content: stretch;
  overflow-y: scroll;
  gap: 15px;
  box-shadow: none;
  scrollbar-width: none;
`;

export const VincSupervFormRow = styled(BoxRow)`
  justify-content: space-between;
  overflow-y: scroll;
  gap: 15px;
  box-shadow: none;
  align-items: flex-end;
  width: 100%;
  scrollbar-width: none;
`;

export const VincSupervTableBox = styled(BoxTable)`
  width: 100%;
  max-height: 80%;
  align-items: center;
  scrollbar-width: none;

  .p-datatable {
    height: 50svh;
    width: 95%;
  }
`;
