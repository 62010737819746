import * as S from './mapImportCli.styles';
import { Dialog } from 'primereact/dialog';
import { useContext, useEffect, useRef, useState } from 'react';
import { TbDatabaseImport } from 'react-icons/tb';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { putImportCli } from 'client/api';
import { MapContext } from 'providers/map';
import { SetoresProps } from '../../regioes.types';
import { trackGCatchError } from 'utils/analytics';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import { dialog } from 'reactivus';
import exportSheetModel from 'utils/exportSheetModel';

type ImportCliProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setorToImportCli: SetoresProps;
};
type CliFileProps = {
  lastModified: number;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
};

export default function MapImportCli({ showModal, setShowModal, setorToImportCli }: ImportCliProps) {
  try {
    const { reloadAllData } = useContext(MapContext);

    const fileRef = useRef(null);

    const [cliFile, setCliFile] = useState<CliFileProps>(null);

    const [cliDataToImport, setCliDataToImport] = useState(null);

    useEffect(() => {
      if (cliFile) handleCliListImport();
    }, [cliFile]);

    useEffect(() => {
      if (!showModal) {
        setCliFile(null);
        setCliDataToImport(null);
      }
    }, [showModal]);

    const convertKeysToLowerCase = (arr: Array<{ [key: string]: any }>): Array<{ [key: string]: any }> => {
      return arr.map((obj) => {
        const newObj: { [key: string]: any } = {};
        for (const key in obj) {
          newObj[key.toLowerCase()] = obj[key];
        }
        return newObj;
      });
    };
    const numericToDate = (numericValue: number): string => {
      const excelReferenceDate = new Date(Date.UTC(1899, 11, 30));
      const dateValue = new Date(excelReferenceDate.getTime() + numericValue * 24 * 60 * 60 * 1000);
      const day = dateValue.getUTCDate().toString().padStart(2, '0');
      const month = (dateValue.getUTCMonth() + 1).toString().padStart(2, '0');
      const year = dateValue.getUTCFullYear() % 100;

      return `${day}/${month}/${year}`;
    };

    const formatDateToUSAFormat = (date: string) => {
      const dateArray = date.split('/');
      dateArray[2] = '20' + dateArray[2];
      const newDate = dateArray.reverse().join('-');
      return newDate;
    };

    const handleCliListImport = () => {
      const file: any = cliFile;

      if (file) {
        const fileExtension = file.name.split('.').reverse();
        if (fileExtension[0] !== 'xls' && fileExtension[0] !== 'xlsx') {
          toast.dismiss();
          toast.error('Arquivo não suportado!');
          return;
        }
        try {
          const reader = new FileReader();

          reader.onload = (e) => {
            const data = new Uint8Array(e.target.result as ArrayBuffer);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];

            const clientes: any = convertKeysToLowerCase(XLSX.utils.sheet_to_json(worksheet));

            let cliList: any = [];
            for (let i = 0; i < clientes.length; i++) {
              let cliDate =
                clientes[i].dtproxvisita && clientes[i].dtproxvisita != ''
                  ? formatDateToUSAFormat(numericToDate(clientes[i].dtproxvisita))
                  : '';
              if (cliDate.includes('NaN')) {
                toast.dismiss();
                toast.warn(`A data da próxima visita do cliente ${clientes[i].codcli} é inválida!`);
              } else if (new Date(new Date(cliDate).getTime() + 24 * 60 * 60 * 1000) < new Date()) {
                toast.dismiss();
                toast.warn(`A data da próxima visita do cliente ${clientes[i].codcli} é menor que a data atual!`);
              }
              try {
                const cliCodCnpj = clientes[i].codcli.toString().replace(/[^0-9]/g, '') ?? '';
                const existsInArray = cliList.findIndex((cli: any) => cli.codCli === cliCodCnpj);
                if (existsInArray == -1) {
                  cliList.push({
                    codCli: cliCodCnpj.length < 14 ? cliCodCnpj : '',
                    cnpj: cliCodCnpj.length == 14 ? cliCodCnpj : '',
                    dtProxVisita:
                      clientes[i].dtproxvisita && clientes[i].dtproxvisita != ''
                        ? formatDateToUSAFormat(numericToDate(clientes[i].dtproxvisita))
                        : '',
                    seqVisita: clientes[i].seqvisita ? clientes[i].seqvisita.toString() : '',
                    periodicidade: clientes[i].periodicidade ? clientes[i].periodicidade.toString() : '',
                    tempoMinimoVisita: clientes[i].tempo_minimo_visita
                      ? clientes[i].tempo_minimo_visita.toString()
                      : '',
                  });
                }
              } catch (err) {
                console.log('err :', err);
                toast.dismiss();
                toast.error(
                  'Falha inesperada ao importar dados de clientes!' + (err.message ? ` '${err.message}'` : ''),
                );
                break;
              }
            }

            const toImportCliList = {
              codSetor: setorToImportCli.CODSETOR,
              codFilial: 0,
              codMapa: setorToImportCli.COD_MAPA ?? 0,
              codEmpresa: setorToImportCli.COD_EMPRESA,
              clientes: cliList,
            };
            setCliDataToImport(toImportCliList);
          };

          reader.readAsArrayBuffer(file);
        } catch (err) {
          console.log('err :', err);
          toast.error('Erro ao ler arquivo!');
        }
      } else {
        toast.error('Arquivo inválido!');
      }
    };

    const handleCliListImportFileModel = () => {
      const fileName: string = `ARQUIVO_MODELO_IMPORTAR_CLIENTES_VMAIS`;
      let data: any = [{ CODCLI: '', DTPROXVISITA: '', SEQVISITA: '', PERIODICIDADE: '', TEMPO_MINIMO_VISITA: '' }];
      exportSheetModel(fileName, data);
    };

    const handleCliImportAction = () => {
      setShowModal(false);
      dialog
        .show({
          title: 'Tem certeza?',
          text: `Um total de ${cliDataToImport.clientes.length} cliente(s) serão importados para o setor [${setorToImportCli.CODSETOR}] ${setorToImportCli.NOMESETOR}`,
          icon: 'danger',
          showConfirmButton: true,
          confirmButtonText: 'Sim, importar',
          showCancelButton: true,
          cancelButtonText: 'Não, cancelar',
          showCloseButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            putImportCli(cliDataToImport)
              .then((res: any) => {
                toast.success('Clientes importados com sucesso!');
                reloadAllData();
              })
              .catch((err) => {
                console.log('err', err);
                if (err.response && err.response.data && err.response.data.message) {
                  toast.error(err.response.data.message);
                } else {
                  toast.error('Falha ao importar clientes!');
                }
              });
          }
        });
    };

    return (
      <Dialog
        visible={showModal}
        style={{ width: 'clamp(30vw, 40vw, 100vw)' }}
        breakpoints={{ '960px': '75vw', '641px': '90vw' }}
        header={'Importar Listagem de Clientes'}
        modal
        className="p-fluid"
        onHide={() => setShowModal(false)}
      >
        <S.ImportCliModalBodyBox>
          <S.ImportCliModalFileBox>
            <input
              type="file"
              id="fileInput"
              accept=".xls, .xlsx"
              ref={fileRef}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                e.target.files && setCliFile(e.target.files[0]);
              }}
            />
            <Button
              text={cliFile && cliFile.name ? cliFile.name : 'Nenhum arquivo selecionado...'}
              color="gray"
              onClick={() => document.getElementById('fileInput').click()}
              icon={<icons.Excel />}
            />
            <S.ImportCliModalInstructionsBox>
              ** O arquivo deve conter as colunas: <b> CODCLI </b>, <b>DTPROXVISITA</b>, <b>SEQVISITA</b>,
              <b> PERIODICIDADE</b> e <b> TEMPO_MINIMO_VISITA</b>. <br />
              ** Extensões aceitas: <b>XLS</b>, <b>XLSX</b>.
            </S.ImportCliModalInstructionsBox>
          </S.ImportCliModalFileBox>

          <S.ImportCliModalButtonsBox>
            <Button
              text={'Importar Clientes'}
              color="green"
              onClick={() => handleCliImportAction()}
              icon={<TbDatabaseImport />}
              disabled={!cliFile}
            />
            <S.ImportCliModalSampleFile onClick={() => handleCliListImportFileModel()}>
              <span>
                <icons.Excel /> Baixar Planilha Modelo
              </span>
            </S.ImportCliModalSampleFile>
          </S.ImportCliModalButtonsBox>
        </S.ImportCliModalBodyBox>
      </Dialog>
    );
  } catch (err) {
    trackGCatchError(err, 'mapImportCli.index.tsx');
  }
}
