import * as S from './supervTable.styles';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Accordion } from 'reactivus';
import { useVerbas } from 'modules/verbas/verbas.context';
import { trackGCatchError } from 'utils/analytics';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import { IVerbaGaParamsState, IVerbasGa } from 'modules/verbas/verbas.types';
import formatCurrency from 'utils/formatCurrency';
import { MdArrowForwardIos } from 'react-icons/md';
import { useEffect, useState } from 'react';
import VinculoSupervModal from '../vinculoSupervModal/vinculoSupervModal.index';
import UseSelectSupervisor from 'hooks/UseSelectSupervisor/selectSupervisor';
import InputText from 'components/Inputs/InputText/text.input';
import { useLoaderEffect } from 'providers/loaderEffect';
import { postVinculoVerbaSupervisor } from 'client/api/verbas';
import { toast } from 'react-toastify';

export default function SupervTable() {
  try {
    const {
      selectedVerba,
      handleHistoricoPageLink,
      setSelectedVerbaGa,
      selectedVerbaGa,
      supervisores: verbasGa,
      selectedEmpresa,
      selectedUsuario,
      handleGetVerbasList,
    } = useVerbas();

    const { supervisores } = UseSelectSupervisor(selectedEmpresa.codEmpresa, selectedUsuario.codUsuario);

    const [showVinculoSupervModal, setShowVinculoSupervModal] = useState(false);

    const { setLoaderHeader, loaderHeader } = useLoaderEffect();

    const supervisorTemplate = (verbaGa: IVerbasGa) => {
      if (verbaGa) {
        return <div>{`[${verbaGa.CODGA}] ${verbaGa.NOME_GA}`}</div>;
      }
      return <></>;
    };

    const extratoTemplate = (verbaGa: IVerbasGa) => {
      const isOnOrigin = handledSupervs?.origin?.some((sup) => sup.CODGA == verbaGa.CODGA);
      return (
        <Button
          icon={<icons.Receipt />}
          color="black"
          tooltip="Extrato"
          rounded
          onClick={() => {
            if (isOnOrigin) {
              handleHistoricoPageLink('supervisor', verbaGa.NUMVERBA, verbaGa.COD_EMPRESA, verbaGa.CODGA, -1);
            } else {
              toast.error('Supervisor ainda não foi salvo');
            }
          }}
        />
      );
    };

    const formattedNumberTemplate = (verbaGa: IVerbasGa, field: string) => {
      return <p>{formatCurrency(verbaGa ? verbaGa[field] ?? 0 : 0)}</p>;
    };

    const AccordionHeaderTemplate = () => {
      return (
        <S.AccordionHeaderTemplateBox>
          <span>Distribuição por Supervisor</span>
          <S.AccordionHeaderButtonsBox>
            <Button
              icon={<icons.Plus />}
              color={selectedVerba ? 'green' : 'black'}
              text="Adicionar Supervisor"
              size="sm"
              textMode
              tag
              disabled={!selectedVerba}
              onClick={() => {
                setShowVinculoSupervModal(true);
              }}
            />
            {!(
              handledSupervs.edited.length == 0 &&
              handledSupervs.removed.length == 0 &&
              handledSupervs.included.length == 0
            ) && (
              <Button
                icon={<icons.Check />}
                color={'green'}
                text="Salvar Alterações"
                size="sm"
                // textMode
                tag
                disabled={
                  (handledSupervs.edited.length == 0 &&
                    handledSupervs.removed.length == 0 &&
                    handledSupervs.included.length == 0) ||
                  loaderHeader
                }
                onClick={() => {
                  handleSaveSupervisores();
                }}
              />
            )}
          </S.AccordionHeaderButtonsBox>
        </S.AccordionHeaderTemplateBox>
      );
    };

    const selectSupervTemplate = (verbaGa: IVerbasGa) => {
      const isOnOrigin = handledSupervs?.origin?.some((sup) => sup.CODGA == verbaGa.CODGA);
      return (
        <MdArrowForwardIos
          onClick={(e) => {
            if (verbaGa.NUMVERBA != 0 && isOnOrigin) {
              setSelectedVerbaGa(verbaGa);
            } else {
              toast.error('Supervisor ainda não foi salvo');
            }
          }}
        />
      );
    };

    const selectedRowClassName = (verbaGa: IVerbasGa) => {
      return {
        'selected-table-row': selectedVerbaGa && verbaGa && verbaGa.CODGA == selectedVerbaGa.CODGA,
      };
    };

    const [handledSupervs, setHandledSupervs] = useState<IVerbaGaParamsState>({
      origin: [],
      selected: [],
      included: [],
      edited: [],
      removed: [],
    });

    useEffect(() => {
      if (handledSupervs && verbasGa) {
        const newSelectedSupervs = [];
        supervisores?.map((superv) => {
          verbasGa?.map((verbaGa) => {
            if (verbaGa.CODGA == superv.codSupervisor) {
              newSelectedSupervs.push(verbaGa);
            }
          });
        });
        setHandledSupervs((prev) => ({
          ...prev,
          origin: newSelectedSupervs,
          selected: newSelectedSupervs,
        }));
      }
    }, [supervisores, verbasGa]);

    const numberEditor = (options) => {
      return <InputText value={options.value} onChange={(e) => options.editorCallback(e)} width="100%" number />;
    };

    const handleNumberEditorComplete = (field: string, newValue: string, verbaGa: IVerbasGa) => {
      const newSelectedSupervs = handledSupervs?.selected?.map((sup) => {
        if (sup && sup.CODGA === verbaGa.CODGA) {
          return { ...sup, [field]: newValue };
        }
        return sup;
      });

      let editedSupervs = handledSupervs?.edited?.filter((sup) => +sup.CODGA != +verbaGa.CODGA);
      editedSupervs.push(verbaGa);

      setHandledSupervs((prev) => ({
        ...prev,
        selected: newSelectedSupervs,
        edited: editedSupervs,
      }));
    };

    const onCellEditComplete = (e) => {
      let { rowData, newValue, field, originalEvent: event } = e;

      const verbaMaxValue = +selectedVerba.VALOR;
      let totalNewDistValue = 0;
      let totalDistValue = 0;

      handledSupervs?.selected?.map((sup) => {
        totalDistValue = totalDistValue + +sup.VALOR;
        if (sup && sup.CODGA === rowData.CODGA) {
          totalNewDistValue = totalNewDistValue + +newValue;
          return { ...sup, [field]: newValue };
        }
        totalNewDistValue = totalNewDistValue + +sup.VALOR;
        return sup;
      });

      if (totalNewDistValue > verbaMaxValue) {
        toast.error(
          `Valor de saldo da verba utrapassado! Total: ${verbaMaxValue} / Livre: ${
            verbaMaxValue - totalDistValue
          } / Informado: ${newValue}`,
        );
        return;
      } else {
        rowData[field] = newValue;
        handleNumberEditorComplete(field, newValue, rowData);
      }
    };

    const handleSaveSupervisores = () => {
      const supervsEditedToSave = handledSupervs?.edited.map((sup) => {
        return {
          operacao: 'ALTERACAO',
          codEmpresa: sup.COD_EMPRESA,
          numverba: sup.NUMVERBA,
          codga: sup.CODGA,
          valor: +sup.VALOR.toString().replace(',', '.'),
        };
      });

      const supervsIncludedToSave = handledSupervs?.included.map((sup) => {
        return {
          operacao: 'INCLUSAO',
          codEmpresa: sup.COD_EMPRESA,
          numverba: sup.NUMVERBA,
          codga: sup.CODGA,
          valor: +sup.VALOR.toString().replace(',', '.'),
        };
      });

      const supervsRemovedToSave = handledSupervs?.removed.map((sup) => {
        return {
          operacao: 'EXCLUSAO',
          codEmpresa: sup.COD_EMPRESA,
          numverba: sup.NUMVERBA,
          codga: sup.CODGA,
          valor: +sup.VALOR.toString().replace(',', '.'),
        };
      });

      const supervsToSave = [...supervsEditedToSave, ...supervsIncludedToSave, ...supervsRemovedToSave];

      setLoaderHeader(true);
      postVinculoVerbaSupervisor(supervsToSave)
        .then((res) => {
          if (res.succeeded) {
            toast.success('Sucesso ao salvar alteraçõs');
            handleGetVerbasList();
            setHandledSupervs((prev) => ({
              ...prev,
              included: [],
              removed: [],
              edited: [],
            }));
          } else {
            toast.error('Falha inesperada ao salvar alteraçõs');
          }
        })
        .catch((err) => {
          console.log('Err :', err);
          toast.error('Falha inesperada ao salvar alteraçõs');
        })
        .finally(() => {
          setLoaderHeader(false);
        });
    };

    return (
      <Accordion title={'Distribuição por supervisor'} alwaysOpen headerTemplate={<AccordionHeaderTemplate />}>
        <S.VerbasTableMainBox>
          <S.VerbasBodyBox>
            <DataTable
              value={handledSupervs.selected ?? []}
              paginator={false}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              rows={100}
              rowHover
              stripedRows
              style={{ maxWidth: '100%' }}
              emptyMessage={'Nenhum supervisor vinculado'}
              size="small"
              rowClassName={selectedRowClassName}
            >
              <Column
                field="NUMVERBA"
                header=""
                body={selectSupervTemplate}
                align="left"
                style={{
                  maxWidth: '50px',
                }}
              />
              <Column field="CODGA" header="Cód. GA" sortable align="left" body={supervisorTemplate} />
              <Column
                field="VALOR"
                header="Vl. Dist. GA"
                sortable
                align="left"
                body={(verbaGa) => formattedNumberTemplate(verbaGa, 'VALOR')}
                editor={(options) => numberEditor(options)}
                onCellEditComplete={onCellEditComplete}
              />
              <Column
                field="VLR_DISTRIBUIDO"
                header="Vl. Dist. RCA"
                sortable
                align="left"
                body={(verbaGa) => {
                  return formattedNumberTemplate(verbaGa, 'VLR_DISTRIBUIDO');
                }}
              />
              <Column
                field="VLR_APLICADO"
                header="Vl. Aplic."
                sortable
                align="left"
                body={(verbaGa) => {
                  return formattedNumberTemplate(verbaGa, 'VLR_APLICADO');
                }}
              />
              <Column
                field="VLR_SALDO"
                header="Vl. Saldo"
                sortable
                align="left"
                body={(verbaGa) => {
                  return formattedNumberTemplate(verbaGa, 'VLR_SALDO');
                }}
              />
              <Column field="" header="" align="left" body={extratoTemplate} />
            </DataTable>
          </S.VerbasBodyBox>
        </S.VerbasTableMainBox>
        {showVinculoSupervModal && (
          <VinculoSupervModal
            showModal={showVinculoSupervModal}
            setShowModal={setShowVinculoSupervModal}
            handledSupervs={handledSupervs}
            setHandledSupervs={setHandledSupervs}
          />
        )}
      </Accordion>
    );
  } catch (err) {
    trackGCatchError(err, 'verbas/components/supervTable/supervTable.index.tsx');
  }
}
