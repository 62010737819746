import styled from 'styled-components';

interface InputMainBoxProps {
  width?: string;
  widthCalculated?: number;
  type?: string;
}

export const InputMainBox = styled.div<InputMainBoxProps>`
  display: flex;
  flex-direction: column;
  gap: 0px;
  min-width: ${({ type, width }) => (width ? width : type == 'date' ? '85px' : 'auto')};
  width: ${({ width, widthCalculated }) =>
    width
      ? width
      : `clamp(${widthCalculated ?? 100}px, ${widthCalculated ?? 100}px, ${widthCalculated ?? 170}px)`} !important;

  .p-dropdown,
  .p-inputtext,
  .p-multiselect {
    border-radius: 5px !important;

    &:focus {
      outline: none;
      border-color: var(--primary-green);
      box-shadow: 0 0 0 0.2rem var(--primary-green-20);
    }
  }
  input::focus {
    outline: none !important;
    border: none !important;
  }

  .p-inputtext::focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }
`;

export const InputDescriptionBox = styled.p`
  padding: 5px 0 0 0;
  width: 100%;
  font-size: 0.8rem;
  color: #6c757d;
`;

interface IIconPosition {
  iconPosition: 'left' | 'right';
  icon: boolean
}

export const InputTextBox = styled.div<IIconPosition>`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 1rem;
  position: relative;

  input {
    border: 1px solid #ced4da;
    border-radius: 5px;
    appearance: none;
    color: #495057;
    background: #ffffff;
    padding: 0.5rem;
    padding-left: 0.5rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    width: 100%;
    padding-right: ${({ iconPosition, icon }) => (iconPosition == 'right' && icon ? '2.2rem' : undefined)};
    padding-left: ${({ iconPosition, icon }) => (iconPosition == 'left' && icon ? '2.2rem' : undefined)};

    &:disabled {
      background-color: #e9ecef;
      color: #6c757d;
      border-color: #ced4da;
      cursor: not-allowed;
    }
    &:focus {
      outline: none;
      border-color: var(--primary-green);
      box-shadow: 0 0 0 0.2rem var(--primary-green-20);
    }
    &:hover {
      border-color: var(--primary-green);
    }
  }
`;
export const InputTextIconBox = styled.div<IIconPosition>`
  position: absolute;
  top: 0;
  right: ${({ iconPosition }) => (iconPosition == 'right' ? '0.2rem' : undefined)};
  left: ${({ iconPosition }) => (iconPosition == 'left' ? '0.2rem' : undefined)};
  font-size: 1.2rem;
  color: #6c757d;
  height: 100%;
  width: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #000;
  }
`;
