import { useGroupClientMember } from 'storesZustand/groupClientMember';
import * as S from './styles';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as XLSX from 'xlsx';

import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ModalAddMemberToGroupClient } from '../ModalAddMemberToGroupClient';
import { useClientGroup } from 'client/hooks/ClientGroup';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';

import { deleteAllClientsGroup, deleteClientGroup } from 'client/api/clientGroup';
import { IClientGroup } from 'client/interfaces/clientGroup';
import { useQueryClient } from '@tanstack/react-query';
import { ModalImportMembersXLS } from '../ModalImportMembersXLS';
import { formatCPFOrCNPJ } from 'utils/formatCNPJ';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
import { alterPageTitle } from 'utils/alterPageTitle';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import InputText from 'components/Inputs/InputText/text.input';
import { dialog } from 'reactivus';
import { usePageTitle } from 'providers/pageTitle';

export const ListGroupClientMembers = () => {
  try {
    const groupInfo = useGroupClientMember((state) => state.groupInfo);

    useEffect(() => {
      trackGPageView('/grupo-clientes/listar/membros');
      alterPageTitle('Listar Grupo Cliente');
    }, []);

    const { codEmpresa: codEmpresaParam } = useParams<{ codEmpresa?: string }>();

    const { data: clientsGroup, isLoading } = useClientGroup(codEmpresaParam, groupInfo.codgrupo);

    const [clientsList, setClientsList] = useState([]);

    useEffect(() => {
      setClientsList(clientsGroup);
      handleSearchClientInGrid();
    }, [clientsGroup]);

    const queryClient = useQueryClient();

    const [openModalAddMembers, setOpenModalAddMembers] = useState(false);
    const [openModalImportMembers, setOpenModalImportMembers] = useState(false);

    const navigate = useNavigate();

    function handleExportTableXLS() {
      const filterClientsToExport = clientsList?.map((item: IClientGroup) => ({
        // COD_EMPRESA: item.codEmpresa,
        COD_GRUPO: item.codGrupo,
        CODCLI: item.codCli,
        CNPJ: item.cnpj,
        NOME_CLIENTE: item.nomeCli,
      }));

      const fileName: string = `CLIENTES_GRUPO_${groupInfo.codgrupo}`;
      const ws = XLSX.utils.json_to_sheet(filterClientsToExport);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, fileName);
    }

    const cliSearchText = useRef<HTMLInputElement>(null);

    const handleSearchClientInGrid = useCallback(() => {
      const cliText = cliSearchText.current ? cliSearchText.current.value.toUpperCase() : '';

      setClientsList(
        cliText == ''
          ? clientsGroup
          : clientsGroup.filter(
              (cli) =>
                cli.nomeCli.toUpperCase().includes(cliText) ||
                cli.codCli.toString().toUpperCase().includes(cliText) ||
                cli.cnpj.toString().toUpperCase().includes(cliText),
            ),
      );
    }, [clientsGroup, cliSearchText]);

    const handleRemoveAllClients = () => {
      dialog
        .show({
          title: `Deseja Remover Todos Clientes do Grupo ${groupInfo?.nomeGrupo}?`,
          icon: 'danger',
          showConfirmButton: true,
          confirmButtonText: 'Remover',
          cancelButtonText: 'Voltar',
          showCloseButton: true,
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            deleteAllClientsGroup(String(groupInfo.codgrupo), String(groupInfo.codempresa))
              .then(() => {
                toast.success('Sucesso ao Remover Clientes do Grupo.');

                queryClient.invalidateQueries({
                  queryKey: ['client-group'],
                });
              })
              .catch(() => {
                toast.error('Erro ao Remover Clientes do Grupo');
              });
          } else {
            toast.warning('Remoção de Clientes Cancelada!');
          }
        });
    };

    const handleRemoveClient = (rowData: IClientGroup) => {
      const handleOpenModal = (rowData) => {
        dialog
          .show({
            title: `Deseja remover o cliente ${rowData.codCli} do grupo ?`,
            icon: 'danger',
            showConfirmButton: true,
            confirmButtonText: 'Remover do Grupo',
            cancelButtonText: 'Voltar',
            showCloseButton: true,
            showCancelButton: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              deleteClientGroup(rowData.codCli, rowData.codGrupo)
                .then(() => {
                  toast.success('Sucesso ao Remover Cliente do Grupo.');
                  queryClient.invalidateQueries({
                    queryKey: ['client-group'],
                  });
                  queryClient.invalidateQueries({
                    queryKey: ['useClientsToAddGroup'],
                  });
                })
                .catch(() => {
                  toast.error('Erro ao Remover Cliente do Grupo');
                });
            } else {
              toast.warning('Remoção de Cliente Cancelada!');
            }
          });
      };

      return (
        <Button
          color="gray"
          icon={<icons.Remove />}
          tooltip="Remover do grupo"
          onClick={() => {
            handleOpenModal(rowData);
          }}
          rounded
          bgOnlyOnHover
        />
      );
    };
    const { setPageTitle } = usePageTitle();
    useEffect(() => {
      setPageTitle((prev) => ({
        text: `Grupo: ${groupInfo.nomeGrupo} ${[groupInfo.codgrupo]}`,
        customObject: prev.customObject,
        tree: prev.tree,
      }));
    }, [groupInfo]);

    return (
      <S.GrouClientsMembersMainBox>
        <S.GrouClientsMembersHeaderBox>
          <InputText
            label="Buscar Cliente"
            placeholder="Nome, Código ou CNPJ"
            onChange={() => {
              handleSearchClientInGrid();
            }}
            ref={cliSearchText}
            width="clamp(150px, 170px, 190px)"
          />

          <Button
            text="Vincular"
            color="green"
            tooltip="Voltar Clientes ao Grupo"
            icon={<icons.Plus />}
            onClick={() => {
              setOpenModalAddMembers(!openModalAddMembers);
            }}
          />

          <Button
            text="Importar"
            tooltip="Importar Planilha de Clientes"
            color="green"
            icon={<icons.Excel />}
            onClick={() => {
              setOpenModalImportMembers(!openModalImportMembers);
            }}
          />

          <Button
            text="Exportar"
            tooltip="Importar Planilha de Clientes"
            color="green"
            icon={<icons.Excel />}
            onClick={() => {
              handleExportTableXLS();
            }}
          />

          <Button
            text="Remover"
            tooltip="Remover Todos os Clientes do Grupo"
            color="red"
            icon={<icons.RemoveFill />}
            onClick={() => {
              handleRemoveAllClients();
            }}
          />
        </S.GrouClientsMembersHeaderBox>

        <S.GrouClientsMembersTableBox>
          <DataTable
            value={clientsList}
            dataKey="id"
            editMode="cell"
            rows={9}
            paginator
            rowsPerPageOptions={[9, 25, 50]}
            emptyMessage="Nenhum dado encontrado"
            size="small"
            rowHover
          >
            <Column header="Nome Cliente" field="nomeCli" sortable />

            <Column header="CodCliente" field="codCli" sortable />
            <Column header="CNPJ" body={(rowData) => formatCPFOrCNPJ(rowData.cnpj)} sortable />
            <Column header="" field="cod" body={handleRemoveClient} />
          </DataTable>
        </S.GrouClientsMembersTableBox>

        <ModalAddMemberToGroupClient
          isOpenModal={openModalAddMembers}
          setIsOpenModal={setOpenModalAddMembers}
          codEmpresa={codEmpresaParam}
          codGrupo={groupInfo.codgrupo}
        />

        <ModalImportMembersXLS
          isOpenModal={openModalImportMembers}
          setIsOpenModal={setOpenModalImportMembers}
          codEmpresa={codEmpresaParam}
        />
      </S.GrouClientsMembersMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'groupClients/GroupClientMembers/ListGroupClientMembers/index.tsx');
  }
};
