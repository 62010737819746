import { IWebPedCab, IWebPedCli } from 'modules/pedido/pedido.types';
import api from 'services/api';

const _controller = 'PedidosWeb';

export const getWebPedCabList = async (
  codEmpresa: number,
  codCli: number,
  dtIni: string,
  dtFim: string,
): Promise<{ data: IWebPedCab[]; succeeded: boolean }> => {
  const { data } = await api.get(
    `${_controller}/GetPedWebPedidos?CODEMPRESA=${codEmpresa}&CODCLI=${codCli}&DtIni=${dtIni}&DtFim=${dtFim}`,
  );
  return data;
};

export const getWebCliente = async (
  codEmpresa: number,
  codCli: number,
): Promise<{ data: IWebPedCli[]; succeeded: boolean }> => {
  const { data } = await api.get(`${_controller}/GetPedWebClientes?CODEMPRESA=${codEmpresa}&CODCLI=${codCli}`);
  return data;
};

export const postAprovaWebPed = async (
  codEmpresa: number,
  numPedRca: number,
  status: string,
): Promise<{ data: IWebPedCli[]; succeeded: boolean }> => {
  const { data } = await api.post(
    `${_controller}/AprovarPedido?CODEMPRESA=${codEmpresa}&NumPedRca=${numPedRca}&Status=${status}`,
  );
  return data;
};
