import { AiFillFileExcel, AiOutlineDelete } from 'react-icons/ai';
import { FaLock, FaMapMarkedAlt, FaPlus, FaPrint, FaTrash } from 'react-icons/fa';
import { LuMap, LuDot } from 'react-icons/lu';
import { GoPlus, GoCheck, GoX, GoDash } from 'react-icons/go';
import {
  BsCurrencyDollar,
  BsFillCalendar3WeekFill,
  BsFillGridFill,
  BsFillHouseFill,
  BsFillPinMapFill,
  BsGrid,
  BsIntersect,
  BsListUl,
  BsPeopleFill,
  BsPercent,
  BsThreeDotsVertical,
} from 'react-icons/bs';
import { IoIosArrowDown, IoIosArrowUp, IoIosSettings, IoMdArrowRoundBack, IoMdEye } from 'react-icons/io';
import { IoLockOpen, IoPlayOutline, IoSearchOutline } from 'react-icons/io5';
import { RiFileEditLine, RiPencilFill } from 'react-icons/ri';
import { FiRefreshCcw, FiTool } from 'react-icons/fi';
import { HiFastForward, HiOutlineClock } from 'react-icons/hi';
import {
  MdCurrencyExchange,
  MdEditCalendar,
  MdOutlineContentCopy,
  MdOutlineDoubleArrow,
  MdOutlineRemoveRedEye,
} from 'react-icons/md';
import { TbCalendarStats, TbReceipt } from 'react-icons/tb';
import { CiSquarePlus } from 'react-icons/ci';
import { FcSettings } from 'react-icons/fc';

const icons = {
  Map: LuMap,
  MapPin: BsFillPinMapFill,
  MapPinned: FaMapMarkedAlt,
  Edit: RiPencilFill,
  EditLine: RiFileEditLine,
  Remove: AiOutlineDelete,
  RemoveFill: FaTrash,
  Plus: GoPlus,
  PlusFill: FaPlus,
  Dash: GoDash,
  PlusOutlined: CiSquarePlus,
  Dot: LuDot,
  Check: GoCheck,
  X: GoX,
  ThreeDotsVertical: BsThreeDotsVertical,
  ArrowBack: IoMdArrowRoundBack,
  ArrowDown: IoIosArrowDown,
  ArrowUp: IoIosArrowUp,
  Search: IoSearchOutline,
  Play: IoPlayOutline,
  PlayDouble: HiFastForward,
  Print: FaPrint,
  Excel: AiFillFileExcel,
  List: BsListUl,
  Refresh: FiRefreshCcw,
  Settings: IoIosSettings,
  SettingsColored: FcSettings,
  Grid: BsGrid,
  GridFill: BsFillGridFill,
  Clock: HiOutlineClock,
  DoubleArrow: MdOutlineDoubleArrow,
  Calendar: TbCalendarStats,
  CalendarFill: BsFillCalendar3WeekFill,
  CalendarEdit: MdEditCalendar,
  Lock: FaLock,
  LockOpen: IoLockOpen,
  HouseFill: BsFillHouseFill,
  Eye: IoMdEye,
  EyeOutline: MdOutlineRemoveRedEye,
  PeopleFill: BsPeopleFill,
  Copy: MdOutlineContentCopy,
  Intersect: BsIntersect,
  Receipt: TbReceipt,
  Tool: FiTool,
  Percent: BsPercent,
  Currency: BsCurrencyDollar,
  CurrencyChange: MdCurrencyExchange,
};

export default icons;
