import {
  Parametro,
  ParametroGeralCab,
  ParametroGeralItem,
  ReplicateParametro,
  ToCloneParametroGeralItem,
} from 'client/interfaces';
import api from 'services/api';

const _controller = 'parametros';

// PARAMETROS VENDEDOR
export const getParametros = async (codEmpresa: number, codUser: number): Promise<Parametro[]> => {
  const { data } = await api.get(`Parametros?CodEmpresa=${codEmpresa}&codUser=${codUser}`);
  return data;
};

export const updateParams = async (parametro: Parametro): Promise<void> => {
  const { data } = await api.put(`${_controller}/${parametro.codEmpresa}/${parametro.codUsur}`, parametro);
  return data;
};

export const replicateParams = async (parametro: ReplicateParametro): Promise<void> => {
  const { data } = await api.put(`${_controller}/replicate`, parametro);
  return data;
};

//PARAMETROS GERAIS
export const getParametrosGeralCab = async (): Promise<{ data: ParametroGeralCab[]; succeeded: boolean }> => {
  const { data } = await api.get(`${_controller}/GetCadParametro`);
  return data;
};

export const postParametrosGeralCab = async (
  toInsertParam: ParametroGeralCab,
): Promise<{ data: boolean; succeeded: boolean }> => {
  const { data } = await api.post(`${_controller}/PostCadParametro`, toInsertParam);
  return data;
};

export const putParametrosGeralCab = async (
  toEditParam: ParametroGeralCab,
): Promise<{ data: boolean; succeeded: boolean }> => {
  const { data } = await api.put(`${_controller}/PutCadParametro`, toEditParam);
  return data;
};

export const postClonseParametrosGeralItem = async (
  toCloneParam: ToCloneParametroGeralItem,
): Promise<{ data: boolean; succeeded: boolean; message: string }> => {
  const { data } = await api.post(`${_controller}/PostFvParametroGeral`, toCloneParam);
  return data;
};

export const getParametrosGeralItem = async (): Promise<{ data: ParametroGeralItem[]; succeeded: boolean }> => {
  const { data } = await api.get(`${_controller}/GetFVParametroEmpresa`);
  return data;
};

export const putParametrosGeralItem = async (
  toEditParam: ParametroGeralItem,
): Promise<{ data: boolean; succeeded: boolean }> => {
  const { data } = await api.put(`${_controller}/PutFvParametroGeral`, toEditParam);
  return data;
};
