import * as S from './noDataFound.styles';
import noDataFoundImg from 'assets/images/noDataFound.png';

interface INoDataFound {
  message?: string;
  tableMode?: boolean;
}

export default function NoDataFound({ message, tableMode }: INoDataFound) {
  if (!tableMode) {
    return (
      <S.EmptyMainBox>
        <S.EmptyImage alt="no-data-found-vmais" src={noDataFoundImg} />
        <S.EmptyText>{message ?? 'Nenhum dado encontrado :/'}</S.EmptyText>
      </S.EmptyMainBox>
    );
  }
  return (
    <S.EmptyTableMainBox>
      <S.EmptyMainBox>
        <S.EmptyImage alt="no-data-found-vmais" src={noDataFoundImg} />
        <S.EmptyText>{message ?? 'Nenhum dado encontrado :/'}</S.EmptyText>
      </S.EmptyMainBox>
    </S.EmptyTableMainBox>
  );
}
