import { BoxColumn, BoxRow } from 'components/Containers';
import styled from 'styled-components';

export const EditaVerbaFormBox = styled(BoxColumn)`
  justify-content: stretch;
  overflow-y: scroll;
  gap: 0px;
  box-shadow: none;
  padding: 0px 5px;
  scrollbar-width: none;
`;

interface IFormRowColumn {
  width?: string;
  align?: string;
  justify?: string;
  wrap?: string;
}

export const EditaVerbaFormRow = styled(BoxRow)<IFormRowColumn>`
  justify-content: ${(props) => (props.justify ? props.justify : 'space-between')};
  align-items: ${(props) => (props.align ? props.align : 'center')};
  gap: 15px;
  box-shadow: none;
  padding: 5px 0px;
  width: ${(props) => (props.width ? props.width : '100%')};
  flex-wrap: ${(props) => (props.wrap ? props.wrap : 'nowrap')};
`;

export const EditaVerbaFormColumn = styled(BoxColumn)<IFormRowColumn>`
  box-shadow: none;
  width: ${(props) => (props.width ? props.width : '45%')};
  gap: 0px;
  padding: 0px;
  align-items: ${(props) => (props.align ? props.align : 'flex-start')};
  flex: 1;
  height: 100%;
`;

export const EditaVerbaCheckBox = styled(BoxRow)`
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  box-shadow: none;
  padding: 5px 0px;
  width: 100%;
  white-space: nowrap;
`;
