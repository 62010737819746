import { Container, Header } from 'components/Containers';
import styled from 'styled-components';

export const VerbasMainBox = styled(Container)`
  gap: 15px;
`;

export const VerbasHeaderMainBox = styled(Header)`
  justify-content: flex-end;
  position: sticky;
`;

export const VerbasHeaderRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 5px;
  gap: 25px;
`;

export const VerbasRadioInputBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

export const VerbasInputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  input::focus {
    outline: none;
    border: none;
  }
`;

export const VerbasContentBox = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  border-radius: 7.5px;
  width: auto;
  left: 0px;
  z-index: 9;
  flex: 1;
`;

export const VerbasContentItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: auto;
  gap: 5px;
  height: 100%;
  height: 65svh;
  width: 50%;
  flex: 1;

  @media (max-width: 1060px) {
    height: auto;
    width: 100%;
    flex: auto;
  }
`;
