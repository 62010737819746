import * as S from './selectClientModal.styles';
import { Dialog } from 'primereact/dialog';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import useMediaQuery from 'hooks/useMediaQuery';
import { SetStateAction, useEffect, useState } from 'react';
import { useVerbas } from 'modules/verbas/verbas.context';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import InputText from 'components/Inputs/InputText/text.input';
import { getClienteByRca } from 'client/api';

export default function SelectClientModal({
  showModal,
  setShowModal,
  handleCliente,
  codRca,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<SetStateAction<boolean>>;
  handleCliente: (cli: any) => void;
  codRca: number;
}) {
  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const [clientsList, setClientsList] = useState(null);
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [searchRegraText, setSearchRegraText] = useState('');

  const { selectedEmpresa } = useVerbas();

  useEffect(() => {
    if (showModal) {
      handleGetClientesRca();
    } else {
      setSelectedCliente(null);
      setClientsList(null);
    }
  }, [showModal]);

  const handleGetClientesRca = () => {
    if (codRca && selectedEmpresa.codEmpresa) {
      getClienteByRca(selectedEmpresa.codEmpresa, codRca).then((clis: any[]) => {
        const formattedClis = [];
        for (const c of clis) {
          formattedClis.push({
            ...c,
            label: `[${c.CODCLI}] - ${c.RAZAOSOCIAL}`,
          });
        }
        setClientsList(formattedClis);
      });
    }
  };

  const handleRegraSelectionChange = (e: { value: any[] }) => {
    let newSelectedRegra = e.value;
    setSelectedCliente(newSelectedRegra);
  };

  return (
    <Dialog
      header={'Selecionar Regra'}
      visible={showModal}
      onHide={() => {
        setShowModal(false);
      }}
      style={{
        width: isWebScreen ? '35svw' : '95svw',
      }}
    >
      <S.SelectClientFormBox>
        <S.SelectClientFormRow>
          <InputText
            label="Buscar"
            placeholder="Buscar regra"
            width="100%"
            value={searchRegraText}
            onChange={(e) => {
              setSearchRegraText(e.toUpperCase());
            }}
          />
        </S.SelectClientFormRow>
        <S.SelectClientTableBox>
          <DataTable
            value={(clientsList ?? []).filter((regra) => regra.label.toUpperCase().includes(searchRegraText))}
            paginator={false}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rows={100}
            rowHover
            stripedRows
            emptyMessage={'Nenhum cliente encontrado'}
            size="small"
            selection={selectedCliente}
            onSelectionChange={handleRegraSelectionChange}
            selectionMode="checkbox"
            scrollable
            scrollHeight="50svh"
          >
            <Column
              header=""
              selectionMode="single"
              align="left"
              style={{
                maxWidth: '60px',
              }}
            />
            <Column header="Cliente" field="label" align="left" />
          </DataTable>
        </S.SelectClientTableBox>

        <S.SelectClientFormRow>
          <Button
            text="Confirmar seleção do cliente"
            icon={<icons.Check />}
            color="green"
            width="100%"
            disabled={!selectedCliente}
            tooltip={!selectedCliente ? 'Nenhum cliente selecionado' : ''}
            onClick={() => {
              selectedCliente && handleCliente(selectedCliente);
              setShowModal(false);
            }}
          />
        </S.SelectClientFormRow>
      </S.SelectClientFormBox>
    </Dialog>
  );
}
