import React from 'react';
import { IoChevronDownSharp, IoChevronUpSharp } from 'react-icons/io5';
import { MdReceipt, MdMap } from 'react-icons/md';
import { usePedidos } from 'modules/pedido/pedidos.context';
import * as S from './clientAccordeon.styles';
import { formatCPFOrCNPJ } from 'utils/formatCNPJ';
import { formatCellphone } from 'utils/formatCellphone';
import useMediaQuery from 'hooks/useMediaQuery';

export default function ClientAccordeon() {
  const { cliente } = usePedidos();

  const [showInfo, setShowInfo] = React.useState(false);

  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  return (
    <S.ClientMainBox display={showInfo}>
      <S.ClientHeaderBox
        onClick={() => {
          setShowInfo(!showInfo);
        }}
      >
        <S.ClientHeaderTitle id={showInfo} key={showInfo ? 'name' : 'title'}>
          {showInfo ? 'Cliente' : `[${cliente.CODCLI}] ${cliente.CLIENTE}`}
        </S.ClientHeaderTitle>
        {!showInfo ? <IoChevronUpSharp /> : <IoChevronDownSharp />}
      </S.ClientHeaderBox>

      <S.ClientContentBox display={showInfo}>
        <S.ClientInfoBox>
          <S.ClientInfoTitle>
            <MdReceipt color={'#F64f3d'} /> Dados
          </S.ClientInfoTitle>
          <S.ClientInfoContent>
            {isWebScreen ? (
              <S.ClientInfoContentRow>
                {cliente && cliente.CLIENTE} [{formatCPFOrCNPJ(cliente && cliente.CNPJCPF)}]
              </S.ClientInfoContentRow>
            ) : (
              <S.ClientInfoContentRow>{cliente && cliente.CLIENTE}</S.ClientInfoContentRow>
            )}
            {!isWebScreen && (
              <S.ClientInfoContentRow> [{formatCPFOrCNPJ(cliente && cliente.CNPJCPF)}]</S.ClientInfoContentRow>
            )}
            <S.ClientInfoContentRow>{formatCellphone(cliente && cliente.TELENT)}</S.ClientInfoContentRow>
            <S.ClientInfoContentRow>{cliente && cliente.EMAIL && cliente.EMAILNFE}</S.ClientInfoContentRow>
          </S.ClientInfoContent>
        </S.ClientInfoBox>

        <S.ClientInfoBox>
          <S.ClientInfoTitle>
            <MdMap color={'#F64f3d'} /> Endereço
          </S.ClientInfoTitle>
          <S.ClientInfoContent>
            <S.ClientInfoContentRow>
              {cliente && cliente.ENDERENT} {cliente && isWebScreen && `, Nº${cliente.NUMEROENT ?? ''}`}
            </S.ClientInfoContentRow>
            {!isWebScreen && (
              <S.ClientInfoContentRow>
                {cliente && 'Nº' + cliente.NUMEROENT}, {cliente && cliente.BAIRROENT}
                {cliente && `, ${cliente.MUNICENT}`}
                {cliente && `, CEP: ${cliente.CEPENT}`}
              </S.ClientInfoContentRow>
            )}
            {isWebScreen && (
              <S.ClientInfoContentRow>
                {cliente && cliente.BAIRROENT} / {cliente && cliente.MUNICENT + ' - '} {cliente && cliente.ESTENT}
              </S.ClientInfoContentRow>
            )}
            {isWebScreen && <S.ClientInfoContentRow>CEP: {cliente && cliente.CEPENT}</S.ClientInfoContentRow>}
            <S.ClientInfoContentRow>{cliente && cliente.COMPLEMENTOENT}</S.ClientInfoContentRow>
          </S.ClientInfoContent>
        </S.ClientInfoBox>
      </S.ClientContentBox>
    </S.ClientMainBox>
  );
}
