import InputText from 'components/Inputs/InputText/text.input';
import * as S from './editaVerbaModal.styles';
import useMediaQuery from 'hooks/useMediaQuery';
import { useVerbas } from 'modules/verbas/verbas.context';
import { IVerbasCab } from 'modules/verbas/verbas.types';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import InputDate from 'components/Inputs/InputDate/date.input';
import InputSwitch from 'components/Inputs/InputSwitch/switch.input';
import icons from 'components/Icons/icons.index';
import Button from 'components/Button/button.index';
import Dropdown from 'components/Dropdown/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { putVerbaCab } from 'client/api/verbas';
import { toast } from 'react-toastify';
import UseSelectFilial from 'hooks/UseSelectFilial/selectFilial';
import SelectRegraModal from '../selectRegraModal/selectRegraModal.index';

export default function EditaVerbaModal() {
  const {
    showEditaVerbaModal,
    setShowEditaVerbaModal,
    toEditVerba,
    handleGetVerbasList,
    selectedEmpresa,
    selectedUsuario,
  } = useVerbas();

  const { SelectFilial, handleSelectFilialByCode } = UseSelectFilial(
    selectedEmpresa.codEmpresa,
    selectedUsuario.codUsuario,
  );

  const [showSelectRegraModal, setShowSelectRegraModal] = useState(false);

  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const [editedVerba, setEditedVerba] = useState<IVerbasCab>(null);

  useEffect(() => {
    if (toEditVerba) {
      setEditedVerba(toEditVerba);
      handleSelectFilialByCode(toEditVerba.CODFILIAL);
    }
  }, [toEditVerba, showEditaVerbaModal]);

  function setTimeToMidnight(date) {
    const newDate = new Date(date).toJSON();
    return newDate;
  }

  const handleSaveVerbaEdit = () => {
    const newEditVerbaData = {
      codEmpresa: editedVerba.COD_EMPRESA,
      numverba: editedVerba.NUMVERBA,
      codgrupo: 0,
      codfilial: editedVerba.CODFILIAL,
      valor: editedVerba.VALOR,
      dtiniciovigencia: editedVerba.DTINICIOVIGENCIA.replace('03:00:00', '00:00:00'),
      dtfimvigencia: editedVerba.DTFIMVIGENCIA.replace('03:00:00', '23:59:59'),
      descricao: editedVerba.DESCRICAO,
      percdescmax: editedVerba.PERCDESCMAX,
      dtcadastro: editedVerba.DTCADASTRO,
      codfunccad: editedVerba.CODFUNCCAD,
      tipoVerba: 'V',
      codregra: editedVerba.CODREGRA,
      percdescmaxItem: editedVerba.PERCDESCMAX_ITEM,
      permiteSaldoNeg: editedVerba.PERMITE_SALDO_NEG,
      permiteAlterarDist: editedVerba.PERMITE_ALTERAR_DIST,
      codregrasAplicAuto: editedVerba.CODREGRAS_APLIC_AUTO ?? '',
      bnfFv: editedVerba.BNF_FV,
      bnfFvAprovar: editedVerba.BNF_FV_APROVAR,
      descFv: editedVerba.DESC_FV,
      descFvAprovar: editedVerba.DESC_FV_APROVAR,
      geraCredPini: editedVerba.GERA_CRED_PINI,
      geraCredPfim: editedVerba.GERA_CRED_PFIM,
      debitoFlex: 'M',
      debcredOrigT: editedVerba.DEBCRED_ORIG_T,
      debcredOrigB: editedVerba.DEBCRED_ORIG_B,
      debcredOrigR: editedVerba.DEBCRED_ORIG_R,
      debcredOrigF: editedVerba.DEBCRED_ORIG_F,
      debcredOrigA: editedVerba.DEBCRED_ORIG_A,
      debcredOrigC: editedVerba.DEBCRED_ORIG_C,
      debcredOrigW: editedVerba.DEBCRED_ORIG_W,
      debcredOrigOff: editedVerba.DEBCRED_ORIG_OFF,
      geraCredAutomatico: editedVerba.GERA_CRED_AUTOMATICO,
      geraCredAcrePini: editedVerba.GERA_CRED_PINI ?? 0,
      geraCredAcrePfim: editedVerba.GERA_CRED_PFIM ?? 0,
      geraCredValor: editedVerba.GERA_CRED_VALOR ?? 0,
      geraCredPercFixo: editedVerba.GERA_CRED_PERC_FIXO ?? 0,
      geraCredPedFat: editedVerba.GERA_CRED_PED_FAT ?? 'F',
      aplicaOferta: editedVerba.APLICA_OFERTA,
      observacao: editedVerba.OBSERVACAO ?? '',
    };

    putVerbaCab(newEditVerbaData)
      .then((res) => {
        if (res.succeeded) {
          handleGetVerbasList();
          toast.success('Sucesso ao editar verba');
          setShowEditaVerbaModal(false);
        }
      })
      .catch((err) => {
        console.log('Err :', err);
        toast.error('Falha inesperada ao editar verba');
      });
  };

  const DEB_CRE_PED_FAT_OPTIONS = [
    {
      code: 'F',
      label: 'Fatura',
    },
    {
      code: 'P',
      label: 'Pedido',
    },
  ];

  const handleRegraSelection = (codRegra: number) => {
    setEditedVerba((prev) => ({
      ...prev,
      CODREGRA: codRegra,
    }));
  };

  const SelectRegraComercial = () => {
    return (
      <InputText
        label="Restringir itens da lista (Regra comercial)"
        number
        value={editedVerba.CODREGRA}
        width={isWebScreen ? '40%' : '100%'}
        icon={<icons.Search />}
        onChange={(e) => {
          setEditedVerba((prev) => ({
            ...prev,
            CODREGRA: +e,
          }));
        }}
        iconAction={() => {
          setShowSelectRegraModal(true);
        }}
      />
    );
  };

  const PercDescMax = () => {
    return (
      <S.EditaVerbaFormColumn>
        <S.EditaVerbaFormRow>% Desc. Máximo:</S.EditaVerbaFormRow>
        <S.EditaVerbaFormRow>
          <InputText
            label="Item"
            value={editedVerba.PERCDESCMAX_ITEM}
            width={isWebScreen ? '45%' : '45%'}
            number
            onChange={(e) => {
              setEditedVerba((prev) => ({
                ...prev,
                PERCDESCMAX_ITEM: +e,
              }));
            }}
            icon={<icons.Percent />}
          />
          <InputText
            label="Pedido"
            value={editedVerba.PERCDESCMAX}
            width={isWebScreen ? '45%' : '45%'}
            number
            onChange={(e) => {
              setEditedVerba((prev) => ({
                ...prev,
                PERCDESCMAX: +e,
              }));
            }}
            icon={<icons.Percent />}
          />
        </S.EditaVerbaFormRow>
      </S.EditaVerbaFormColumn>
    );
  };

  const GeraCredPercAndFixo = () => {
    return (
      <S.EditaVerbaFormColumn width={'50%'}>
        <S.EditaVerbaFormRow></S.EditaVerbaFormRow>
        <S.EditaVerbaFormRow>
          <InputText
            label="Gera CRÉDITO %Fixo"
            value={editedVerba.GERA_CRED_PERC_FIXO}
            icon={<icons.Percent />}
            width="48%"
            onChange={(e) => {
              setEditedVerba((prev) => ({
                ...prev,
                GERA_CRED_PERC_FIXO: e,
              }));
            }}
          />

          <InputText
            label="Gera CRÉDITO Vl. Fixo"
            value={editedVerba.GERA_CRED_VALOR}
            icon={<icons.Currency />}
            width="48%"
            onChange={(e) => {
              setEditedVerba((prev) => ({
                ...prev,
                GERA_CRED_VALOR: e,
              }));
            }}
          />
        </S.EditaVerbaFormRow>
      </S.EditaVerbaFormColumn>
    );
  };

  if (editedVerba) {
    return (
      <Dialog
        header={() => {
          return <b>{`Editar verba [${editedVerba.NUMVERBA}] ${editedVerba.DESCRICAO}`}</b>;
        }}
        visible={showEditaVerbaModal}
        onHide={() => {
          setShowEditaVerbaModal(false);
        }}
        style={{
          width: isWebScreen ? '47svw' : '95svw',
        }}
      >
        <S.EditaVerbaFormBox>
          <S.EditaVerbaFormRow>
            <InputText
              label="Descrição"
              value={editedVerba.DESCRICAO}
              width="60%"
              onChange={(e) => {
                setEditedVerba((prev) => ({
                  ...prev,
                  DESCRICAO: e.toUpperCase(),
                }));
              }}
            />
            <SelectFilial label="Filial" disabled width="35%" />
          </S.EditaVerbaFormRow>

          <S.EditaVerbaFormRow wrap={isWebScreen ? 'nowrap' : 'wrap'}>
            <InputDate
              label="Dt. Ini"
              width={isWebScreen ? '15%' : '30%'}
              value={new Date(editedVerba.DTINICIOVIGENCIA)}
              onChange={(e) => {
                setEditedVerba((prev) => ({
                  ...prev,
                  DTINICIOVIGENCIA: setTimeToMidnight(e),
                }));
              }}
            />
            <InputDate
              label="Dt. Fim"
              width={isWebScreen ? '15%' : '30%'}
              value={new Date(editedVerba.DTFIMVIGENCIA)}
              onChange={(e) => {
                setEditedVerba((prev) => ({
                  ...prev,
                  DTFIMVIGENCIA: setTimeToMidnight(e),
                }));
              }}
            />
            <S.EditaVerbaFormRow width="5%"></S.EditaVerbaFormRow>
            <InputSwitch
              label="Permite Saldo NEGATIVO"
              checked={editedVerba.PERMITE_SALDO_NEG == 'S'}
              width={isWebScreen ? '30%' : '40%'}
              onChange={(e) => {
                setEditedVerba((prev) => ({
                  ...prev,
                  PERMITE_SALDO_NEG: e ? 'S' : 'N',
                }));
              }}
            />
            <InputSwitch
              label="Permite Alterar Distribuição"
              checked={editedVerba.PERMITE_ALTERAR_DIST == 'S'}
              width={isWebScreen ? '30%' : '40%'}
              onChange={(e) => {
                setEditedVerba((prev) => ({
                  ...prev,
                  PERMITE_ALTERAR_DIST: e ? 'S' : 'N',
                }));
              }}
            />
          </S.EditaVerbaFormRow>

          <S.EditaVerbaFormRow wrap={isWebScreen ? 'nowrap' : 'wrap'}>
            <S.EditaVerbaFormColumn>
              <S.EditaVerbaFormRow>Forma Aplicação:</S.EditaVerbaFormRow>
              <S.EditaVerbaFormRow width="70%">
                <InputSwitch
                  label="BNF"
                  checked={editedVerba.BNF_FV == 'S'}
                  onChange={(e) => {
                    setEditedVerba((prev) => ({
                      ...prev,
                      BNF_FV: e ? 'S' : 'N',
                    }));
                  }}
                />
                <InputSwitch
                  label="DESCONTO"
                  checked={editedVerba.DESC_FV == 'S'}
                  onChange={(e) => {
                    setEditedVerba((prev) => ({
                      ...prev,
                      DESC_FV: e ? 'S' : 'N',
                    }));
                  }}
                />
              </S.EditaVerbaFormRow>
            </S.EditaVerbaFormColumn>

            <S.EditaVerbaFormColumn>
              <S.EditaVerbaFormRow>Exige Aprovação:</S.EditaVerbaFormRow>
              <S.EditaVerbaFormRow width="70%">
                <InputSwitch
                  label="BNF"
                  checked={editedVerba.BNF_FV_APROVAR == 'S'}
                  onChange={(e) => {
                    setEditedVerba((prev) => ({
                      ...prev,
                      BNF_FV_APROVAR: e ? 'S' : 'N',
                    }));
                  }}
                />
                <InputSwitch
                  label="DESCONTO"
                  checked={editedVerba.DESC_FV_APROVAR == 'S'}
                  onChange={(e) => {
                    setEditedVerba((prev) => ({
                      ...prev,
                      DESC_FV_APROVAR: e ? 'S' : 'N',
                    }));
                  }}
                />
              </S.EditaVerbaFormRow>
            </S.EditaVerbaFormColumn>

            {isWebScreen && <PercDescMax />}
          </S.EditaVerbaFormRow>

          {!isWebScreen ? (
            <S.EditaVerbaFormRow align={'center'}>
              <PercDescMax />

              <S.EditaVerbaFormColumn width={'100%'}>
                <S.EditaVerbaFormRow></S.EditaVerbaFormRow>
                <SelectRegraComercial />
              </S.EditaVerbaFormColumn>
            </S.EditaVerbaFormRow>
          ) : (
            <></>
          )}

          <S.EditaVerbaFormRow align="center" justify="start">
            {isWebScreen ? <SelectRegraComercial /> : <></>}
            {isWebScreen ? <S.EditaVerbaFormRow width="5%"></S.EditaVerbaFormRow> : <></>}
            <InputSwitch
              label="Gera DÉBITO/CRÉDITO"
              checked={editedVerba.GERA_CRED_AUTOMATICO == 'S'}
              width={isWebScreen ? '25%' : '40%'}
              onChange={(e) => {
                setEditedVerba((prev) => ({
                  ...prev,
                  GERA_CRED_AUTOMATICO: e ? 'S' : 'N',
                }));
              }}
            />

            {editedVerba.GERA_CRED_AUTOMATICO == 'S' && (
              <Dropdown
                label="DÉBITO/CRÉDITO por:"
                value={DEB_CRE_PED_FAT_OPTIONS.filter((op) => op.code == editedVerba.GERA_CRED_PED_FAT)[0] ?? ''}
                options={DEB_CRE_PED_FAT_OPTIONS}
                width={isWebScreen ? '25%' : '40%'}
                onChange={(e) => {
                  setEditedVerba((prev) => ({
                    ...prev,
                    GERA_CRED_PED_FAT: e.value.code,
                  }));
                }}
              />
            )}
          </S.EditaVerbaFormRow>

          {editedVerba.GERA_CRED_AUTOMATICO == 'S' && (
            <S.EditaVerbaFormRow width={'100%'} align="center" wrap={isWebScreen ? 'nowrap' : 'wrap'}>
              <S.EditaVerbaFormColumn width={'50%'}>
                <S.EditaVerbaFormRow>Gera CRÉDITO quando %Acre. Entre:</S.EditaVerbaFormRow>

                <S.EditaVerbaFormRow width="90%">
                  <InputText
                    label=""
                    value={
                      +editedVerba.GERA_CRED_ACRE_PINI < 1 && +editedVerba.GERA_CRED_ACRE_PINI > 0
                        ? (+editedVerba.GERA_CRED_ACRE_PINI).toFixed(3)
                        : editedVerba.GERA_CRED_ACRE_PINI
                    }
                    width="48%"
                    icon={<icons.Percent />}
                    onChange={(e) => {
                      setEditedVerba((prev) => ({
                        ...prev,
                        GERA_CRED_ACRE_PINI: e,
                      }));
                    }}
                    number
                  />
                  <p>a</p>
                  <InputText
                    label=""
                    value={
                      +editedVerba.GERA_CRED_ACRE_PFIM < 1 && +editedVerba.GERA_CRED_ACRE_PFIM > 0
                        ? (+editedVerba.GERA_CRED_ACRE_PFIM).toFixed(3)
                        : editedVerba.GERA_CRED_ACRE_PFIM
                    }
                    width="48%"
                    icon={<icons.Percent />}
                    onChange={(e) => {
                      setEditedVerba((prev) => ({
                        ...prev,
                        GERA_CRED_ACRE_PFIM: e,
                      }));
                    }}
                    number
                  />
                </S.EditaVerbaFormRow>
              </S.EditaVerbaFormColumn>
              {isWebScreen && <GeraCredPercAndFixo />}
            </S.EditaVerbaFormRow>
          )}

          {!isWebScreen && editedVerba.GERA_CRED_AUTOMATICO == 'S' && <GeraCredPercAndFixo />}

          {editedVerba.GERA_CRED_AUTOMATICO == 'S' && (
            <S.EditaVerbaFormColumn width="100%">
              <S.EditaVerbaFormRow>Gera CRÉDITO para origem pedido abaixo:</S.EditaVerbaFormRow>

              <S.EditaVerbaFormRow wrap={isWebScreen ? 'nowrap' : 'wrap'}>
                <S.EditaVerbaCheckBox>
                  <Checkbox
                    checked={editedVerba.DEBCRED_ORIG_F == 'S'}
                    onChange={(e) => {
                      setEditedVerba((prev) => ({
                        ...prev,
                        DEBCRED_ORIG_F: e.checked ? 'S' : 'N',
                      }));
                    }}
                  />
                  <label>[F]orça Vendas</label>
                </S.EditaVerbaCheckBox>

                <S.EditaVerbaCheckBox>
                  <Checkbox
                    checked={editedVerba.DEBCRED_ORIG_T == 'S'}
                    onChange={(e) => {
                      setEditedVerba((prev) => ({
                        ...prev,
                        DEBCRED_ORIG_T: e.checked ? 'S' : 'N',
                      }));
                    }}
                  />
                  <label>[T]elemarketing</label>
                </S.EditaVerbaCheckBox>

                <S.EditaVerbaCheckBox>
                  <Checkbox
                    checked={editedVerba.DEBCRED_ORIG_W == 'S'}
                    onChange={(e) => {
                      setEditedVerba((prev) => ({
                        ...prev,
                        DEBCRED_ORIG_W: e.checked ? 'S' : 'N',
                      }));
                    }}
                  />
                  <label>[W]eb/eCom</label>
                </S.EditaVerbaCheckBox>

                <S.EditaVerbaCheckBox>
                  <Checkbox
                    checked={editedVerba.DEBCRED_ORIG_R == 'S'}
                    onChange={(e) => {
                      setEditedVerba((prev) => ({
                        ...prev,
                        DEBCRED_ORIG_R: e.checked ? 'S' : 'N',
                      }));
                    }}
                  />
                  <label>[R]Balcão Res</label>
                </S.EditaVerbaCheckBox>
              </S.EditaVerbaFormRow>

              <S.EditaVerbaFormRow wrap={isWebScreen ? 'nowrap' : 'wrap'}>
                <S.EditaVerbaCheckBox>
                  <Checkbox
                    checked={editedVerba.DEBCRED_ORIG_B == 'S'}
                    onChange={(e) => {
                      setEditedVerba((prev) => ({
                        ...prev,
                        DEBCRED_ORIG_B: e.checked ? 'S' : 'N',
                      }));
                    }}
                  />
                  <label>[B]alcão</label>
                </S.EditaVerbaCheckBox>

                <S.EditaVerbaCheckBox>
                  <Checkbox
                    checked={editedVerba.DEBCRED_ORIG_C == 'S'}
                    onChange={(e) => {
                      setEditedVerba((prev) => ({
                        ...prev,
                        DEBCRED_ORIG_C: e.checked ? 'S' : 'N',
                      }));
                    }}
                  />
                  <label>[C]all Center</label>
                </S.EditaVerbaCheckBox>

                <S.EditaVerbaCheckBox>
                  <Checkbox
                    checked={editedVerba.DEBCRED_ORIG_A == 'S'}
                    onChange={(e) => {
                      setEditedVerba((prev) => ({
                        ...prev,
                        DEBCRED_ORIG_A: e.checked ? 'S' : 'N',
                      }));
                    }}
                  />
                  <label>[A]uto Serviço</label>
                </S.EditaVerbaCheckBox>

                <S.EditaVerbaCheckBox>
                  <Checkbox
                    checked={editedVerba.DEBCRED_ORIG_OFF == 'S'}
                    onChange={(e) => {
                      setEditedVerba((prev) => ({
                        ...prev,
                        DEBCRED_ORIG_OFF: e.checked ? 'S' : 'N',
                      }));
                    }}
                  />
                  <label>OFERTA</label>
                </S.EditaVerbaCheckBox>
              </S.EditaVerbaFormRow>
            </S.EditaVerbaFormColumn>
          )}

          <S.EditaVerbaFormRow align="flex-end">
            <InputText
              label="Observação"
              value={editedVerba.OBSERVACAO}
              width="68%"
              onChange={(e) => {
                setEditedVerba((prev) => ({
                  ...prev,
                  OBSERVACAO: e.toUpperCase(),
                }));
              }}
            />
            <Button
              text="Salvar"
              icon={<icons.Check />}
              color="green"
              tooltip="Salvar alterações na verba"
              width="30%"
              onClick={() => {
                handleSaveVerbaEdit();
              }}
            />
          </S.EditaVerbaFormRow>
        </S.EditaVerbaFormBox>
        <SelectRegraModal
          showModal={showSelectRegraModal}
          setShowModal={setShowSelectRegraModal}
          handleRegra={handleRegraSelection}
        />
      </Dialog>
    );
  } else {
    return <></>;
  }
}
