import * as S from './novaVerbaModal.styles';
import { Dialog } from 'primereact/dialog';
import InputText from 'components/Inputs/InputText/text.input';
import InputDate from 'components/Inputs/InputDate/date.input';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import useMediaQuery from 'hooks/useMediaQuery';
import { useVerbas } from 'modules/verbas/verbas.context';
import { toast } from 'react-toastify';
import { Checkbox } from 'primereact/checkbox';
import Dropdown from 'components/Dropdown/dropdown';
import InputSwitch from 'components/Inputs/InputSwitch/switch.input';
import { useEffect, useState } from 'react';
import { postVerbaCab } from 'client/api/verbas';
import { INewVerbaCab } from 'modules/verbas/verbas.types';
import UseSelectFilial from 'hooks/UseSelectFilial/selectFilial';
import { useUser } from 'client/hooks';
import SelectRegraModal from '../selectRegraModal/selectRegraModal.index';

export default function NovaVerbaModal() {
  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const { showNovaVerbaModal, setShowNovaVerbaModal, selectedEmpresa, handleGetVerbasList, selectedUsuario } =
    useVerbas();

  const { filiais } = UseSelectFilial(selectedEmpresa.codEmpresa, selectedUsuario.codUsuario);

  const [showSelectRegraModal, setShowSelectRegraModal] = useState(false);

  const [selectedFilial, setSelectedFilial] = useState(null);

  const [newRegra, setNewVerba] = useState<INewVerbaCab>();

  const { data: user } = useUser();

  function setTimeToMidnight(date) {
    const newDate = new Date(date).toJSON();
    return newDate;
  }

  function setTimeToLessHours(date, hours) {
    const newDate = new Date(date);
    newDate.setHours(newDate.getHours() - hours);
    return newDate.toISOString();
  }

  const defaultVerba = {
    codEmpresa: +selectedEmpresa.codEmpresa,
    numverba: 0,
    codgrupo: 0,
    codfilial: 0,
    valor: 0,
    dtiniciovigencia: setTimeToMidnight(new Date()),
    dtfimvigencia: setTimeToMidnight(new Date()),
    descricao: '',
    percdescmax: 0,
    dtcadastro: setTimeToLessHours(new Date(), 3),
    codfunccad: user ? user.codUsuario : 0,
    tipoVerba: 'V',
    codregra: 0,
    percdescmaxItem: 0,
    permiteSaldoNeg: 'N',
    permiteAlterarDist: 'N',
    codregrasAplicAuto: '',
    bnfFv: 'N',
    bnfFvAprovar: 'N',
    descFv: 'N',
    descFvAprovar: 'N',
    geraCredPini: 0,
    geraCredPfim: 0,
    debitoFlex: 'M',
    debcredOrigT: 'N',
    debcredOrigB: 'N',
    debcredOrigR: 'N',
    debcredOrigF: 'N',
    debcredOrigA: 'N',
    debcredOrigC: 'N',
    debcredOrigW: 'N',
    debcredOrigOff: 'N',
    geraCredAutomatico: 'N',
    geraCredAcrePini: 0,
    geraCredAcrePfim: 0,
    geraCredValor: 0,
    geraCredPercFixo: 0,
    geraCredPedFat: 'F',
    aplicaOferta: 'N',
    observacao: '',
  };

  useEffect(() => {
    setNewVerba(defaultVerba);
    setSelectedFilial(null);
  }, [showNovaVerbaModal]);

  const handleSaveVerbaNew = () => {
    const newRegraToInsert = { ...newRegra };
    if (newRegraToInsert.dtiniciovigencia.includes('T')) {
      const newDtIni = newRegraToInsert.dtiniciovigencia.split('T');
      newRegraToInsert.dtiniciovigencia = newDtIni[0] + 'T00:00:00.000Z';
    } else {
      newRegraToInsert.dtiniciovigencia = newRegraToInsert.dtiniciovigencia.replace('03:00:00', '00:00:00');
    }
    if (newRegraToInsert.dtfimvigencia.includes('T')) {
      const newDtFim = newRegraToInsert.dtfimvigencia.split('T');
      newRegraToInsert.dtfimvigencia = newDtFim[0] + 'T23:59:59.000Z';
    } else {
      newRegraToInsert.dtfimvigencia = newRegraToInsert.dtfimvigencia.replace('03:00:00', '23:59:59');
    }

    if (newRegraToInsert.descricao == '') {
      toast.error('Campo descricao não informado');
      return;
    } else if (!selectedFilial || (selectedFilial && selectedFilial.codFilial == 0)) {
      toast.error('Campo filial não informado');
      return;
    }
    newRegraToInsert.codfilial = selectedFilial.codFilial;
    postVerbaCab(newRegraToInsert)
      .then((res) => {
        if (res.succeeded) {
          handleGetVerbasList();
          toast.success('Sucesso ao salvar verba');
          setShowNovaVerbaModal(false);
        }
      })
      .catch((err) => {
        console.log('Err :', err);
        toast.error('Falha inesperada ao salvar verba');
      });
  };

  const DEB_CRE_PED_FAT_OPTIONS = [
    {
      code: 'F',
      label: 'Fatura',
    },
    {
      code: 'P',
      label: 'Pedido',
    },
  ];

  const handleRegraSelection = (codRegra: number) => {
    setNewVerba((prev) => ({
      ...prev,
      codregra: codRegra,
    }));
  };

  const SelectRegraComercial = () => {
    return (
      <InputText
        label="Restringir itens da lista (Regra comercial)"
        number
        value={newRegra.codregra}
        width={isWebScreen ? '40%' : '100%'}
        icon={<icons.Search />}
        onChange={(e) => {
          setNewVerba((prev) => ({
            ...prev,
            codregra: +e,
          }));
        }}
        iconAction={() => {
          setShowSelectRegraModal(true);
        }}
      />
    );
  };

  const PercDescMax = () => {
    return (
      <S.NovaVerbaFormColumn width={isWebScreen ? '45%' : '45%'}>
        <S.NovaVerbaFormRow>% Desc. Máximo:</S.NovaVerbaFormRow>
        <S.NovaVerbaFormRow>
          <InputText
            label="Item"
            value={newRegra.percdescmaxItem}
            width={isWebScreen ? '45%' : '45%'}
            number
            onChange={(e) => {
              setNewVerba((prev) => ({
                ...prev,
                percdescmaxItem: +e,
              }));
            }}
            icon={<icons.Percent />}
          />
          <InputText
            label="Pedido"
            value={newRegra.percdescmax}
            width={isWebScreen ? '45%' : '45%'}
            number
            onChange={(e) => {
              setNewVerba((prev) => ({
                ...prev,
                percdescmax: +e,
              }));
            }}
            icon={<icons.Percent />}
          />
        </S.NovaVerbaFormRow>
      </S.NovaVerbaFormColumn>
    );
  };

  const GeraCredPercAndFixo = () => {
    return (
      <S.NovaVerbaFormColumn width={isWebScreen ? '45%' : '95%'}>
        <S.NovaVerbaFormRow></S.NovaVerbaFormRow>
        <S.NovaVerbaFormRow>
          <InputText
            label="Gera CRÉDITO %Fixo"
            value={newRegra.geraCredPercFixo}
            icon={<icons.Percent />}
            width="48%"
            onChange={(e) => {
              setNewVerba((prev) => ({
                ...prev,
                geraCredPercFixo: +e,
              }));
            }}
          />

          <InputText
            label="Gera CRÉDITO Vl. Fixo"
            value={newRegra.geraCredValor}
            icon={<icons.Currency />}
            width="48%"
            onChange={(e) => {
              setNewVerba((prev) => ({
                ...prev,
                geraCredValor: +e,
              }));
            }}
          />
        </S.NovaVerbaFormRow>
      </S.NovaVerbaFormColumn>
    );
  };

  if (newRegra) {
    return (
      <Dialog
        header={() => {
          return <b>{`Nova verba`}</b>;
        }}
        visible={showNovaVerbaModal}
        onHide={() => {
          setShowNovaVerbaModal(false);
        }}
        style={{
          width: isWebScreen ? '47svw' : '95svw',
        }}
      >
        <S.NovaVerbaFormBox>
          <S.NovaVerbaFormRow>
            <InputText
              label="Descrição"
              value={newRegra.descricao}
              width="60%"
              onChange={(e) => {
                setNewVerba((prev) => ({
                  ...prev,
                  descricao: e.toUpperCase(),
                }));
              }}
            />

            <Dropdown
              label="Filial"
              value={selectedFilial}
              options={filiais}
              optionLabel=""
              width="35%"
              placeholder="Selecione uma filial"
              onChange={(e) => {
                setSelectedFilial(e.value);
              }}
            />
          </S.NovaVerbaFormRow>

          <S.NovaVerbaFormRow wrap={isWebScreen ? 'nowrap' : 'wrap'}>
            <InputDate
              label="Dt. Ini"
              width={isWebScreen ? '15%' : '30%'}
              value={new Date(newRegra.dtiniciovigencia)}
              onChange={(e) => {
                setNewVerba((prev) => ({
                  ...prev,
                  dtiniciovigencia: setTimeToMidnight(e),
                }));
              }}
            />
            <InputDate
              label="Dt. Fim"
              width={isWebScreen ? '15%' : '30%'}
              value={new Date(newRegra.dtfimvigencia)}
              onChange={(e) => {
                setNewVerba((prev) => ({
                  ...prev,
                  dtfimvigencia: setTimeToMidnight(e),
                }));
              }}
            />

            <S.NovaVerbaFormRow width="5%"></S.NovaVerbaFormRow>
            <InputSwitch
              label="Permite Saldo NEGATIVO"
              checked={newRegra.permiteSaldoNeg == 'S'}
              width={isWebScreen ? '30%' : '40%'}
              onChange={(e) => {
                setNewVerba((prev) => ({
                  ...prev,
                  permiteSaldoNeg: e ? 'S' : 'N',
                }));
              }}
            />
            <InputSwitch
              label="Permite Alterar Distribuição"
              checked={newRegra.permiteAlterarDist == 'S'}
              width={isWebScreen ? '35%' : '45%'}
              onChange={(e) => {
                setNewVerba((prev) => ({
                  ...prev,
                  permiteAlterarDist: e ? 'S' : 'N',
                }));
              }}
            />
          </S.NovaVerbaFormRow>

          <S.NovaVerbaFormRow wrap={isWebScreen ? 'nowrap' : 'wrap'}>
            <S.NovaVerbaFormColumn>
              <S.NovaVerbaFormRow>Forma Aplicação:</S.NovaVerbaFormRow>
              <S.NovaVerbaFormRow width="70%">
                <InputSwitch
                  label="BNF"
                  checked={newRegra.bnfFv == 'S'}
                  onChange={(e) => {
                    setNewVerba((prev) => ({
                      ...prev,
                      bnfFv: e ? 'S' : 'N',
                    }));
                  }}
                />
                <InputSwitch
                  label="DESCONTO"
                  checked={newRegra.descFv == 'S'}
                  onChange={(e) => {
                    setNewVerba((prev) => ({
                      ...prev,
                      descFv: e ? 'S' : 'N',
                    }));
                  }}
                />
              </S.NovaVerbaFormRow>
            </S.NovaVerbaFormColumn>

            <S.NovaVerbaFormColumn>
              <S.NovaVerbaFormRow>Exige Aprovação:</S.NovaVerbaFormRow>
              <S.NovaVerbaFormRow width="70%">
                <InputSwitch
                  label="BNF"
                  checked={newRegra.bnfFvAprovar == 'S'}
                  onChange={(e) => {
                    setNewVerba((prev) => ({
                      ...prev,
                      bnfFvAprovar: e ? 'S' : 'N',
                    }));
                  }}
                />
                <InputSwitch
                  label="DESCONTO"
                  checked={newRegra.descFvAprovar == 'S'}
                  onChange={(e) => {
                    setNewVerba((prev) => ({
                      ...prev,
                      descFvAprovar: e ? 'S' : 'N',
                    }));
                  }}
                />
              </S.NovaVerbaFormRow>
            </S.NovaVerbaFormColumn>

            {isWebScreen && <PercDescMax />}
          </S.NovaVerbaFormRow>

          {!isWebScreen ? (
            <S.NovaVerbaFormRow align={'center'}>
              <PercDescMax />

              <S.NovaVerbaFormColumn width={'100%'}>
                <S.NovaVerbaFormRow></S.NovaVerbaFormRow>
                <SelectRegraComercial />
              </S.NovaVerbaFormColumn>
            </S.NovaVerbaFormRow>
          ) : (
            <></>
          )}

          <S.NovaVerbaFormRow align="flex-start" justify="start">
            {isWebScreen ? <SelectRegraComercial /> : <></>}
            {isWebScreen ? <S.NovaVerbaFormRow width="5%"></S.NovaVerbaFormRow> : <></>}
            <InputSwitch
              label="Gera DÉBITO/CRÉDITO"
              checked={newRegra.geraCredAutomatico == 'S'}
              width={isWebScreen ? '25%' : '40%'}
              onChange={(e) => {
                setNewVerba((prev) => ({
                  ...prev,
                  geraCredAutomatico: e ? 'S' : 'N',
                }));
              }}
            />

            {newRegra.geraCredAutomatico == 'S' && (
              <Dropdown
                label="DÉBITO/CRÉDITO por:"
                value={DEB_CRE_PED_FAT_OPTIONS.filter((op) => op.code == newRegra.geraCredPedFat)[0] ?? ''}
                options={DEB_CRE_PED_FAT_OPTIONS}
                width={isWebScreen ? '25%' : '40%'}
                onChange={(e) => {
                  setNewVerba((prev) => ({
                    ...prev,
                    geraCredPedFat: e.value.code,
                  }));
                }}
              />
            )}
          </S.NovaVerbaFormRow>

          {newRegra.geraCredAutomatico == 'S' && (
            <S.NovaVerbaFormRow width={'100%'} align="center" wrap={isWebScreen ? 'nowrap' : 'wrap'}>
              <S.NovaVerbaFormColumn width={'50%'}>
                <S.NovaVerbaFormRow>Gera CRÉDITO quando %Acre. Entre:</S.NovaVerbaFormRow>

                <S.NovaVerbaFormRow width="90%">
                  <InputText
                    label=""
                    value={
                      +newRegra.geraCredAcrePini < 1 && +newRegra.geraCredAcrePini > 0
                        ? (+newRegra.geraCredAcrePini).toFixed(3)
                        : newRegra.geraCredAcrePini
                    }
                    width="48%"
                    icon={<icons.Percent />}
                    onChange={(e) => {
                      setNewVerba((prev) => ({
                        ...prev,
                        geraCredAcrePini: +e,
                      }));
                    }}
                    number
                  />
                  <p>a</p>
                  <InputText
                    label=""
                    value={
                      +newRegra.geraCredAcrePfim < 1 && +newRegra.geraCredAcrePfim > 0
                        ? (+newRegra.geraCredAcrePfim).toFixed(3)
                        : newRegra.geraCredAcrePfim
                    }
                    width="48%"
                    icon={<icons.Percent />}
                    onChange={(e) => {
                      setNewVerba((prev) => ({
                        ...prev,
                        geraCredAcrePfim: +e,
                      }));
                    }}
                    number
                  />
                </S.NovaVerbaFormRow>
              </S.NovaVerbaFormColumn>

              {isWebScreen && <GeraCredPercAndFixo />}
            </S.NovaVerbaFormRow>
          )}

          {!isWebScreen && newRegra.geraCredAutomatico == 'S' && <GeraCredPercAndFixo />}

          {newRegra.geraCredAutomatico == 'S' && (
            <S.NovaVerbaFormColumn width="100%">
              <S.NovaVerbaFormRow>Gera CRÉDITO para origem pedido abaixo:</S.NovaVerbaFormRow>

              <S.NovaVerbaFormRow wrap={isWebScreen ? 'nowrap' : 'wrap'}>
                <S.NovaVerbaCheckBox>
                  <Checkbox
                    checked={newRegra.debcredOrigF == 'S'}
                    onChange={(e) => {
                      setNewVerba((prev) => ({
                        ...prev,
                        debcredOrigF: e.checked ? 'S' : 'N',
                      }));
                    }}
                  />
                  <label>[F]orça Vendas</label>
                </S.NovaVerbaCheckBox>

                <S.NovaVerbaCheckBox>
                  <Checkbox
                    checked={newRegra.debcredOrigT == 'S'}
                    onChange={(e) => {
                      setNewVerba((prev) => ({
                        ...prev,
                        debcredOrigT: e.checked ? 'S' : 'N',
                      }));
                    }}
                  />
                  <label>[T]elemarketing</label>
                </S.NovaVerbaCheckBox>

                <S.NovaVerbaCheckBox>
                  <Checkbox
                    checked={newRegra.debcredOrigW == 'S'}
                    onChange={(e) => {
                      setNewVerba((prev) => ({
                        ...prev,
                        debcredOrigW: e.checked ? 'S' : 'N',
                      }));
                    }}
                  />
                  <label>[W]eb/eCom</label>
                </S.NovaVerbaCheckBox>

                <S.NovaVerbaCheckBox>
                  <Checkbox
                    checked={newRegra.debcredOrigR == 'S'}
                    onChange={(e) => {
                      setNewVerba((prev) => ({
                        ...prev,
                        debcredOrigR: e.checked ? 'S' : 'N',
                      }));
                    }}
                  />
                  <label>[R]Balcão Res</label>
                </S.NovaVerbaCheckBox>
              </S.NovaVerbaFormRow>

              <S.NovaVerbaFormRow wrap={isWebScreen ? 'nowrap' : 'wrap'}>
                <S.NovaVerbaCheckBox>
                  <Checkbox
                    checked={newRegra.debcredOrigB == 'S'}
                    onChange={(e) => {
                      setNewVerba((prev) => ({
                        ...prev,
                        debcredOrigB: e.checked ? 'S' : 'N',
                      }));
                    }}
                  />
                  <label>[B]alcão</label>
                </S.NovaVerbaCheckBox>

                <S.NovaVerbaCheckBox>
                  <Checkbox
                    checked={newRegra.debcredOrigC == 'S'}
                    onChange={(e) => {
                      setNewVerba((prev) => ({
                        ...prev,
                        debcredOrigC: e.checked ? 'S' : 'N',
                      }));
                    }}
                  />
                  <label>[C]all Center</label>
                </S.NovaVerbaCheckBox>

                <S.NovaVerbaCheckBox>
                  <Checkbox
                    checked={newRegra.debcredOrigA == 'S'}
                    onChange={(e) => {
                      setNewVerba((prev) => ({
                        ...prev,
                        debcredOrigA: e.checked ? 'S' : 'N',
                      }));
                    }}
                  />
                  <label>[A]uto Serviço</label>
                </S.NovaVerbaCheckBox>

                <S.NovaVerbaCheckBox>
                  <Checkbox
                    checked={newRegra.debcredOrigOff == 'S'}
                    onChange={(e) => {
                      setNewVerba((prev) => ({
                        ...prev,
                        debcredOrigOff: e.checked ? 'S' : 'N',
                      }));
                    }}
                  />
                  <label>OFERTA</label>
                </S.NovaVerbaCheckBox>
              </S.NovaVerbaFormRow>
            </S.NovaVerbaFormColumn>
          )}

          <S.NovaVerbaFormRow align="flex-end">
            <InputText
              label="Observação"
              value={newRegra.observacao}
              width="68%"
              onChange={(e) => {
                setNewVerba((prev) => ({
                  ...prev,
                  observacao: e.toUpperCase(),
                }));
              }}
            />
            <Button
              text="Salvar"
              icon={<icons.Check />}
              color="green"
              tooltip="Salvar nova verba"
              width="30%"
              onClick={() => {
                handleSaveVerbaNew();
              }}
            />
          </S.NovaVerbaFormRow>
        </S.NovaVerbaFormBox>
        <SelectRegraModal
          showModal={showSelectRegraModal}
          setShowModal={setShowSelectRegraModal}
          handleRegra={handleRegraSelection}
        />
      </Dialog>
    );
  } else {
    return <></>;
  }
}
