import * as S from './vinculoSupervModal.styles';
import { Dialog } from 'primereact/dialog';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import useMediaQuery from 'hooks/useMediaQuery';
import { useVerbas } from 'modules/verbas/verbas.context';
import { SetStateAction, useEffect, useState } from 'react';
import UseSelectSupervisor from 'hooks/UseSelectSupervisor/selectSupervisor';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { IVerbaGaParamsState } from 'modules/verbas/verbas.types';
import InputText from 'components/Inputs/InputText/text.input';
import { toast } from 'react-toastify';

export default function VinculoSupervModal({
  showModal,
  setShowModal,
  handledSupervs,
  setHandledSupervs,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<SetStateAction<boolean>>;
  handledSupervs: IVerbaGaParamsState;
  setHandledSupervs: React.Dispatch<SetStateAction<IVerbaGaParamsState>>;
}) {
  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const { selectedEmpresa, selectedUsuario, selectedVerba, supervisores: verbasGa } = useVerbas();

  const { supervisores } = UseSelectSupervisor(selectedEmpresa.codEmpresa, selectedUsuario.codUsuario);

  const [supervsList, setSupervsList] = useState([]);

  const [searchSupervText, setSearchSupervText] = useState('');

  useEffect(() => {
    if (handledSupervs) {
      const newSupervList = [];
      supervisores?.map((superv) => {
        const isSupervAlreadyOnList = verbasGa?.some((sup) => sup.CODGA == superv.codSupervisor);
        if (!isSupervAlreadyOnList) {
          newSupervList.push({
            COD_EMPRESA: selectedEmpresa.codEmpresa,
            NUMVERBA: selectedVerba.NUMVERBA,
            CODGA: superv.codSupervisor,
            NOME_GA: superv.nomeSupervisor,
            VALOR: 0,
            VLR_DISTRIBUIDO: 0,
            VLR_APLICADO: 0,
            BTNEXTRATO: 0,
            VLR_SALDO: 0,
          });
        }
      });
      setSupervsList([...verbasGa, ...newSupervList]);
    }
  }, [supervisores]);

  const onSelectionChange = (e: { value: any[] }) => {
    let newRemoved = handledSupervs?.removed;
    let newIncluded = e.value;

    handledSupervs?.selected?.map((supervSelected) => {
      const isOnOrigin = handledSupervs?.origin?.some((sup) => sup.CODGA == supervSelected.CODGA);
      const isOnNewSelected = e.value?.some((sup) => sup.CODGA == supervSelected.CODGA);
      if (isOnOrigin && !isOnNewSelected) {
        newRemoved.push(supervSelected);
      }

      const isRemoved = newRemoved?.some((sup) => sup.CODGA == supervSelected.CODGA);
      if (isRemoved && isOnOrigin) {
        const canBeRemoved =
          supervSelected.VLR_DISTRIBUIDO > 0 || supervSelected.VLR_APLICADO > 0 || supervSelected.VLR_SALDO > 0;
        if (canBeRemoved) {
          e.value.push(supervSelected);
          newRemoved = newRemoved?.filter((sup) => sup.CODGA != supervSelected.CODGA);
          toast.error(`Supervisor não pode ser removido pois contém valores atribuidos`);
        }
      }
    });

    e.value?.map((newSup) => {
      const isRemoved = newRemoved?.some((sup) => sup.CODGA == newSup.CODGA);
      const isOnOrigin = handledSupervs?.origin?.some((sup) => sup.CODGA == newSup.CODGA);
      if (isRemoved) {
        newRemoved = newRemoved?.filter((sup) => sup.CODGA != newSup.CODGA);
      }
      if (isRemoved || isOnOrigin) {
        newIncluded = newIncluded?.filter((sup) => sup.CODGA != newSup.CODGA);
      }
    });

    setHandledSupervs((prev) => ({
      ...prev,
      selected: e.value,
      included: newIncluded,
      removed: newRemoved,
    }));
  };

  return (
    <Dialog
      header={'Selecionar Supervisores'}
      visible={showModal}
      onHide={() => {
        setShowModal(false);
      }}
      style={{
        width: isWebScreen ? '35svw' : '95svw',
      }}
    >
      <S.VincSupervFormBox>
        <S.VincSupervFormRow>
          <InputText
            label="Buscar"
            placeholder="Buscar supervisor"
            width="100%"
            value={searchSupervText}
            onChange={(e) => {
              setSearchSupervText(e.toUpperCase());
            }}
          />
        </S.VincSupervFormRow>

        <S.VincSupervTableBox>
          <DataTable
            value={(supervsList ?? [])?.filter(
              (sup) => +sup.CODGA == +searchSupervText || sup.NOME_GA.includes(searchSupervText),
            )}
            paginator={false}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rows={100}
            rowHover
            stripedRows
            emptyMessage={'Nenhum supervisor vinculado'}
            size="small"
            selection={handledSupervs.selected}
            onSelectionChange={onSelectionChange}
            selectionMode="checkbox"
            scrollable
            scrollHeight="50svh"
          >
            <Column
              header=""
              selectionMode="multiple"
              align="left"
              style={{
                maxWidth: '60px',
              }}
            />
            <Column
              field="CODGA"
              header="Supervisor"
              body={(superv) => {
                return `[${superv.CODGA}] ${superv.NOME_GA}`;
              }}
              align="left"
              sortable
            />
          </DataTable>
        </S.VincSupervTableBox>

        <S.VincSupervFormRow>
          <Button
            text="Confirmar"
            icon={<icons.Check />}
            color="green"
            tooltip="Confirmar vinculos"
            width="100%"
            onClick={() => {
              setShowModal(false);
            }}
          />
        </S.VincSupervFormRow>
      </S.VincSupervFormBox>
    </Dialog>
  );
}
