'use client';

import { createContext, SetStateAction, useContext, useEffect, useState } from 'react';
import { IVerbasCab, IVerbasGa, IVerbasRca, VerbasStateProps } from './verbas.types';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import UseSelectUsuario from 'hooks/UseSelectUsuario/selectUsuario';
import UseSelectFilial from 'hooks/UseSelectFilial/selectFilial';
import UseSelectStatus from 'hooks/UseSelectStatus/selectStatus';
import { getVerbasCab, getVerbasGa, getVerbasRca } from 'client/api/verbas';
import { useLoaderEffect } from 'providers/loaderEffect';
import { useNavigate } from 'react-router-dom';

export interface ProdContextProps {
  verbas: VerbasStateProps;
  handleGetVerbasList: () => void;
  selectedVerba: IVerbasCab;
  setSelectedVerba: React.Dispatch<SetStateAction<IVerbasCab>>;
  supervisores: IVerbasGa[];
  setSupervisores: React.Dispatch<SetStateAction<IVerbasGa[]>>;
  vendedores: IVerbasRca[];
  setVendedores: React.Dispatch<SetStateAction<IVerbasRca[]>>;
  searchText: string;
  setSearchText: React.Dispatch<SetStateAction<string>>;
  toEditVerba: IVerbasCab;
  setToEditVerba: React.Dispatch<SetStateAction<IVerbasCab>>;
  showNovaVerbaModal: boolean;
  setShowNovaVerbaModal: React.Dispatch<SetStateAction<boolean>>;
  showEditaVerbaModal: boolean;
  setShowEditaVerbaModal: React.Dispatch<SetStateAction<boolean>>;
  showAjustaCreditoModal: boolean;
  setShowAjustaCreditoModal: React.Dispatch<SetStateAction<boolean>>;
  SelectEmpresa: any;
  selectedEmpresa: any;
  SelectUsuario: any;
  selectedUsuario: any;
  SelectFilial: any;
  SelectStatus: any;
  selectedStatus: string;
  selectedVerbaGa: IVerbasGa;
  setSelectedVerbaGa: React.Dispatch<SetStateAction<IVerbasGa>>;
  selectedVerbaRca: IVerbasRca;
  setSelectedVerbaRca: React.Dispatch<SetStateAction<IVerbasRca>>;
  toAdjustSaldoVerba: IVerbasCab;
  setToAdjustSaldoVerba: React.Dispatch<SetStateAction<IVerbasCab>>;
  handleHistoricoPageLink: (
    type: '' | 'supervisor' | 'vendedor',
    numVerba: number,
    codEmpresa: number,
    odSupervisor: number,
    codRca: number,
  ) => void;
}

export const VerbasContext = createContext({} as ProdContextProps);

export const VerbasProvider = ({ children }: any) => {
  const { SelectEmpresa, selectedEmpresa } = UseSelectEmpresa();
  const { SelectUsuario, selectedUsuario } = UseSelectUsuario(selectedEmpresa.codEmpresa);
  const { SelectFilial, selectedFilial } = UseSelectFilial(selectedEmpresa.codEmpresa, selectedUsuario.codUsuario);
  const { SelectStatus, selectedStatus } = UseSelectStatus();

  const { setLoaderHeader } = useLoaderEffect();

  const [searchText, setSearchText] = useState('');

  const [verbas, setVerbas] = useState<VerbasStateProps>({
    filtered: [],
    unfiltered: [],
  });
  const [selectedVerba, setSelectedVerba] = useState<IVerbasCab>(null);
  const [toEditVerba, setToEditVerba] = useState<IVerbasCab>(null);
  const [toAdjustSaldoVerba, setToAdjustSaldoVerba] = useState<IVerbasCab>(null);

  const [supervisores, setSupervisores] = useState<IVerbasGa[]>([]);
  const [selectedVerbaGa, setSelectedVerbaGa] = useState<IVerbasGa>(null);

  const [vendedores, setVendedores] = useState<IVerbasRca[]>([]);
  const [selectedVerbaRca, setSelectedVerbaRca] = useState<IVerbasRca>(null);

  const [showNovaVerbaModal, setShowNovaVerbaModal] = useState(false);
  const [showEditaVerbaModal, setShowEditaVerbaModal] = useState(false);
  const [showAjustaCreditoModal, setShowAjustaCreditoModal] = useState(false);

  useEffect(() => {
    setLoaderHeader(true);
    setVerbas({
      filtered: [],
      unfiltered: [],
    });
    setSelectedVerba(null);
    setSupervisores([]);
    setVendedores([]);
    handleGetVerbasList();
  }, [selectedEmpresa, selectedFilial, selectedStatus, selectedUsuario]);

  useEffect(() => {
    if (selectedVerba) {
      setSupervisores([]);
      setVendedores([]);
      handleGetVerbasGaList();
    } else {
      setSupervisores([]);
      setVendedores([]);
    }
  }, [selectedVerba]);

  const handleGetVerbasList = async () => {
    setLoaderHeader(false);
    if (selectedEmpresa && selectedFilial && selectedStatus) {
      const verbasListToSet = await getVerbasCab(
        selectedEmpresa.codEmpresa,
        selectedFilial.codFilial,
        selectedStatus == 'N' ? 'I' : selectedStatus == 'S' ? 'A' : 'T',
      );
      setVerbas({
        filtered: verbasListToSet.data ?? [],
        unfiltered: verbasListToSet.data ?? [],
      });

      if (selectedVerba) {
        const newSelectedVerba = verbasListToSet.data?.filter((verba) => verba.NUMVERBA == selectedVerba.NUMVERBA);
        setSelectedVerba(newSelectedVerba[0] ? newSelectedVerba[0] : null);
      }
    }
  };

  const handleGetVerbasGaList = async () => {
    setLoaderHeader(true);
    if (selectedEmpresa && selectedVerba) {
      const verbasGaList = await getVerbasGa(selectedEmpresa.codEmpresa, selectedVerba.NUMVERBA);
      setSupervisores(verbasGaList.data ?? []);
      if ((verbasGaList.data ?? []).length == 1) {
        setSelectedVerbaGa(verbasGaList.data[0]);
      }
    }
    setLoaderHeader(false);
  };
  useEffect(() => {
    setVendedores([]);
    handleGetVerbasRcaList();
  }, [selectedVerbaGa, selectedVerba]);

  const handleGetVerbasRcaList = async () => {
    try {
      setLoaderHeader(true);
      if (selectedEmpresa && selectedVerba && selectedVerbaGa) {
        const verbasRcaList = await getVerbasRca(
          selectedEmpresa.codEmpresa,
          selectedVerba.NUMVERBA,
          selectedVerbaGa.CODGA,
        );
        setVendedores(verbasRcaList.data ?? []);
        if ((verbasRcaList.data ?? []).length == 1) {
          setSelectedVerbaRca(verbasRcaList.data[0]);
        }
      }
      setLoaderHeader(false);
    } catch (err) {
      setLoaderHeader(false);
      console.log('Err :', err);
    }
  };

  const navigate = useNavigate();

  const handleHistoricoPageLink = (
    type: '' | 'supervisor' | 'vendedor',
    numVerba: number,
    codEmpresa: number,
    codSupervisor: number,
    codRca: number,
  ) => {
    navigate(`/verbas/extrato/${type}`, {
      state: { numVerba: numVerba, codEmpresa: codEmpresa, codSupervisor: codSupervisor, codRca: codRca },
    });
  };

  return (
    <VerbasContext.Provider
      value={{
        verbas,
        handleGetVerbasList,
        selectedVerba,
        setSelectedVerba,
        supervisores,
        setSupervisores,
        vendedores,
        setVendedores,
        searchText,
        setSearchText,
        toEditVerba,
        setToEditVerba,
        showNovaVerbaModal,
        setShowNovaVerbaModal,
        showEditaVerbaModal,
        setShowEditaVerbaModal,
        showAjustaCreditoModal,
        setShowAjustaCreditoModal,
        SelectEmpresa,
        selectedEmpresa,
        SelectUsuario,
        selectedUsuario,
        SelectFilial,
        SelectStatus,
        selectedStatus,
        selectedVerbaGa,
        setSelectedVerbaGa,
        selectedVerbaRca,
        setSelectedVerbaRca,
        toAdjustSaldoVerba,
        setToAdjustSaldoVerba,
        handleHistoricoPageLink,
      }}
    >
      {children}
    </VerbasContext.Provider>
  );
};

export const useVerbas = () => useContext(VerbasContext);
