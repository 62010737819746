import * as S from './verbasTable.styles';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useEffect, useState } from 'react';
import { Accordion, Container, dialog, Tag } from 'reactivus';
import { useVerbas } from 'modules/verbas/verbas.context';
import { MdArrowForwardIos, MdOutlineCancel } from 'react-icons/md';
import { trackGCatchError } from 'utils/analytics';
import { IVerbasCab } from 'modules/verbas/verbas.types';
import formatCurrency from 'utils/formatCurrency';
import icons from 'components/Icons/icons.index';
import Button from 'components/Button/button.index';
import { formatDate } from 'utils/formatDate';

export default function VerbasTable() {
  try {
    const {
      verbas,
      selectedVerba,
      setSelectedVerba,
      setToEditVerba,
      setShowEditaVerbaModal,
      searchText,
      setShowAjustaCreditoModal,
      setToAdjustSaldoVerba,
      handleHistoricoPageLink,
    } = useVerbas();

    const [isTableOpen, setIsTableOpen] = useState<boolean>(true);

    useEffect(() => {
      if (selectedVerba) {
        setIsTableOpen(false);
      } else if (!isTableOpen) {
        setIsTableOpen(true);
      }
    }, [selectedVerba]);

    const fixedNumber = (number: number | string) => {
      return `${formatCurrency(+number.toString().replace(',', '.'))}`;
    };

    const formattedNumberTemplate = (verba: IVerbasCab, field: string) => {
      return <p>{fixedNumber(verba[field] ?? 0)}</p>;
    };

    const AaccordionHeaderTemplate = () => {
      const isVerbaAtiva = selectedVerba && selectedVerba.STATUS == 'A';

      return (
        <Container gap="10px">
          {selectedVerba && <MdOutlineCancel onClick={() => setSelectedVerba(null)} />}
          <span>{selectedVerba ? `[${selectedVerba.NUMVERBA}] ${selectedVerba.DESCRICAO}` : 'Verbas'}</span>
          {selectedVerba && (
            <Tag label={!isVerbaAtiva ? 'Expirada' : 'Vigente'} color={!isVerbaAtiva ? 'danger' : 'success'} text />
          )}
        </Container>
      );
    };

    const selectedRowClassName = (verba: IVerbasCab) => {
      if (selectedVerba && verba.NUMVERBA === selectedVerba.NUMVERBA) {
        return {
          'selected-table-row': selectedVerba && verba.NUMVERBA === selectedVerba.NUMVERBA,
        };
      } else {
        return {
          'expirada-table-row': verba.STATUS == 'I',
          'vigente-table-row': verba.STATUS == 'A',
        };
      }
    };

    const selectVerbaTemplate = (verba: IVerbasCab) => {
      return (
        <MdArrowForwardIos
          onClick={(e) => {
            verba.NUMVERBA != 0 && setSelectedVerba(verba);
          }}
        />
      );
    };

    const codFuncTemplate = (verba: IVerbasCab) => {
      return <div>{`[${verba.CODFUNCCAD}] ${''}`}</div>;
    };

    const verbaTemplate = (verba: IVerbasCab) => {
      return <div>{`[${verba.NUMVERBA}] ${verba.DESCRICAO}`}</div>;
    };

    const vigenciaTemplate = (verba: IVerbasCab) => {
      return (
        <S.VigenciaTemplate>
          <span>{`De  ${formatDate(new Date(verba.DTINICIOVIGENCIA), 'BR')}`}</span>
          <span>{`até ${formatDate(new Date(verba.DTFIMVIGENCIA), 'BR')}`}</span>
        </S.VigenciaTemplate>
      );
    };

    const dtCadastroTemplate = (verba: IVerbasCab) => {
      return <S.VigenciaTemplate>{`${formatDate(new Date(verba.DTCADASTRO), 'BR', true)}`}</S.VigenciaTemplate>;
    };

    const actionButtonsTemplate = (verba: IVerbasCab) => {
      return (
        <S.VerbasActionButtonsTemplateBox>
          <Button
            color="gray"
            tooltip="Editar"
            icon={<icons.Edit />}
            rounded
            bgOnlyOnHover
            onClick={() => {
              setShowEditaVerbaModal(true);
              setToEditVerba(verba);
            }}
          />
          <Button
            color="gray"
            tooltip="Crédito"
            icon={<icons.CurrencyChange />}
            rounded
            bgOnlyOnHover
            onClick={() => {
              setShowAjustaCreditoModal(true);
              setToAdjustSaldoVerba(verba);
            }}
          />
          <Button
            color="gray"
            tooltip="Extrato"
            icon={<icons.Receipt />}
            rounded
            bgOnlyOnHover
            onClick={() => {
              handleHistoricoPageLink('', verba.NUMVERBA, verba.COD_EMPRESA, -1, -1);
            }}
          />
        </S.VerbasActionButtonsTemplateBox>
      );
    };

    const handleDuplicateVerba = (verba: IVerbasCab) => {
      dialog
        .show({
          icon: 'question',
          title: 'Duplicar esta verba?',
          html: `[${verba.NUMVERBA}] ${verba.DESCRICAO}`,
          showConfirmButton: true,
          confirmButtonText: 'Sim, duplicar',
          showCancelButton: true,
          cancelButtonText: 'Não, cancelar',
          showCloseButton: true,
        })
        .then((res) => {
          if (res.isConfirmed) {
          } else {
          }
        });
    };

    return (
      <Accordion
        title={selectedVerba ? `[${selectedVerba.NUMVERBA}] ${selectedVerba.DESCRICAO}` : 'Verbas'}
        isOpenControl={selectedVerba ? true : isTableOpen || searchText != ''}
        setIsOpenControl={setIsTableOpen}
        headerTemplate={<AaccordionHeaderTemplate />}
        alwaysOpen
      >
        <S.VerbasTableMainBox>
          <S.VerbasBodyBox>
            <DataTable
              value={
                selectedVerba
                  ? [selectedVerba]
                  : (verbas.filtered ?? [])?.filter(
                      (verba) => +verba.NUMVERBA == +searchText || verba.DESCRICAO.includes(searchText),
                    )
              }
              paginator={false}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              rows={100}
              rowHover
              stripedRows
              rowClassName={selectedRowClassName}
              emptyMessage={'Nenhuma verba encontrada'}
              size="small"
            >
              <Column
                field="NUMVERBA"
                header=""
                body={selectVerbaTemplate}
                align="left"
                style={{
                  maxWidth: '50px',
                }}
              />
              <Column
                field="CODFILIAL"
                header="Filial"
                sortable
                align="left"
                style={{
                  maxWidth: '75px',
                }}
              />
              <Column field="NUMVERBA" header="Verba" sortable align="left" body={verbaTemplate} />
              {/* <Column field="DTINICIO" header="Vigencia" sortable body={vigenciaTemplate} /> */}
              <Column
                field="DTINICIOVIGENCIA"
                header="Dt. Ini"
                sortable
                body={(verba) => {
                  return `${formatDate(new Date(verba.DTINICIOVIGENCIA), 'BR')}`;
                }}
              />
              <Column
                field="DTFIMVIGENCIA"
                header="Dt. Fim"
                sortable
                body={(verba) => {
                  return `${formatDate(new Date(verba.DTFIMVIGENCIA), 'BR')}`;
                }}
              />
              <Column
                field="VALOR"
                header="Valor. VB"
                sortable
                body={(verba) => {
                  return formattedNumberTemplate(verba, 'VALOR');
                }}
              />
              <Column
                field="VLR_DISTRIBUIDO"
                header="Vl. Distribuído"
                sortable
                body={(verba) => {
                  return formattedNumberTemplate(verba, 'VLR_DISTRIBUIDO');
                }}
              />
              <Column
                field="SALDO_DIST"
                header="Saldo Distribuir"
                sortable
                body={(verba) => {
                  return formattedNumberTemplate(verba, 'SALDO_DIST');
                }}
              />
              <Column
                field="VLR_APLICADO"
                header="Vl. Aplicado"
                sortable
                body={(verba) => {
                  return formattedNumberTemplate(verba, 'VLR_APLICADO');
                }}
              />
              <Column
                field="SALDO_APLIC"
                header="Saldo Aplicar"
                sortable
                body={(verba) => {
                  return formattedNumberTemplate(verba, 'SALDO_APLIC');
                }}
              />
              <Column field="" header="Ações" body={actionButtonsTemplate} />
              <Column field="CODFUNCCAD" header="Func. Cad" body={codFuncTemplate} sortable />
              <Column field="DTCADASTRO" header="Dt. Cad" sortable body={dtCadastroTemplate} />
              <Column field="OBSERVACAO" header="Observação" sortable />
            </DataTable>
          </S.VerbasBodyBox>
        </S.VerbasTableMainBox>
      </Accordion>
    );
  } catch (err) {
    trackGCatchError(err, 'verbas/components/verbasTable/verbasTable.index.tsx');
  }
}
