import * as S from './vinculoRcaModal.styles';
import { Dialog } from 'primereact/dialog';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import useMediaQuery from 'hooks/useMediaQuery';
import { useVerbas } from 'modules/verbas/verbas.context';
import { SetStateAction, useEffect, useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { IVerbaRcaParamsState } from 'modules/verbas/verbas.types';
import InputText from 'components/Inputs/InputText/text.input';
import UseSelectVendedor from 'hooks/UseSelectVendedor/selectVendedor';

export default function VinculoRcaModal({
  showModal,
  setShowModal,
  handledRcas,
  setHandledRcas,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<SetStateAction<boolean>>;
  handledRcas: IVerbaRcaParamsState;
  setHandledRcas: React.Dispatch<SetStateAction<IVerbaRcaParamsState>>;
}) {
  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const { selectedEmpresa, selectedUsuario, selectedVerba, selectedVerbaGa } = useVerbas();

  const { vendedores } = UseSelectVendedor(
    selectedEmpresa.codEmpresa,
    selectedUsuario.codUsuario,
    -1,
    selectedVerbaGa ? selectedVerbaGa.CODGA : -1,
  );

  const [rcasList, setRcasList] = useState([]);

  const [searchRcaText, setSearchRcaText] = useState('');

  const [selectedRcas, setSelectedRcas] = useState([]);

  useEffect(() => {
    if (handledRcas) {
      const newRcasList = [];
      vendedores?.map((vendedor, index) => {
        newRcasList.push({
          ID: index,
          COD_EMPRESA: selectedEmpresa.codEmpresa,
          NUMVERBA: selectedVerba.NUMVERBA,
          CODRCA: vendedor.codVendedor,
          NOME: vendedor.nomeVendedor,
          CODCLIP: 0,
          CLIENTE: 0,
          VALOR: 0,
          VLAPLICADO: 0,
          VLR_SALDO: 0,
        });
      });
      setRcasList(newRcasList);
    }
  }, [vendedores]);

  const onSelectionChange = (e: { value: any[] }) => {
    let newIncluded = e.value;
    setSelectedRcas(newIncluded);
  };

  const handleConfirmSelection = () => {
    let newIncluded = selectedRcas;

    newIncluded?.map((newSup) => {
      const haveDataWithoutCli = handledRcas?.selected?.some((sup) => sup.CODCLIP == 0 && sup.CODRCA == newSup.CODRCA);
      if (haveDataWithoutCli) {
        newIncluded = newIncluded?.filter((sup) => sup.CODRCA != newSup.CODRCA);
        newIncluded = newIncluded?.filter((sup) => sup.CODRCA != newSup.CODRCA);
      }
    });

    let uptadedIncludedList = [...handledRcas.included, ...newIncluded];
    let uptadedSelectedList = [...handledRcas.selected, ...newIncluded];

    setHandledRcas((prev) => ({
      ...prev,
      origin: [...prev.origin],
      removed: [],
      selected: uptadedSelectedList,
      included: uptadedIncludedList,
    }));
    setShowModal(false);
  };

  useEffect(() => {
    setSelectedRcas([]);
  }, [showModal]);

  return (
    <Dialog
      header={'Selecionar Vendedores'}
      visible={showModal}
      onHide={() => {
        setShowModal(false);
      }}
      style={{
        width: isWebScreen ? '35svw' : '95svw',
      }}
    >
      <S.VincRcaFormBox>
        <S.VincRcaFormRow>
          <InputText
            label="Buscar"
            placeholder="Buscar vendedor"
            width="100%"
            value={searchRcaText}
            onChange={(e) => {
              setSearchRcaText(e.toUpperCase());
            }}
          />
        </S.VincRcaFormRow>

        <S.VincRcaTableBox>
          <DataTable
            value={(rcasList ?? [])?.filter((sup) => +sup.CODRCA == +searchRcaText || sup.NOME.includes(searchRcaText))}
            paginator={false}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rows={100}
            rowHover
            stripedRows
            emptyMessage={'Nenhum vendedor vinculado'}
            size="small"
            selection={selectedRcas}
            onSelectionChange={onSelectionChange}
            selectionMode="checkbox"
            scrollable
            scrollHeight="50svh"
          >
            <Column
              header=""
              selectionMode="multiple"
              align="left"
              style={{
                maxWidth: '60px',
              }}
            />
            <Column
              field="CODRCA"
              header="Vendedor"
              body={(rca) => {
                return `[${rca.CODRCA}] ${rca.NOME}`;
              }}
              align="left"
              sortable
            />
          </DataTable>
        </S.VincRcaTableBox>

        <S.VincRcaFormRow>
          <Button
            text="Confirmar"
            icon={<icons.Check />}
            color="green"
            tooltip="Confirmar vinculos"
            width="100%"
            onClick={() => {
              handleConfirmSelection();
            }}
          />
        </S.VincRcaFormRow>
      </S.VincRcaFormBox>
    </Dialog>
  );
}
