import * as S from './selectRegraModal.styles';
import { Dialog } from 'primereact/dialog';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import useMediaQuery from 'hooks/useMediaQuery';
import { SetStateAction, useEffect, useState } from 'react';
import { useVerbas } from 'modules/verbas/verbas.context';
import { getAllRegrasComerciais } from 'client/api/regraComercial';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import InputText from 'components/Inputs/InputText/text.input';

export default function SelectRegraModal({
  showModal,
  setShowModal,
  handleRegra,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<SetStateAction<boolean>>;
  handleRegra: (codRegra: number) => void;
}) {
  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const [regrasList, setRegrasList] = useState(null);
  const [selectedRegra, setSelectedRegra] = useState(null);
  const [searchRegraText, setSearchRegraText] = useState('');

  const { selectedEmpresa } = useVerbas();

  useEffect(() => {
    if (showModal) {
      handleGetRegrasEmpr();
    } else {
      setSelectedRegra(null);
      setRegrasList(null);
    }
  }, [showModal]);

  const handleGetRegrasEmpr = () => {
    getAllRegrasComerciais(selectedEmpresa.codEmpresa).then((res) => {
      if (res.succeeded) {
        setRegrasList((res.data ?? []).filter((regra) => regra.tipo == 'L'));
      }
    });
  };

  const handleRegraSelectionChange = (e: { value: any[] }) => {
    let newSelectedRegra = e.value;
    setSelectedRegra(newSelectedRegra);
  };

  return (
    <Dialog
      header={'Selecionar Regra'}
      visible={showModal}
      onHide={() => {
        setShowModal(false);
      }}
      style={{
        width: isWebScreen ? '35svw' : '95svw',
      }}
    >
      <S.SelectRegraFormBox>
        <S.SelectRegraFormRow>
          <InputText
            label="Buscar"
            placeholder="Buscar regra"
            width="100%"
            value={searchRegraText}
            onChange={(e) => {
              setSearchRegraText(e.toUpperCase());
            }}
          />
        </S.SelectRegraFormRow>
        <S.SelectRegraTableBox>
          <DataTable
            value={(regrasList ?? []).filter(
              (regra) =>
                regra.descricao.toUpperCase().includes(searchRegraText) ||
                regra.descricao.toUpperCase().includes(searchRegraText),
            )}
            paginator={false}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rows={100}
            rowHover
            stripedRows
            emptyMessage={'Nenhuma regra encontrada'}
            size="small"
            selection={selectedRegra}
            onSelectionChange={handleRegraSelectionChange}
            selectionMode="checkbox"
            scrollable
            scrollHeight="50svh"
          >
            <Column
              header=""
              selectionMode="single"
              align="left"
              style={{
                maxWidth: '60px',
              }}
            />
            <Column
              header="Regra"
              field="codregra"
              align="left"
              body={(regra) => {
                return `[${regra.codregra}] ${regra.nomeregra}`;
              }}
            />
            <Column header="Descrição" field="descricao" align="left" />
          </DataTable>
        </S.SelectRegraTableBox>

        <S.SelectRegraFormRow>
          <Button
            text="Confirmar seleção da regra"
            icon={<icons.Check />}
            color="green"
            width="100%"
            disabled={!selectedRegra}
            tooltip={!selectedRegra ? 'Nenhuma regra selecionada' : ''}
            onClick={() => {
              selectedRegra && handleRegra(selectedRegra.codregra);
              setShowModal(false);
            }}
          />
        </S.SelectRegraFormRow>
      </S.SelectRegraFormBox>
    </Dialog>
  );
}
