import * as S from './styles';
import { useEffect, useState } from 'react';

import { InputText as PmInputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { toast } from 'react-toastify';
import { postRelatorio } from 'client/api/relatorios';
import { useNavigate } from 'react-router-dom';
import { alterPageTitle } from 'utils/alterPageTitle';
import { trackGCatchError, trackGPageView } from 'utils/analytics';

import InputText from 'components/Inputs/InputText/text.input';
import InputSwitch from 'components/Inputs/InputSwitch/switch.input';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import { dialog } from 'reactivus';
import Dropdown from 'components/Dropdown/dropdown';

type ReportParamProps = {
  codrel: number;
  ordem: number;
  obrigatorio: string;
  nome_param: string;
  legenda: string;
  componente: string;
  valor_padrao: string;
  itens: string;
  consulta: string;
  busca: string;
};

type ReportProps = {
  codrel: number;
  descricao: string;
  script: string;
  layout: string;
  layout_p: string;
  ativo: string;
  tipo: string;
  exibir_fv: string;
  parametros: ReportParamProps[];
};

export default function Relatorios7000New() {
  try {
    const navigate = useNavigate();

    useEffect(() => {
      trackGPageView('/relatorios/novo');
      alterPageTitle('Novo Relatório');
    }, []);

    const reportTypes = [
      {
        name: 'Relatório',
        code: 'R',
      },
      {
        name: 'Ação',
        code: 'A',
      },
    ];

    const [reportData, setReportData] = useState<ReportProps>({
      codrel: 0,
      descricao: '',
      script: '',
      layout: '',
      layout_p: '',
      ativo: 'S',
      exibir_fv: 'S',
      tipo: 'R',
      parametros: [],
    });

    const handleScriptStringAdjust = (script: string): string => {
      return script
        .replaceAll('\n', ' ')
        .replaceAll('(', '( ')
        .replaceAll(')', ' )')
        .replaceAll(',', ' , ')
        .replaceAll('=', ' = ')
        .replaceAll('\n', ' ');
    };

    const handleScriptParams = (script: string) => {
      const params = handleScriptStringAdjust(script)
        .split(' ')
        ?.filter((param: string) => param.slice(0, 1) == ':');
      const paramsList = [];
      for (let i = 0; i < params.length; i++) {
        if (!paramsList.some((param) => param.nome_param === params[i].replace(':', '')))
          paramsList.push({
            codrel: 0,
            ordem: i + 1,
            obrigatorio: 'S',
            nome_param: params[i].replace(':', ''),
            legenda: '',
            componente: '',
            valor_padrao: '',
            itens: '',
            consulta: '',
            busca: '',
          });
      }
      setReportData((prev: ReportProps) => {
        for (let i = 0; i < paramsList.length; i++) {
          paramsList[i].ordem = i + 1;
          for (let j = 0; j < prev.parametros.length; j++) {
            if (prev.parametros[j].nome_param == paramsList[i].nome_param) {
              paramsList[i] = prev.parametros[j];
            }
          }
        }
        return {
          ...prev,
          parametros: paramsList ?? [],
        };
      });
    };

    const textEditor = (options: any) => {
      return (
        <S.NewReportParamsInputBox>
          <PmInputText
            type="text"
            value={options.value}
            onChange={(e) => options.editorCallback(e.target.value)}
            style={{
              width: 'clamp(100px, 150px, 200px)',
            }}
          />
        </S.NewReportParamsInputBox>
      );
    };

    const activeEditor = (options: any) => {
      return (
        <S.NewReportParamsInputBox>
          <InputSwitch
            checked={options.value == 'S' || options.obrigatorio == 'S'}
            onChange={(e) => options.editorCallback && options.editorCallback(e ? 'S' : 'N')}
          />
        </S.NewReportParamsInputBox>
      );
    };

    const onCellEditComplete = (e: any) => {
      let { rowData, newValue, field, originalEvent: event } = e;
      rowData[field] = newValue;
      setReportData((prev: ReportProps) => {
        const newParams = prev.parametros;
        for (let i = 0; i < newParams.length; i++) {
          newParams[i].ordem = i + 1;
          if (newParams[i].nome_param == field) {
            newParams[i] = rowData;
          }
        }
        return {
          ...prev,
          parametros: newParams,
        };
      });
    };

    const handleSaveNewReport = () => {
      if (reportData.descricao == '') {
        toast.error('Descrição não informada');
        return;
      } else if (reportData.script == '') {
        dialog
          .show({
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonText: 'Não, voltar',
            showConfirmButton: true,
            confirmButtonText: 'Sim, salvar',
            icon: 'question',
            title: 'Nenhum script?',
            text: 'Nenhum script foi informado, deseja prosseguir?',
          })
          .then((res) => {
            if (res.isConfirmed) {
              postSaveNewReport();
            } else {
              return;
            }
          });
      } else {
        postSaveNewReport();
      }
    };

    async function postSaveNewReport() {
      const reportDataToInsert = { ...reportData };
      if (!reportDataToInsert.script.toUpperCase().includes('--{CODEMPRESA}')) {
        reportDataToInsert.script = reportDataToInsert.script + ' --{codempresa}';
      }
      const reportInserted = await postRelatorio(reportDataToInsert);
      if (reportInserted.succeeded) {
        toast.success('Sucesso ao editar relatório');
        navigate(`/relatorios`);
      }
    }

    return (
      <S.NewReportMainBox>
        <S.NewReportContentBox>
          <S.NewReportRowBox1>
            <InputText
              label="Descrição"
              id="report"
              placeholder="Descrição do relatório"
              value={reportData.descricao}
              onChange={(e) => {
                setReportData((prev: ReportProps) => {
                  return {
                    ...prev,
                    descricao: e.toUpperCase() ?? '',
                  };
                });
              }}
              width="clamp(200px, 250px, 300px)"
            />

            <Dropdown
              label="Tipo"
              value={reportData.tipo}
              onChange={(e) => {
                setReportData((prev: ReportProps) => {
                  return {
                    ...prev,
                    tipo: e.value ?? 'R',
                  };
                });
              }}
              options={reportTypes}
              optionLabel="name"
              optionValue="code"
              className="w-full md:w-14rem"
              style={{ marginRight: '10px' }}
              itemTemplate={(item) => {
                return '[' + item.code + '] - ' + item.name;
              }}
            />

            <S.NewReportInputBox>
              <label htmlFor="report">Ativo</label>
              <InputSwitch
                checked={reportData.ativo == 'S'}
                onChange={(e) => {
                  setReportData((prev: ReportProps) => {
                    return {
                      ...prev,
                      ativo: e ? 'S' : 'N',
                    };
                  });
                }}
              />
            </S.NewReportInputBox>

            <S.NewReportInputBox>
              <label htmlFor="report">Exibir FV</label>
              <InputSwitch
                checked={reportData.exibir_fv == 'S'}
                onChange={(e) => {
                  setReportData((prev: ReportProps) => {
                    return {
                      ...prev,
                      exibir_fv: e ? 'S' : 'N',
                    };
                  });
                }}
              />
            </S.NewReportInputBox>

            <Button
              text="Salvar"
              icon={<icons.Check />}
              color="green"
              onClick={() => handleSaveNewReport()}
              tooltip="Salvar novo relatório"
            />
          </S.NewReportRowBox1>

          <S.NewReportRowBox2>
            <S.NewReportScriptInputBox>
              <label htmlFor="report">Script</label>
              <InputTextarea
                defaultValue={reportData.script}
                onChange={(e) => {
                  handleScriptParams(e.target.value.toUpperCase() ?? '');
                  setReportData((prev: ReportProps) => {
                    return {
                      ...prev,
                      script: e.target.value.toUpperCase() ?? '',
                    };
                  });
                }}
                autoResize
                rows={24}
                cols={30}
              />
            </S.NewReportScriptInputBox>

            <S.NewReportInputBox>
              <label htmlFor="report">Parâmetros</label>
              <S.NewReportParamsBox>
                <DataTable
                  value={reportData.parametros}
                  stripedRows
                  reorderableRows
                  rowHover
                  responsiveLayout="scroll"
                  onRowReorder={(e) => {
                    for (let i = 0; i < e.value.length; i++) {
                      e.value[i].ordem = i + 1;
                    }
                    setReportData((prev: ReportProps) => {
                      return {
                        ...prev,
                        parametros: e.value,
                      };
                    });
                  }}
                  size="small"
                >
                  <Column
                    field="reorder"
                    header=""
                    align="left"
                    rowReorder
                    style={{
                      maxWidth: '2rem',
                    }}
                  />
                  <Column
                    field="ordem"
                    header=""
                    align="center"
                    style={{
                      maxWidth: '2rem',
                    }}
                  />
                  <Column field="nome_param" header="Nome" align="left" />
                  <Column
                    field="legenda"
                    header="Legenda"
                    align="left"
                    editor={(options) => textEditor(options)}
                    onCellEditComplete={onCellEditComplete}
                  />
                  <Column
                    field="obrigatorio"
                    header="Obrigatório"
                    align="center"
                    editor={(options) => activeEditor(options)}
                    onCellEditComplete={onCellEditComplete}
                    body={(options) => activeEditor(options)}
                  />
                  <Column
                    field="valor_padrao"
                    header="Vl. Padrão"
                    align="left"
                    editor={(options) => textEditor(options)}
                    onCellEditComplete={onCellEditComplete}
                  />
                </DataTable>
              </S.NewReportParamsBox>
            </S.NewReportInputBox>
          </S.NewReportRowBox2>
        </S.NewReportContentBox>
      </S.NewReportMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'relatorios/relatorioNew/index.tsx');
  }
}
