import { trackGCatchError } from 'utils/analytics';

const visitas = (listData: Array<any>) => {
  try {
    if (!listData || listData.length == 0) {
      return [];
    }
    const tableData = listData;

    // TRATA ARRAY DE VENDEDORES
    let vendedores = [];
    let vendedor = -1;
    for (let i = 0; i < tableData.length; i++) {
      if (vendedor != tableData[i].cod_vendedor) {
        vendedor = tableData[i].cod_vendedor;

        let newVendedor = {
          dtRota: tableData[i].dt_rota,
          codVendedor: tableData[i].cod_vendedor,
          vendedor: tableData[i].vendedor,
          cod_empresa: tableData[i].cod_empresa,
          codSupervisor: tableData[i].cod_supervisor,
          supervisor: tableData[i].nomesup,
          codGerente: tableData[i].cod_gerente,
          gerente: tableData[i].nomegerente,
          qde_cli: tableData[i].qde_cli,
          qde_visitado: tableData[i].qde_visitado,
          perc_visitado: tableData[i].rota_perc_exec,
          km_exec: tableData[i].km_exec,
          qt_positivado: tableData[i].qt_positivado,
          perc_positivado: tableData[i].perc_positivado,
          pendencias: tableData[i].rota_nao_exec,
          dias: [],
          prev_visita_total: 0,
          real_visita_total: 0,
          pendencia_visita_total: 0,
          perc_visita_total: 0,
          positivacao_total: 0,
          perc_positivacao_total: 0,
          km_total: 0,
        };
        vendedores.push(newVendedor);
      }
    }

    // TRATA ARRAY DE DIAS
    for (let j = 0; j < vendedores.length; j++) {
      let currentVendedor = vendedores[j];
      let dias = [];
      for (let k = 0; k < tableData.length; k++) {
        if (currentVendedor.codVendedor == tableData[k].cod_vendedor) {
          let newDia = {
            dia: tableData[k].dia,
            semana: tableData[k].semana,
            status: tableData[k].rota_completa,
            qde_cli_dia: tableData[k].qde_cli,
            qde_visitado_dia: tableData[k].qde_visitado,
            qde_nao_visitado: +tableData[k].qde_cli - +tableData[k].qde_visitado,
            perc_visitado: tableData[k].rota_perc_exec ?? 0,
            positivacoes: tableData[k].qt_positivado ?? 0,
            perc_positivado: tableData[k].perc_positivado ?? 0,
            km_exec: tableData[k].km_exec ?? 0,
            dtRota: tableData[k].dt_rota,
            codVendedor: tableData[k].cod_vendedor,
            vendedor: tableData[k].vendedor,
            codEmpresa: tableData[k].cod_empresa,
          };
          dias.push(newDia);
          // TOTALIZADORES
          vendedores[j].prev_visita_total = vendedores[j].prev_visita_total + +tableData[k].qde_cli;
          vendedores[j].real_visita_total = vendedores[j].real_visita_total + +tableData[k].qde_visitado;
          vendedores[j].pendencia_visita_total =
            vendedores[j].pendencia_visita_total + +(+tableData[k].qde_cli - +tableData[k].qde_visitado);
          vendedores[j].positivacao_total = vendedores[j].positivacao_total + +tableData[k].qt_positivado;
          vendedores[j].km_total = +vendedores[j].km_total + +tableData[k].km_exec;
        }
      }
      vendedores[j].pendencias = dias.filter((day: any) => +day.qde_visitado_dia < +day.qde_cli_dia).length;
      vendedores[j].dias = dias;

      vendedores[j].perc_visita_total = +(
        (vendedores[j].real_visita_total * 100) /
        vendedores[j].prev_visita_total
      ).toFixed(2);
      vendedores[j].perc_positivacao_total = +(
        (vendedores[j].positivacao_total * 100) /
        vendedores[j].prev_visita_total
      ).toFixed(2);
    }

    const isDayInDaysArray = (array: any[], day: number) => {
      const dayInArray = array?.filter((d) => +d.dia == day);
      return dayInArray && dayInArray.length > 0 ? true : false;
    };

    let indexWithHigherNumberOfDays = 0;
    let higherLength = 0;
    for (let i = 0; i < vendedores.length; i++) {
      if (vendedores[i] && vendedores[i].dias.length > higherLength) {
        higherLength = vendedores[i].dias.length;
        indexWithHigherNumberOfDays = i;
      }
    }

    const newVendedores = [...vendedores];

    try {
      let lastDate = +(newVendedores[indexWithHigherNumberOfDays]?.dias?.length ?? 0);

      for (let i = 0; i < newVendedores.length; i++) {
        for (let j = 0; j < lastDate; j++) {
          if (!isDayInDaysArray(newVendedores[i]?.dias, j + 1) && j > lastDate) {
            const splittedData = newVendedores[i]?.dias[0]?.dtRota.split('/');
            newVendedores[i]?.dias?.push({
              dia: j + 1,
              semana: newVendedores[i]?.dias[j]?.semana,
              status: newVendedores[i]?.dias[j]?.status,
              vendedor: newVendedores[i]?.dias[j]?.vendedor,
              codEmpresa: newVendedores[i]?.dias[j]?.codEmpresa,
              codVendedor: newVendedores[i]?.dias[j]?.codVendedor,
              dtRota: `${j + 1}/${splittedData[1]}/${splittedData[2]}`,
              km_exec: 0,
              perc_positivado: 0,
              perc_visitado: 0,
              positivacoes: 0,
              qde_cli_dia: 0,
              qde_nao_visitado: 0,
              qde_visitado_dia: 0,
            });
          }
        }
        newVendedores[i].dias = newVendedores[i].dias.sort((a, b) => a.dia - b.dia);
      }
    } catch (err) {
      console.log('Falha ao atualizar dias dos vendedores localmente');
    }
    return newVendedores;
  } catch (err) {
    trackGCatchError(err, 'AcompanhamentoDiario/utils/dataHandler.ts');
  }
};

export { visitas };
