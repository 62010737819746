import { useVerbas } from 'modules/verbas/verbas.context';
import * as S from './verbasHistorico.styes';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getVerbasExtrato } from 'client/api/verbas';
import { toast } from 'react-toastify';
import { useLoaderEffect } from 'providers/loaderEffect';
import InputText from 'components/Inputs/InputText/text.input';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import { usePageTitle } from 'providers/pageTitle';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { IVerbaExtrato } from 'modules/verbas/verbas.types';
import formatCurrency from 'utils/formatCurrency';

export default function VerbasHistorico() {
  const { verbas } = useVerbas();

  const { setLoaderHeader } = useLoaderEffect();

  const { setPageTitle } = usePageTitle();

  const [extData, setExtData] = useState<IVerbaExtrato[]>([]);

  const [extSearchText, setExtSearchText] = useState('');

  const location = useLocation();
  const extParams = location.state as { numVerba: number; codEmpresa: number; codSupervisor: number; codRca: number };

  useEffect(() => {
    handleGetExtData();
  }, []);

  const handleGetExtData = async () => {
    if (extParams) {
      setPageTitle((prev) => ({
        text: `Extrato da verba: [${extParams.numVerba}] ${getVerbaNameByNumVerba()}`,
        customObject: prev.customObject,
        tree: prev.tree,
      }));
      setLoaderHeader(true);
      console.log('extParams.codSupervisor ?? -1 :', extParams.codSupervisor ?? -1);
      console.log('extParams.codRca ?? - :', extParams.codRca ?? -1);
      getVerbasExtrato(extParams.codEmpresa, extParams.numVerba, extParams.codSupervisor ?? -1, extParams.codRca ?? -1)
        .then((res) => {
          if (res.succeeded) {
            setExtData(res.data ?? []);
          }
        })
        .catch((err) => {
          console.log('Err :', err);
          toast.error('Falha inesperada ao buscar extrato da verba');
        })
        .finally(() => {
          setLoaderHeader(false);
        });
    }
  };

  const getVerbaNameByNumVerba = () => {
    const filteredVerba = verbas.unfiltered?.filter((verba) => verba.NUMVERBA == extParams.numVerba);
    if (filteredVerba) {
      return filteredVerba[0].DESCRICAO;
    }
    return '';
  };

  const handleExportDataToExcel = () => {
    const fileName: string = `extrato-verba`;

    const dataToExport = (extData ?? [])?.filter((verba) =>
      Object.values(verba).some((value) => String(value).toLowerCase().includes(extSearchText.toLowerCase())),
    );

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Extrato');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, fileName);
  };

  const dateTemplate = (verbaExt: IVerbaExtrato) => {
    const formattedDate = new Date(verbaExt['DTINCLUSAO'])
      .toLocaleString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      })
      .replace(',', '');

    return formattedDate;
  };

  const fixedNumber = (number: number | string) => {
    return `${formatCurrency(+number.toString().replace(',', '.'))}`;
  };

  const formattedNumberTemplate = (verbaExt: IVerbaExtrato, field: string) => {
    return <p>{fixedNumber(verbaExt[field] ?? 0)}</p>;
  };

  return (
    <S.VerbasHistoricoMainBox>
      <S.VerbasHistoricoHeaderBox>
        <InputText
          label="Buscar"
          placeholder="Buscar no grid"
          value={extSearchText}
          onChange={(e) => {
            setExtSearchText(e.toUpperCase());
          }}
        />
        <Button
          color="green"
          icon={<icons.Refresh />}
          tooltip="Atualizar dados do grid"
          onClick={() => {
            handleGetExtData();
          }}
        />
        <Button
          color="green"
          icon={<icons.Excel />}
          text="Exportar"
          tooltip={extData.length == 0 ? 'Nenhum dado para exportar' : 'Exportar dados em planilha'}
          disabled={extData.length == 0}
          onClick={() => {
            extData && handleExportDataToExcel();
          }}
        />
      </S.VerbasHistoricoHeaderBox>

      <S.VerbasHistoricoTableBox>
        <DataTable
          value={(extData ?? [])?.filter((verba) =>
            Object.values(verba).some((value) => String(value).toLowerCase().includes(extSearchText.toLowerCase())),
          )}
          paginator={false}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rows={100}
          rowHover
          stripedRows
          emptyMessage={'Nenhum extrato encontrado'}
          size="small"
        >
          <Column field="NUMVERBA" header="NUMVERBA" align="left" sortable />
          <Column field="NUMPEDRCA" header="NUMPEDRCA" align="left" sortable />
          <Column field="CODRCA" header="CODRCA" align="left" sortable />
          <Column field="NOME_RCA" header="NOME_RCA" align="left" sortable />
          <Column field="CODCLI" header="CODCLI" align="left" sortable />
          <Column field="CLIENTE" header="CLIENTE" align="left" sortable />
          <Column field="COD_SUPERVISOR" header="COD_SUPERVISOR" align="left" sortable />
          <Column field="PERCDESC" header="PERCDESC" align="left" sortable />
          <Column
            field="VLDESC"
            header="VLDESC"
            align="right"
            sortable
            body={(verbaRca) => {
              return formattedNumberTemplate(verbaRca, 'VLDESC');
            }}
          />
          <Column
            field="ACUMULADO"
            header="ACUMULADO"
            align="right"
            sortable
            body={(verbaRca) => {
              return formattedNumberTemplate(verbaRca, 'ACUMULADO');
            }}
          />
          <Column field="DTINCLUSAO" header="DTINCLUSAO" align="left" body={dateTemplate} sortable />
          <Column field="CODFUNC_LANC" header="CODFUNC_LANC" align="left" sortable />
        </DataTable>
      </S.VerbasHistoricoTableBox>
    </S.VerbasHistoricoMainBox>
  );
}
