import {
  IVerbaExtrato,
  IVerbaGaParams,
  IVerbaRcaParams,
  IVerbasCab,
  IVerbasGa,
  IVerbasRca,
} from 'modules/verbas/verbas.types';
import api from 'services/api';

const _controller = 'Verba';

export const getVerbasCab = async (
  codEmpresa: number,
  codFilial: number,
  status: string,
): Promise<{ data: IVerbasCab[]; succeeded: boolean }> => {
  const { data } = await api.get(
    `${_controller}/GetVerba?CODEMPRESA=${codEmpresa}&CODFILIAL=${codFilial}&STATUS=${status}`,
  );
  return data;
};

export const getVerbasGa = async (
  codEmpresa: number,
  numVerba: number,
): Promise<{ data: IVerbasGa[]; succeeded: boolean }> => {
  const { data } = await api.get(`${_controller}/GetVerbaSupervisor?CODEMPRESA=${codEmpresa}&NumVerba=${numVerba}`);
  return data;
};

export const getVerbasRca = async (
  codEmpresa: number,
  numVerba: number,
  codSupervisor: number,
): Promise<{ data: IVerbasRca[]; succeeded: boolean }> => {
  const { data } = await api.get(
    `${_controller}/GetVerbaVendedor?CODEMPRESA=${codEmpresa}&NumVerba=${numVerba}&CodSupervisor=${codSupervisor}`,
  );
  return data;
};

export const getVerbasExtrato = async (
  codEmpresa: number,
  numVerba: number,
  codSupervisor: number,
  codRca: number,
): Promise<{ data: IVerbaExtrato[]; succeeded: boolean }> => {
  const { data } = await api.get(
    `${_controller}/GetVerbaExtrato?CODEMPRESA=${codEmpresa}&NumVerba=${numVerba}&CodSupervisor=${codSupervisor}&CodRca=${codRca}`,
  );
  return data;
};

export const postVerbaSaldo = async (
  codOper: string,
  numVerba: number,
  vlCredito: number,
  descHistorico: string,
  codFuncLanc: number,
  codEmpresa: number
): Promise<{ data: string; succeeded: boolean; message: string }> => {
  const { data } = await api.post(
    `${_controller}/PostSaldoVerba?RGOPERACAO=${codOper}&V_NUMVERBA=${numVerba}&V_VLCREDITO=${vlCredito}&V_HISTORICO=${descHistorico}&V_CODFUNC_LANC=${codFuncLanc}&COD_EMPRESA=${codEmpresa}`,
  );
  return data;
};

export const postVinculoVerbaSupervisor = async (
  vinculos: IVerbaGaParams[],
): Promise<{ data: boolean; succeeded: boolean }> => {
  const { data } = await api.post(`${_controller}/PostVerbaSupervisor`, vinculos);
  return data;
};

export const postVinculoVerbaVendedor = async (
  vinculos: IVerbaRcaParams[],
): Promise<{ data: boolean; succeeded: boolean }> => {
  const { data } = await api.post(`${_controller}/PostVerbaRca`, vinculos);
  return data;
};

export const putVerbaCab = async (verba: any): Promise<{ data: boolean; succeeded: boolean }> => {
  const { data } = await api.put(`${_controller}/Verba`, verba);
  return data;
};

export const postVerbaCab = async (verba: any): Promise<{ data: boolean; succeeded: boolean }> => {
  const { data } = await api.post(`${_controller}/Verba`, verba);
  return data;
};
