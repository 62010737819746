import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRespostas } from 'client/hooks';
import { RespostaCab } from 'client/interfaces';
import { filterByGlobalFilter } from 'components/datatable';
import * as S from './styles';
import { useAppSelector } from 'store';
import { userSelector } from 'modules/auth/reducer/auth';
import { useForm } from 'react-hook-form';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import UseSelectUsuario from 'hooks/UseSelectUsuario/selectUsuario';
import UseSelectFilial from 'hooks/UseSelectFilial/selectFilial';
import InputText from 'components/Inputs/InputText/text.input';
import InputDate from 'components/Inputs/InputDate/date.input';
import { formatDate } from 'utils/formatDate';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
import { alterPageTitle } from 'utils/alterPageTitle';

export default function RepostaFormularios() {
  try {
    useEffect(() => {
      trackGPageView('/respostas');
      alterPageTitle('Respostas Formulários');
    }, []);
    const { SelectEmpresa, selectedEmpresa } = UseSelectEmpresa();
    const { SelectUsuario, selectedUsuario } = UseSelectUsuario(selectedEmpresa.codEmpresa);
    const { SelectFilial, selectedFilial } = UseSelectFilial(selectedEmpresa.codEmpresa, selectedUsuario.codUsuario);
    const user = useAppSelector(userSelector);

    const [tableData, setTableData] = useState([]);

    const endDate = new Date();
    const startDate = new Date();
    startDate.setMonth(endDate.getMonth() - 1);

    const { watch, setValue } = useForm({
      defaultValues: {
        codCompany: user?.codEmpresa,
        codUserByCompany: user?.codUsuario,
        codFilial: '',
        endDate: new Date(),
        startDate: startDate,
        filterGlobal: '',
      },
    });

    const values = watch();

    const navigate = useNavigate();

    const {
      data: respostas,
      isLoading,
      isFetching,
      refetch,
    } = useRespostas(values.startDate, values.endDate, selectedEmpresa.codEmpresa);

    const viewresposta = (rowData: RespostaCab) => {
      navigate(`itens/${rowData.nroResposta}`);
    };

    useEffect(() => {
      const filterByFilial = selectedFilial
        ? respostas?.filter((formulario) => {
            return selectedFilial && selectedFilial.codFilial == +formulario.codfilial;
          })
        : [];

      let filteredData = filterByFilial ?? [];
      filteredData = filterByGlobalFilter(
        filteredData.map((p, index) => ({ ...p, id: index })) ?? [],
        values.filterGlobal,
      );

      setTableData(filteredData);
    }, [respostas, values.filterGlobal, selectedFilial]);

    useEffect(() => {
      refetch();
    }, [selectedEmpresa, selectedUsuario, values.startDate, values.endDate]);

    return (
      <S.FormRespMainBox>
        <S.FormRespHeaderBox>
          <SelectEmpresa
            label="Empresa *"
            onChange={(emp) => {
              setValue('codCompany', emp.codEmpresa);
            }}
          />
          <SelectUsuario
            label="Usuário *"
            onChange={(usr) => {
              setValue('codUserByCompany', usr.codUsuario);
            }}
          />
          <SelectFilial
            label="Filial *"
            onChange={(fil) => {
              setValue('codFilial', fil.codFilial.toString());
            }}
          />

          <InputDate
            label="Dt. Ini *"
            placeholder="dd/mm/yyyy"
            value={values.startDate}
            onChange={(e) => {
              setValue('startDate', e);
            }}
            width="90px"
          />

          <InputDate
            label="Dt. Fim *"
            placeholder="dd/mm/yyyy"
            value={values.endDate}
            onChange={(e) => {
              setValue('endDate', e);
            }}
            width="clamp(90px, 110p, 120px)"
          />

          <InputText
            label="Buscar"
            value={values.filterGlobal}
            onChange={(e) => {
              setValue('filterGlobal', e);
            }}
            placeholder="Buscar"
          />
        </S.FormRespHeaderBox>

        <S.FormRespTableBox>
          <DataTable
            value={tableData}
            dataKey="nroResposta"
            paginator
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[10, 25, 50, 100, 200, 300, 500]}
            rows={50}
            emptyMessage="Nenhum dado encontrado"
            currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} registros"
            size="small"
          >
            <Column header="Formulário" field="form" />
            <Column header="Cliente" field="codcli" />
            <Column header="Filial" field="codfilial" />
            <Column header="CodSupervisor" field="codrca" />
            <Column
              header="Vendedor"
              field="codcli"
              body={(rowData: RespostaCab) =>
                `${rowData.vendedor ?? ''}  ${rowData.codrca ? ` [${rowData.codrca}]` : ''}`
              }
            />
            <Column
              header="Data Resposta"
              field="dtresposta"
              body={(rowData: RespostaCab) => formatDate(new Date(rowData.dtresposta), 'BR')}
            />
            <Column
              body={(rowData: RespostaCab) => (
                <Button
                  icon={<icons.Eye />}
                  color="blue"
                  rounded
                  bgOnlyOnHover
                  onClick={() => viewresposta(rowData)}
                  tooltip="Visualizar"
                  textMode
                />
              )}
            />
          </DataTable>
        </S.FormRespTableBox>
      </S.FormRespMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'formulario/pages/RespostaFormularios/FormularioRespostaTable.tsx');
  }
}
