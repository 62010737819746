import styled, { keyframes } from 'styled-components';

const displayAnim = keyframes`
  0% {
    opacity: 0;
    scale: 0.85;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
`;

export const EmptyMainBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  overflow: hidden;
  animation: ${displayAnim} 0.5s ease forwards;
`;

export const EmptyTableMainBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

export const EmptyImage = styled.img`
  max-width: 50%;
  max-height: 50%;
`;

export const EmptyText = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  /* font-weight: 600; */
  /* color: #000; */
`;
