import { useEffect } from 'react';
import ClientAccordeon from './components/clientAccordeon/clientAccordeon';
import PedList from './components/pedList/pedList.index';
import * as S from './styles';
import { alterPageTitle } from 'utils/alterPageTitle';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
import InputText from 'components/Inputs/InputText/text.input';
import InputDate from 'components/Inputs/InputDate/date.input';
import icons from 'components/Icons/icons.index';
import { usePedidos } from 'modules/pedido/pedidos.context';
import DebitSalesCharts from './components/debitSalesCharts/debitSalesCharts';
import { LoaderEffectHeader } from 'components/LoaderEffect';
import Button from 'components/Button/button.index';
import exportSheetModel from 'utils/exportSheetModel';
import { formatDate } from 'utils/formatDate';

export default function Pedido() {
  try {
    useEffect(() => {
      trackGPageView('/pedido');
      alterPageTitle('Pedidos');
    }, []);

    const {
      SelectEmpresa,
      searchParams,
      setSearchParams,
      handleGetPedidos,
      cliente,
      handleResetData,
      pedidos,
      selectedEmpresa,
    } = usePedidos();

    const handleTableExport = () => {
      const fileName: string = `PEDIDOS_${selectedEmpresa.codEmpresa}
      ${formatDate(searchParams.dtIni, 'BR').replaceAll('/', '-')}
      a
      ${formatDate(searchParams.dtFim, 'BR').replaceAll('/', '-')}
      ${searchParams.client == '' || +searchParams.client == -1 ? 'TODOS_CLIENTES' : `CLIENTE-${searchParams.client}`}`;
      let data: any = pedidos?.filtered?.map((ped) => {
        return {
          POSICAO: ped.POSICAO,
          'PED.FV': ped.NUMPEDRCA,
          'PED.ERP': ped.NUMPED,
          CODCLI: ped.CODCLI,
          FILIAL: ped.CODFILIAL,
          RCA: ped.CODUSUR,
          DATA: ped.DATA,
          EMITENTE: ped.EMITENTE,
          VALOR: ped.VLTOTAL,
          PRAZO: ped.PRAZO,
          CODCOB: ped.CODCOB,
          OBSERVAÇÃO: ped.OBSERVACAO,
        };
      });
      exportSheetModel(fileName, data);
    };

    return (
      <S.PedidoMainBox>
        <S.PedidoHeaderMainBox>
          <SelectEmpresa label="Empresa *" />
          <InputText
            label="Cliente"
            width="150px"
            placeholder="Código do cliente"
            value={searchParams?.client ?? ''}
            onChange={(e) => {
              cliente && handleResetData();
              setSearchParams((prev) => ({
                ...prev,
                client: e,
              }));
            }}
            onKeyDown={(e: any) => {
              if (e.key == 'Enter') {
                handleGetPedidos(+e.target.value);
              }
            }}
            number
            icon={<icons.Search />}
            iconPosition="right"
            iconAction={() => {
              handleGetPedidos();
            }}
          />

          <InputDate
            label="Dt. Ini"
            value={searchParams.dtIni}
            onChange={(e) => {
              setSearchParams((prev) => ({
                ...prev,
                dtIni: e,
              }));
            }}
          />
          <InputDate
            label="Dt. Fim"
            value={searchParams.dtFim}
            onChange={(e) => {
              setSearchParams((prev) => ({
                ...prev,
                dtFim: e,
              }));
            }}
          />

          <InputText
            label="Buscar"
            width="150px"
            placeholder="Pesquisar no grid..."
            onChange={(e) => {
              setSearchParams((prev) => ({
                ...prev,
                text: e,
              }));
            }}
          />

          {pedidos.filtered.length > 0 && (
            <Button
              text="Exportar"
              color="green"
              icon={<icons.Excel />}
              onClick={() => {
                handleTableExport();
              }}
            />
          )}
        </S.PedidoHeaderMainBox>

        <DebitSalesCharts />

        {cliente && <ClientAccordeon />}

        <PedList />

        <S.LoaderWrapper>
          <LoaderEffectHeader />
        </S.LoaderWrapper>
      </S.PedidoMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'pedido/pages/pedido/index.tsx');
  }
}
