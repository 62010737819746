import { Column } from 'primereact/column';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { deleteCabecalho, updateForm } from 'client/api';
import { FormularioCab } from 'client/interfaces';
import { formatDateTime } from 'utils/datetime';
import FormularioItemTable from './FormularioItemTable';
import FormularioRestricoesTable from './FormularioRestricoesTable';
import NewFormDialog from './NewFormDialog';
import { RowItens } from './cadastroFormularios.styles';
import * as S from './cadastroFormularios.styles';
import { useQueryClient } from '@tanstack/react-query';
import { useCabecalhos } from 'client/hooks';
import { Image } from 'primereact/image';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import UseSelectUsuario from 'hooks/UseSelectUsuario/selectUsuario';
import UseSelectFilial from 'hooks/UseSelectFilial/selectFilial';
import UseSelectStatus from 'hooks/UseSelectStatus/selectStatus';
import InputSwitch from 'components/Inputs/InputSwitch/switch.input';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
import { alterPageTitle } from 'utils/alterPageTitle';

const CadastroFormularios = () => {
  const queryClient = useQueryClient();
  try {
    useEffect(() => {
      trackGPageView('/formulario');
      alterPageTitle('Formulários');
    }, []);

    const { SelectEmpresa, selectedEmpresa } = UseSelectEmpresa();
    const { SelectUsuario, selectedUsuario } = UseSelectUsuario(selectedEmpresa.codEmpresa);
    const { SelectFilial, selectedFiliais, setSelectedFiliais, filiais } = UseSelectFilial(
      selectedEmpresa.codEmpresa,
      selectedUsuario.codUsuario,
    );
    const { SelectStatus, selectedStatus } = UseSelectStatus();

    useEffect(() => {
      if (filiais) {
        setSelectedFiliais(filiais);
      }
    }, [filiais]);

    const { data: cabecalhos, isLoading, refetch: refetchForms } = useCabecalhos(Number(selectedEmpresa.codEmpresa));

    const [tableFormData, setTableFormData] = useState(cabecalhos ?? []);

    const [selectedItem, setSelectedItem] = useState<FormularioCab | null>(null);
    const [isVisible, setIsVisible] = useState(false);
    const [formNro, setFormNro] = useState<number | null>(null);
    const [isItemsVisible, setIsItemsVisible] = useState(false);
    const [isRestrictionsVisible, setIsRestrictionsVisible] = useState(false);

    const FormStatusTemplate = ({ form, field }: { form: FormularioCab; field: string }) => {
      const [isFormActive, setIsFormActive] = useState(form[field]);

      const handleEditMapAtivoField = (form: any, ativo: boolean) => {
        const toEditFormData = { ...form };
        toEditFormData[field] = ativo;
        toEditFormData.fotoCategoria = !toEditFormData.fotoCategoria ? '' : toEditFormData.fotoCategoria;
        try {
          updateForm(toEditFormData)
            .then(() => {
              toast.success(`Campo *${field}* ${ativo ? 'ativado' : 'desativado'}`);
              queryClient.invalidateQueries(['forms/cabecalho']);
              setIsFormActive(ativo);
            })
            .catch(() => {
              toast.error(`Falha ao ${ativo ? 'ativar' : 'desativar'} o campo *${field}*`);
              setIsFormActive(!ativo);
            });
        } catch (err) {
          toast.error(`Falha ao ${ativo ? 'ativar' : 'desativar'} o campo *${field}*`);
          setIsFormActive(ativo);
        }
      };
      return (
        <InputSwitch
          checked={isFormActive}
          onChange={(e) => {
            handleEditMapAtivoField(form, e);
          }}
        />
      );
    };

    useEffect(() => {
      const filterByFilial = [];
      if (cabecalhos) {
        const selectedFiliaisString = selectedFiliais?.map((fil) => fil.codFilial).join(',');
        for (let i = 0; i < cabecalhos.length; i++) {
          if (cabecalhos[i].filiais && selectedFiliaisString) {
            const cabFiliais = cabecalhos[i].filiais.split(',');
            for (let j = 0; j < cabFiliais.length; j++) {
              if (
                selectedFiliaisString.includes(cabFiliais[j]) &&
                !filterByFilial?.some((cab) => cab.nroform == cabecalhos[i].nroform)
              ) {
                filterByFilial.push(cabecalhos[i]);
              }
            }
          }
        }
      }

      const filterByStatus = filterByFilial?.filter((formulario) => {
        if (selectedStatus === 'T') return formulario.ativo === false || formulario.ativo === true;
        if (selectedStatus === 'S') return formulario.ativo === true;
        if (selectedStatus === 'N') return formulario.ativo === false;
        return true;
      });

      if (selectedItem) {
        const newSelectedItem = filterByStatus?.filter((item) => item.nroform == selectedItem.nroform)[0];
        newSelectedItem && setSelectedItem(newSelectedItem);
      }

      setTableFormData(filterByStatus);
    }, [cabecalhos, selectedStatus, selectedFiliais]);

    useEffect(() => {
      refetchForms();
    }, [selectedEmpresa, selectedUsuario, selectedFiliais]);

    const menu = useRef<Menu>(null);

    const onClose = () => {
      setSelectedItem(null);
      setFormNro(null);
      setIsVisible(false);
    };

    const handleEdit = (rowData: FormularioCab) => {
      setSelectedItem(rowData);
      setIsVisible(true);
    };

    const removeItem = async (data: FormularioCab) => {
      try {
        await deleteCabecalho(data.nroform);
        queryClient.invalidateQueries({
          queryKey: ['forms/cabecalho'],
        });
        toast.success('Cabeçalho excluído com sucesso!');
      } catch {
        toast.error('Erro ao excluir cabeçalho');
      }
    };

    const confirmDeleteItem = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: FormularioCab) => {
      confirmPopup({
        target: event.currentTarget,
        message: 'Tem certeza que deseja excluir este formulário? Esta ação irá excluir seus itens e restrições!',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept: async () => await removeItem(data),
      });
    };

    const handleClone = () => {
      if (selectedItem) {
        setSelectedItem({ ...selectedItem, nroform: 0 });
      }
      setIsVisible(true);
    };

    const menuItems = [
      {
        label: 'Items',
        icon: 'pi pi-bars',
        command: () => setIsItemsVisible(true),
      },
      {
        label: 'Clonar',
        icon: 'pi pi-copy',
        command: () => handleClone(),
      },
      {
        label: 'Restrições',
        icon: 'pi pi-lock',
        command: () => setIsRestrictionsVisible(true),
      },
    ];

    const onCloseItem = () => {
      setFormNro(null);
      setIsItemsVisible(false);
      setIsRestrictionsVisible(false);
    };

    const actionBodyTemplate = (rowData: FormularioCab) => {
      return (
        <RowItens>
          <Button
            icon={<icons.Edit />}
            iconPosition="left"
            color="black"
            rounded
            bgOnlyOnHover
            tooltip="Editar"
            tooltipPosition="right"
            textMode
            onClick={() => handleEdit(rowData)}
          />
          <Button
            icon={<icons.Remove />}
            iconPosition="left"
            color="red"
            rounded
            bgOnlyOnHover
            tooltip={
              rowData.temRespostas
                ? 'Não é possível excluir um fomulário que já contenha respostas. Você pode apenas desativar ou alterar o período de vigência.'
                : 'Excluir'
            }
            tooltipPosition="right"
            textMode
            onClick={(e) => !rowData.temRespostas && confirmDeleteItem(e, rowData)}
          />
          <Button
            icon={<icons.ThreeDotsVertical />}
            iconPosition="left"
            color="black"
            rounded
            bgOnlyOnHover
            tooltip={'Mais'}
            tooltipPosition="right"
            textMode
            onClick={(e) => {
              setSelectedItem(rowData);
              setFormNro(rowData.nroform);
              menu.current?.toggle(e);
            }}
          />
        </RowItens>
      );
    };

    const createNew = useCallback(() => {
      setSelectedItem(null);
      setIsVisible(true);
    }, []);

    const imageFvTemplate = (rowData: any) => {
      const imageBaseUrl = `${process.env.REACT_APP_BASE_URL}/foto_categoria_formulario/${
        !rowData || (rowData.fotoCategoria && rowData.fotoCategoria == '')
          ? 'sem-imagem.jpeg'
          : rowData.fotoCategoria ?? 'sem-imagem.jpeg'
      }`;
      return (
        <S.ItemImageTemplateBox>
          <S.FomImageTableIcon>
            <Image alt={'ImagemFV'} src={imageBaseUrl} width="250" preview />
          </S.FomImageTableIcon>
        </S.ItemImageTemplateBox>
      );
    };

    return (
      <S.FormListMainBox>
        <S.HeaderBox>
          <SelectEmpresa label="Empresa *" />
          <SelectUsuario label="Usuário *" />
          <SelectFilial label="Filiais *" multiselect />
          <SelectStatus label="Ativo/Inativo" width="120px" value={selectedStatus} />

          <Button onClick={createNew} icon={<icons.Plus />} text="Adicionar" tooltip="Novo Formulário" color="green" />
        </S.HeaderBox>

        <S.Tablebox>
          <DataTable
            value={tableFormData}
            paginator={tableFormData.length >= 10}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[10, 25, 50]}
            rows={10}
            emptyMessage="Nenhum dado encontrado"
            currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} registros"
            size="small"
            rowHover
          >
            <Column header="Imagem FV" field="fotoCategoria" body={imageFvTemplate} />

            <Column header="Nº Form" field="nroform" />
            <Column header="Descrição" field="descricao" />
            <Column
              header="Dt. Início"
              field="dtiniciovigencia"
              body={(rowData: FormularioCab) => formatDateTime('dd/MM/yyyy', new Date(rowData.dtiniciovigencia))}
            />
            <Column
              header="Dt. Fim"
              field="dtfimvigencia"
              body={(rowData: FormularioCab) => formatDateTime('dd/MM/yyyy', new Date(rowData.dtfimvigencia))}
            />
            <Column header="Cód. Filiais" field="filiais" />
            <Column
              header="Obrigatório"
              field="obrigatorio"
              body={(form) => {
                return <FormStatusTemplate form={form} field="obrigatorio" />;
              }}
              align="left"
            />
            <Column
              header="Ativo"
              field="ativo"
              headerClassName="testeActive"
              body={(form) => {
                return <FormStatusTemplate form={form} field="ativo" />;
              }}
            />
            <Column body={(rowData: FormularioCab) => actionBodyTemplate(rowData)} />
          </DataTable>
        </S.Tablebox>

        <Menu model={menuItems} popup ref={menu} />
        {isVisible && (
          <NewFormDialog
            visible={isVisible}
            setIsVisible={setIsVisible}
            form={selectedItem}
            clonedItem={formNro}
            onClose={onClose}
            codEmpresa={selectedEmpresa.codEmpresa}
            codUsuario={selectedUsuario.codUsuario}
            refetchForms={refetchForms}
          />
        )}
        {isItemsVisible && selectedItem != null && (
          <FormularioItemTable
            codCompany={selectedEmpresa.codEmpresa}
            visible={isItemsVisible}
            cab={selectedItem}
            onClose={onCloseItem}
          />
        )}
        {isRestrictionsVisible && formNro != null && (
          <FormularioRestricoesTable
            codEmpresa={String(selectedEmpresa.codEmpresa)}
            visible={isRestrictionsVisible}
            id={formNro}
            onClose={onCloseItem}
            codUsuario={selectedUsuario.codUsuario.toString()}
          />
        )}
        <ConfirmPopup />
      </S.FormListMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'formulario/CadastroFormularios/CadastroFormularios.index.tsx');
  }
};

export default CadastroFormularios;
