export const formatCellphone = (str: string) => {
  if (!str) return '';

  return str.length === 10
    ? str.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3') // Format for 10 digits
    : str.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2$3-$4'); // Format for 11 digits
};

export const revertCellphoneFormat = (formattedStr: string) => {
  return formattedStr.replace(/\D/g, '');
};
