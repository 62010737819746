import * as S from './styles';
import chartConfig from './chartConfig';
import { MdAttachMoney, MdSearch } from 'react-icons/md';
import { Chart } from 'primereact/chart';
import { usePedidos } from 'modules/pedido/pedidos.context';
import formatCurrency from 'utils/formatCurrency';
import { useEffect, useState } from 'react';

export default function DebitSalesCharts() {
  const { cliente, clientes, setSearchParams } = usePedidos();

  const [totals, setTotals] = useState({
    t1: 0,
    t2: 0,
    t3: 0,
    t4: 0,
  });

  const [isMultipleClients, setIsMultipleClients] = useState(false);

  useEffect(() => {
    if (clientes && clientes.length > 0) {
      let t1 = 0;
      let t2 = 0;
      let t3 = 0;
      let t4 = 0;

      for (let i = 0; i < clientes.length; i++) {
        const client = clientes[i];
        t1 = t1 + client.VENDA_MES_ATUAL;
        t2 = t2 + client.VENDA_MES_ANT1;
        t3 = t3 + client.VENDA_MES_ANT2;
        t4 = t4 + client.VENDA_MES_ANT3;
      }
      setIsMultipleClients(true);
      setTotals({
        t1: t1,
        t2: t2,
        t3: t3,
        t4: t4,
      });
    } else if (cliente) {
      setTotals({
        t1: cliente.VENDA_MES_ATUAL,
        t2: cliente.VENDA_MES_ANT1,
        t3: cliente.VENDA_MES_ANT2,
        t4: cliente.VENDA_MES_ANT3,
      });
      setIsMultipleClients(false);
    } else {
      setTotals({
        t1: 0,
        t2: 0,
        t3: 0,
        t4: 0,
      });
    }
  }, [clientes, cliente]);

  const monthList = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

  const handleGetMonthYearOffset = (offset: number): string => {
    const date = new Date();

    date.setMonth(date.getMonth() - offset);

    let month = date.getMonth();
    let year = date.getFullYear();

    if (month < 0) {
      month = 11;
      year -= 1;
    }

    return `${monthList[month]}/${year}`;
  };

  let perc1 = ((totals.t1 - totals.t2) / totals.t1) * 100;
  let perc2 = ((totals.t2 - totals.t3) / totals.t2) * 100;
  let perc3 = ((totals.t3 - totals.t4) / totals.t3) * 100;

  let labels = ['1ª sem', '2ª sem', '3ª sem', '4ª sem'];
  let data1 = {
    labels: labels,
    datasets: [
      {
        label: 'R$',
        borderColor: +perc3 > 0 ? '#00800070' : '#FF000070',
        backgroundColor: +perc3 > 0 ? '#00800070' : '#FF000070',
        pointBackgroundColor: +perc3 > 0 ? '#00800070' : '#FF000070',
        borderWidth: 2,
        pointStyle: false,
        data: cliente != null ? [0, 0, 0, 0] : [0, 0, 0, 0],
      },
    ],
  };
  let data2 = {
    labels: labels,
    datasets: [
      {
        label: 'R$',
        borderColor: perc2 > 0 ? '#00800070' : '#FF000070',
        backgroundColor: perc2 > 0 ? '#00800070' : '#FF000070',
        pointBackgroundColor: perc2 > 0 ? '#00800070' : '#FF000070',
        borderWidth: 2,
        pointStyle: false,
        data: cliente != null ? [0, 0, 0, 0] : [0, 0, 0, 0],
      },
    ],
  };
  let data3 = {
    labels: labels,
    datasets: [
      {
        label: 'R$',
        borderColor: perc1 > 0 ? '#00800070' : '#FF000070',
        backgroundColor: perc1 > 0 ? '#00800070' : '#FF000070',
        pointBackgroundColor: perc1 > 0 ? '#00800070' : '#FF000070',
        borderWidth: 2,
        pointStyle: false,
        data: cliente != null ? [0, 0, 0, 0] : [0, 0, 0, 0],
      },
    ],
  };

  return (
    <S.FatAndCobDataBox>
      <S.FaturamentoField>
        <S.FaturamentoFieldHeader>
          <span>
            <MdAttachMoney /> Vendas {handleGetMonthYearOffset(0)}:
          </span>
          <MdSearch
            className={'pedFatSearch'}
            onClick={(e: React.MouseEvent<Element>) => {
              let date = new Date();
              setSearchParams((prev) => ({
                ...prev,
                dtIni: new Date(date.getFullYear(), date.getMonth(), 1),
                dtFim: new Date(date.getFullYear(), date.getMonth() + 1, 0),
              }));
            }}
          />
        </S.FaturamentoFieldHeader>

        <div>
          <b>R${formatCurrency(totals.t1)}</b>
        </div>

        <div style={{ color: perc1 > 0 ? 'green' : perc1 == 0 ? 'orange' : 'red' }}>
          {isNaN(perc1) ? 0 : formatCurrency(perc1)}%
        </div>

        <S.PedFatChart>
          {cliente != null && <Chart type="line" data={data3} options={chartConfig} className={'salesChart'} />}
        </S.PedFatChart>
      </S.FaturamentoField>

      <S.FaturamentoField>
        <S.FaturamentoFieldHeader>
          <span>
            <MdAttachMoney /> Vendas {handleGetMonthYearOffset(1)}:
          </span>
          <MdSearch
            className={'pedFatSearch'}
            onClick={(e) => {
              let date = new Date();
              setSearchParams((prev) => ({
                ...prev,
                dtIni: new Date(date.getFullYear(), date.getMonth() - 1, 1),
                dtFim: new Date(date.getFullYear(), date.getMonth(), 0),
              }));
            }}
          />
        </S.FaturamentoFieldHeader>

        <div>
          <b>R${formatCurrency(totals.t2)}</b>
        </div>

        <div style={{ color: perc2 > 0 ? 'green' : perc1 == 0 ? 'orange' : 'red' }}>
          {isNaN(perc2) ? 0 : formatCurrency(perc2)}%
        </div>

        <S.PedFatChart>
          {cliente != null && <Chart type="line" data={data2} options={chartConfig} className={'salesChart'} />}
        </S.PedFatChart>
      </S.FaturamentoField>

      <S.FaturamentoField>
        <S.FaturamentoFieldHeader>
          <span>
            <MdAttachMoney /> Vendas {handleGetMonthYearOffset(2)}:
          </span>
          <MdSearch
            className={'pedFatSearch'}
            onClick={(e) => {
              let date = new Date();
              setSearchParams((prev) => ({
                ...prev,
                dtIni: new Date(date.getFullYear(), date.getMonth() - 2, 1),
                dtFim: new Date(date.getFullYear(), date.getMonth() - 1, 0),
              }));
            }}
          />
        </S.FaturamentoFieldHeader>

        <div>
          <b>R${formatCurrency(totals.t3)}</b>
        </div>

        <div style={{ color: perc3 > 0 ? 'green' : perc3 == 0 ? 'orange' : 'red' }}>
          {isNaN(perc3) ? 0 : formatCurrency(perc3)}%
        </div>

        <S.PedFatChart>
          {cliente != null && <Chart type="line" data={data1} options={chartConfig} className={'salesChart'} />}
        </S.PedFatChart>
      </S.FaturamentoField>

      {!isMultipleClients && (
        <S.CredCobField>
          <S.CredCodbValuesList>
            <span>
              <span>Lim. Crédito: </span>
              <S.CredCobValues style={{ color: cliente != null && cliente.LIMITE_CRED > 0 ? 'green' : 'red' }}>
                R${cliente != null ? formatCurrency(cliente.LIMITE_CRED) : 0}
              </S.CredCobValues>
            </span>

            {/* <span>
              <span>A Receber:</span>
              <S.CredCobValues
                style={{
                  color: cliente != null && cliente.LIMITE_CRED - cliente.LIMCRED_DISPONIVEL > 0 ? 'red' : 'green',
                }}
              >
                R${cliente != null ? formatCurrency(cliente.LIMITE_CRED - cliente.LIMCRED_DISPONIVEL) : 0}
              </S.CredCobValues>
            </span> */}

            <span>
              <span>Disponível: </span>
              <S.CredCobValues
                style={{
                  color:
                    cliente != null && cliente.LIMITE_CRED - (cliente.LIMITE_CRED - cliente.LIMCRED_DISPONIVEL) > 0
                      ? 'green'
                      : 'red',
                }}
              >
                R$
                {cliente != null
                  ? formatCurrency(cliente.LIMITE_CRED - (cliente.LIMITE_CRED - cliente.LIMCRED_DISPONIVEL))
                  : 0}
              </S.CredCobValues>
            </span>
          </S.CredCodbValuesList>

          {cliente != null && cliente.LIMITE_CRED > 0 && (
            <S.CredCobChart>
              <svg viewBox="0 0 36 36">
                <path
                  d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
                  fill="none"
                  stroke="#1dd32c"
                  strokeWidth="2"
                  strokeDasharray={
                    cliente != null
                      ? ((cliente.LIMITE_CRED - (cliente.LIMITE_CRED - cliente.LIMCRED_DISPONIVEL)) * 100) /
                          cliente.LIMITE_CRED +
                        ', 100'
                      : '0, 100'
                  }
                />
              </svg>
              <span>
                {cliente != null &&
                  (
                    ((cliente.LIMITE_CRED - (cliente.LIMITE_CRED - cliente.LIMCRED_DISPONIVEL)) * 100) /
                    cliente.LIMITE_CRED
                  ).toFixed(0) + '%'}
              </span>
            </S.CredCobChart>
          )}
        </S.CredCobField>
      )}
    </S.FatAndCobDataBox>
  );
}
