import { useUser } from '../../client/hooks/usuarios';
import { useEffect, useState } from 'react';
import { getFiliaisByUser } from 'client/api/filiais';
import { useFiliais } from 'client/hooks/filiais';
import Dropdown from 'components/Dropdown/dropdown';
import MultiSelect from 'components/Multiselect/multiselect';

type SelectedFilialProps = {
  codFilial?: number;
  nomeFilial?: string;
  codEmpresa?: number;
  label?: string;
};

const UseSelectFilial = (codEmpresa: number, codUsuario: number) => {
  // IF CODUSUARIO IS 0 THEN ALL FILIAIS WITHOUT RESTRICTIONS WILL BE USED
  const { data: user } = useUser();
  const [filiais, setFiliais] = useState<any[]>([]);

  function handleSavedFilial() {
    const fil = JSON.parse(localStorage.getItem('vmFilData') || 'null');
    return fil || null;
  }

  const [selectedFilial, setSelectedFilial] = useState<SelectedFilialProps>(handleSavedFilial);
  const [selectedFiliais, setSelectedFiliais] = useState<SelectedFilialProps[]>(null);

  const { data: allFiliais, refetch: refetchAllFiliais } = useFiliais(codEmpresa);

  useEffect(() => {
    refetchAllFiliais();
  }, [codEmpresa]);

  useEffect(() => {
    setSelectedFilial(null); // EXPERIMENTAL
    setSelectedFiliais(null); // EXPERIMENTAL
    if (user && codEmpresa && codUsuario != undefined) {
      getFiliaisByUser(codEmpresa, codUsuario).then((fils: any[]) => {
        const formattedFiliais = (codUsuario != 0 ? fils : allFiliais).map((v) => ({
          ...v,
          label: v ? `[${v.codFilial}] - ${v.nomeFilial}` : '',
        }));
        const savedFilial = JSON.parse(localStorage.getItem('vmFilData') || 'null');
        const savedFiliais = JSON.parse(localStorage.getItem('vmFilsData') || 'null');

        if (formattedFiliais.length == 1) {
          setSelectedFilial(formattedFiliais[0]);
          setSelectedFiliais(formattedFiliais ?? null);
        } else if (savedFilial && +savedFilial.codEmpresa == +codEmpresa) {
          setSelectedFilial(savedFilial);
        } else if (savedFiliais) {
          const newSavedFiliais = savedFiliais?.filter((fil) => +fil.codEmpresa == +codEmpresa);
          setSelectedFiliais(newSavedFiliais ?? null);
        }
        if (selectedFilial && +codEmpresa != +selectedFilial.codEmpresa) {
          setSelectedFiliais(null);
          setSelectedFilial(null);
          localStorage.removeItem('vmFilData');
        }
        setFiliais(formattedFiliais);
      });
    }
  }, [user, codEmpresa, codUsuario, allFiliais]);

  const handleFilialUseChange = (fil: SelectedFilialProps) => {
    setSelectedFilial(fil ?? null);
    localStorage.setItem('vmFilData', JSON.stringify(fil ?? null));
  };

  const handleFiliaisUseChange = (fil: SelectedFilialProps[]) => {
    setSelectedFiliais(fil ?? []);
    localStorage.setItem('vmFilsData', JSON.stringify(fil ?? null));
  };

  const SelectFilial = ({
    onChange,
    onChangeMulti,
    width,
    label = 'Filial',
    placeholder = 'Selecione uma Filial',
    multiselect = false,
    showClear = true,
    disabled = false,
  }: {
    onChange?: (fil: SelectedFilialProps) => void;
    onChangeMulti?: (fil: SelectedFilialProps[]) => void;
    width?: string;
    label?: string;
    placeholder?: string;
    multiselect?: boolean;
    showClear?: boolean;
    disabled?: boolean;
  }) => {
    const handleFilialChange = (fil: SelectedFilialProps) => {
      handleFilialUseChange(fil);
      if (onChange) {
        onChange(fil);
      }
    };

    const handleFiliaisChange = (fil: SelectedFilialProps[]) => {
      handleFiliaisUseChange(fil);
      if (onChangeMulti) {
        onChangeMulti(fil);
      }
    };

    if (multiselect) {
      return (
        <MultiSelect
          value={selectedFiliais ?? []}
          onChange={(e) => {
            handleFiliaisChange(e);
          }}
          options={filiais}
          optionLabel="label"
          filter
          placeholder={placeholder}
          width={width}
          label={label}
          emptyFilterMessage="Nenhum dado encontrado"
          showClear
          disabled={disabled}
        />
      );
    }
    return (
      <Dropdown
        value={selectedFilial}
        onChange={(e) => handleFilialChange(e.value)}
        options={filiais}
        optionLabel="label"
        filter
        placeholder={placeholder}
        width={width}
        label={label}
        emptyMessage="Nenhum dado encontrado"
        emptyFilterMessage="Nenhum dado encontrado"
        showClear={showClear}
        disabled={disabled}
      />
    );
  };

  const handleSelectFilialByCode = (codFilial: number) => {
    const toSelectFilial = filiais?.filter((fil) => fil.codFilial == codFilial)[0];
    setSelectedFilial(toSelectFilial);
  };

  return {
    SelectFilial,
    selectedFilial,
    setSelectedFilial,
    selectedFiliais,
    setSelectedFiliais,
    handleSelectFilialByCode,
    filiais,
  };
};

export default UseSelectFilial;
