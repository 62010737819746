export const regraTypeOptions = {
  combos: { title: 'Combos', type: 'C' },
  alerta: { title: 'Alerta', type: 'A' },
  'mix-sugerido': { title: 'Mix Sugerido', type: 'I' },
  lista: { title: 'Lista de produtos', type: 'L' },
  'bloqueio-venda': { title: 'Bloqueio Venda', type: 'B' },
  campanhas: { title: 'Campanhas', type: 'K' },
};

export const regraItemTypeDescriptions = {
  P: 'Produto',
  D: 'Departamento',
  S: 'Seção',
  F: 'Fornecedor',
};

export const regraRestricaoTypeDescriptions = {
  C: 'Cliente',
  RCA: 'Vendedor',
  GRU: 'Grupo',
  GG: 'Gerente Geral',
  GA: 'Gerente Área',
  PC: 'Praça',
};
