import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as S from './pedList.styles';
import { usePedidos } from 'modules/pedido/pedidos.context';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { ContextMenu } from 'primereact/contextmenu';
import icons from 'components/Icons/icons.index';
import { postAprovaWebPed } from 'client/api/pedidos';
import Button from 'components/Button/button.index';
import { IWebPedCab } from 'modules/pedido/pedido.types';
import NoDataFound from 'components/NoDataFound/noDataFound.index';
import formatCurrency from 'utils/formatCurrency';

export default function PedList() {
  const { pedidos, selectedEmpresa, handleGetPedidos } = usePedidos();

  const [selectedPed, setSelectedPed] = useState(null);
  const cm = useRef(null);
  const menuModel: { label: string; template: (ped: IWebPedCab | any) => JSX.Element }[] = [
    {
      label: 'Aprovar Pedido',
      template: () => (
        <S.FloatMenuItem
          onClick={() => {
            selectedPed && selectedPed.APROVADO != 'S' && handleAprovaWePed();
          }}
          active={selectedPed && selectedPed.APROVADO != 'S'}
        >
          <icons.Currency />
          Aprovar Pedido
        </S.FloatMenuItem>
      ),
    },
  ];

  const handleAprovaWePed = () => {
    if (selectedPed) {
      postAprovaWebPed(selectedEmpresa.codEmpresa, selectedPed.NUMPEDRCA, 'S')
        .then((res) => {
          if (res.succeeded) {
            toast.success('Pedido liberado');
            setSelectedPed(null);
            handleGetPedidos();
          } else {
            toast.error(`Falha inesperada ao liberar pedido ${selectedPed.NUMPEDRCA}`);
          }
        })
        .catch((err) => {
          console.log('Err :', err);
          toast.error(`Falha inesperada ao liberar pedido ${selectedPed.NUMPEDRCA}`);
        });
    }
  };

  const optionsTemplate = (ped: IWebPedCab) => {
    return (
      <Button
        icon={<icons.ThreeDotsVertical />}
        iconPosition="left"
        color="black"
        rounded
        bgOnlyOnHover
        tooltip={'Mais'}
        tooltipPosition="right"
        textMode
        onClick={(e) => {
          setSelectedPed(ped);
          cm.current.show(e.nativeEvent);
        }}
      />
    );
  };

  const posicaoRowTemplate = (rowData: any) => {
    return {
      'danger-row': rowData.POSICAO == 'C-CANCELADO',
      'waning-row': rowData.POSICAO == 'A-ANALISE',
    };
  };

  const posicaoColors = {
    CP: {
      bgColor: '#E7E8F3',
      textColor: '#373737',
    },
    MM: {
      bgColor: '#DCD6D6',
      textColor: '#892D28',
    },
    XP: {
      bgColor: '#FFF5D3',
      textColor: '#373737',
    },
    AA: {
      bgColor: '#E1EDFF',
      textColor: '#0B5DE4',
    },
    LL: {
      bgColor: '#FFDFCA',
      textColor: '#F65621',
    },
    DD: {
      bgColor: '#DDF1EA',
      textColor: '#228F6B',
    },
    RR: {
      bgColor: '#F2EBFF',
      textColor: '#7421CE',
    },
    VV: {
      bgColor: '#FCD7D4',
      textColor: '#EB0F0F',
    },
  };

  const posicaoTemplate = (ped: IWebPedCab) => {
    let actualColor = posicaoColors[ped.COR ?? 'CP'];
    if (!actualColor) {
      actualColor = posicaoColors['CP'];
    }

    return (
      <S.PedTag textColor={actualColor.textColor} bgColor={actualColor.bgColor}>
        {ped.POSICAO}
      </S.PedTag>
    );
  };

  const fixedNumber = (number: number | string) => {
    return `${formatCurrency(+number.toString().replace(',', '.'))}`;
  };

  const formattedNumberTemplate = (ped: IWebPedCab, field: string) => {
    return <p>{fixedNumber(ped[field] ?? 0)}</p>;
  };

  const columnsList = [
    <Column key={'POSICAO'} field="POSICAO" header="POSIÇÃO" align="left" sortable body={posicaoTemplate} />,
    <Column
      key={'NUMPEDRCA'}
      field="NUMPEDRCA"
      header="PED. FV"
      align="left"
      sortable
      style={{
        minWidth: '7rem',
      }}
    />,
    <Column
      key={'NUMPED'}
      field="NUMPED"
      header="PED. ERP"
      align="left"
      sortable
      style={{
        minWidth: '8rem',
      }}
    />,
    <Column key={'CODCLI'} field="CODCLI" header="CODCLI" align="left" sortable />,
    <Column key={'CODFILIAL'} field="CODFILIAL" header="FILIAL" align="left" sortable />,
    <Column key={'CODUSUR'} field="CODUSUR" header="RCA" align="left" sortable />,
    <Column key={'DATA'} field="DATA" header="DATA" sortable />,
    <Column
      key={'EMITENTE'}
      field="EMITENTE"
      header="EMITENTE"
      align="left"
      sortable
      style={{
        minWidth: '8rem',
      }}
    />,
    <Column
      key={'VLTOTAL'}
      field="VLTOTAL"
      header="VALOR"
      align="right"
      sortable
      body={(verbaRca) => {
        return formattedNumberTemplate(verbaRca, 'VLTOTAL');
      }}
    />,
    <Column key={'PRAZO'} field="PRAZO" header="PRAZO" align="right" sortable />,
    <Column key={'CODCOB'} field="CODCOB" header="CODCOB" align="right" sortable />,
    <Column
      key={'OBSERVACAO'}
      field="OBSERVACAO"
      header="OBSERVAÇÃO"
      align="left"
      sortable
      style={{
        maxWidth: '32rem',
      }}
    />,
    <Column key={'ACTIONS'} field="" header="" align="left" body={optionsTemplate} />,
  ];

  return (
    <S.PedListTableBox>
      <ContextMenu model={menuModel} ref={cm} onHide={() => setSelectedPed(null)} />
      <DataTable
        value={pedidos?.filtered}
        size={'small'}
        rowHover
        paginator={pedidos?.filtered?.length >= 10}
        rows={10}
        emptyMessage={<NoDataFound message="Nenhum pedido encontrado :/" tableMode />}
        rowClassName={posicaoRowTemplate}
        // onContextMenu={(e) => cm.current.show(e.originalEvent)}
        // contextMenuSelection={selectedPed}
        // onContextMenuSelectionChange={(e) => setSelectedPed(e.value)}
      >
        {columnsList}
      </DataTable>
    </S.PedListTableBox>
  );
}
