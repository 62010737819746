import { Dialog } from 'primereact/dialog';
import * as S from './mapNewSetor.styles';
import { SetStateAction, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { registerSetor } from 'client/api';
import { useMap } from '../../regioes.context';
import UseSelectColor from 'hooks/UseSelectColor/selectColor.index';
import InputText from 'components/Inputs/InputText/text.input';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import UseSelectUsuario from 'hooks/UseSelectUsuario/selectUsuario';
import UseSelectSupervisor from 'hooks/UseSelectSupervisor/selectSupervisor';
import UseSelectVendedor from 'hooks/UseSelectVendedor/selectVendedor';
import InputSwitch from 'components/Inputs/InputSwitch/switch.input';
import { trackGCatchError } from 'utils/analytics';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';

export default function MapNewSetor({
  showMapNewSetor,
  setShowMapNewSetor,
}: {
  showMapNewSetor: boolean;
  setShowMapNewSetor: React.Dispatch<SetStateAction<boolean>>;
}) {
  try {
    const { selectedEmpresa } = UseSelectEmpresa();
    const { selectedUsuario } = UseSelectUsuario(selectedEmpresa.codEmpresa);
    const { SelectSupervisor, selectedSupervisor } = UseSelectSupervisor(
      selectedEmpresa.codEmpresa,
      selectedUsuario.codUsuario,
    );
    const { SelectVendedor, selectedVendedor } = UseSelectVendedor(
      selectedEmpresa.codEmpresa,
      selectedUsuario.codUsuario,
      undefined,
      selectedSupervisor.codSupervisor,
    );

    const { handleGetAllData, params } = useMap();

    const { SelectColor } = UseSelectColor();

    const defaultNewSetorData = {
      codSetor: 0,
      setor: '',
      codRca: 0,
      nomeRca: '',
      ativo: true,
      codFilial: 1,
      codGerente: 0,
      nomeGerente: '',
      codMapa: 0,
      cor: '000000',
      CodEmpresa: 0,
      atualizaAutomatico: 'S',
    };

    const [newSetorData, setNewSetorData] = useState(defaultNewSetorData);

    useEffect(() => {
      setNewSetorData((prev: any) => {
        return {
          ...prev,
          codMapa: params.codMapa,
          CodEmpresa: params.codEmpresa,
        };
      });
    }, [params]);

    const DialogFooterCadastrarSetor = (
      <S.BoxButtons>
        <Button
          text="Salvar"
          icon={<icons.Check />}
          color="green"
          tooltip="Salvar Setor"
          onClick={() => handleSaveSetor()}
        />
      </S.BoxButtons>
    );

    const handleSaveSetor = () => {
      if (newSetorData.setor === '') {
        toast.error('Nome do setor não informado!');
        return;
      } else if (newSetorData.cor == '') {
        toast.error('Cor não informada!');
        return;
      }

      registerSetor(newSetorData)
        .then((res) => {
          toast.success('Setor criado com sucesso!');
          handleGetAllData();
        })
        .catch((err) => {
          toast.error('Não foi possível criar setor!');
        });
      setShowMapNewSetor(false);
      setNewSetorData(defaultNewSetorData);
    };

    return (
      <Dialog
        visible={showMapNewSetor}
        style={{ width: '32rem' }}
        breakpoints={{ '960px': '75vw', '641px': '90vw' }}
        header={'Cadastro de Setor'}
        modal
        className="p-fluid"
        onHide={() => setShowMapNewSetor(false)}
        footer={DialogFooterCadastrarSetor}
        draggable={false}
      >
        <S.NovoSetorForm>
          <InputText
            id="name"
            placeholder="Setor"
            onChange={(e) => {
              setNewSetorData((prev: any) => {
                return {
                  ...prev,
                  setor: e,
                };
              });
            }}
            value={newSetorData.setor}
            label="Nome do Setor*"
            width="100%"
          />

          <SelectSupervisor
            label="Supervisor"
            width="100%"
            onChange={(e) => {
              setNewSetorData((prev: any) => {
                return {
                  ...prev,
                  codGerente: e ? e.codSupervisor : '',
                  nomeGerente: e ? e.label : '',
                };
              });
            }}
          />

          <SelectVendedor
            label="Vendedor"
            width="100%"
            onChange={(e) => {
              setNewSetorData((prev: any) => {
                return {
                  ...prev,
                  codRca: e ? e.codVendedor : '',
                  nomeRca: e ? e.nomeVendedor : '',
                };
              });
            }}
          />

          <SelectColor
            onChange={(color) => {
              setNewSetorData((prev: any) => {
                return {
                  ...prev,
                  cor: color.COR,
                };
              });
            }}
            width={'100%'}
          />

          <S.NovoSetorFormRow>
            <InputSwitch
              label="Ativo/Inativo"
              checked={newSetorData.ativo}
              onChange={(e) => {
                setNewSetorData((prev: any) => {
                  return {
                    ...prev,
                    ativo: e,
                  };
                });
              }}
            />

            <InputSwitch
              label="Roteiriza Automático"
              checked={newSetorData.atualizaAutomatico == 'S'}
              onChange={(e) => {
                setNewSetorData((prev: any) => {
                  return {
                    ...prev,
                    atualizaAutomatico: e ? 'S' : 'N',
                  };
                });
              }}
            />
          </S.NovoSetorFormRow>
        </S.NovoSetorForm>
      </Dialog>
    );
  } catch (err) {
    trackGCatchError(err, 'mapNewSetor.index.tsx');
  }
}
