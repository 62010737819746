import { useEffect } from 'react';
import * as S from './verbasList.styles';
import { useVerbas } from 'modules/verbas/verbas.context';
import VerbasTable from 'modules/verbas/components/verbasTable/verbasTable.index';
import SupervTable from 'modules/verbas/components/supervTable/supervTable.index';
import VendedorTable from 'modules/verbas/components/vendedorTable/vendedorTable.index';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
import { alterPageTitle } from 'utils/alterPageTitle';
import InputText from 'components/Inputs/InputText/text.input';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import NovaVerbaModal from 'modules/verbas/components/novaVerbaModal/novaVerbaModal.index';
import EditaVerbaModal from 'modules/verbas/components/editaVerbaModal/editaVerbaModal.index';
import AjustaCreditoModal from 'modules/verbas/components/ajustaCreditoModal/ajustaCreditoModal.index';
import useMediaQuery from 'hooks/useMediaQuery';

export default function VerbasList() {
  try {
    const {
      searchText,
      setSearchText,
      selectedVerba,
      setShowNovaVerbaModal,
      SelectEmpresa,
      SelectUsuario,
      SelectFilial,
      SelectStatus,
      selectedStatus,
    } = useVerbas();

    useEffect(() => {
      trackGPageView('/verbas');
      alterPageTitle('Verbas');
    }, []);

    const isWebScreen = useMediaQuery('(min-width: 1060px)');

    return (
      <S.VerbasMainBox>
        <S.VerbasHeaderMainBox>
          <S.VerbasHeaderRow>
            <SelectEmpresa label="Empresa *" />
            <SelectUsuario label="Usuário *" />
            <SelectFilial label="Filial *" />
            <SelectStatus label="Status" value={selectedStatus} />

            <InputText
              label="Verba"
              placeholder="Buscar pelo código ou nome da verba"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.toUpperCase());
              }}
              width={isWebScreen ? "clamp(200px, 250px, 300px)" : '200px'}
            />

            <Button
              text="Verba"
              icon={<icons.Plus />}
              color="green"
              tooltip="Nova verba"
              onClick={() => {
                setShowNovaVerbaModal(true);
              }}
            />
          </S.VerbasHeaderRow>
        </S.VerbasHeaderMainBox>

        <VerbasTable />

        {selectedVerba && (
          <S.VerbasContentBox>
            <S.VerbasContentItem>
              <SupervTable />
            </S.VerbasContentItem>

            <S.VerbasContentItem>
              <VendedorTable />
            </S.VerbasContentItem>
          </S.VerbasContentBox>
        )}
        <NovaVerbaModal />
        <EditaVerbaModal />
        <AjustaCreditoModal />
      </S.VerbasMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'verbas/verbasList/verbasList.index.tsx');
  }
}
