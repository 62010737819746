import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export default function exportSheetModel(fileName: string, data: any[]) {
  const ws = XLSX.utils.json_to_sheet(data, { header: Object.keys(data[0]) });
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  saveAs(blob, fileName);
}
