import { Calendar, CalendarProps } from 'primereact/calendar';
import * as SI from 'styles/inputs.styles';

export default function InputDate({
  onChange,
  width,
  label = 'Data',
  placeholder = 'dd/mm/aaaa',
  description,
  ...rest
}: {
  onChange?: (data: string) => void;
  width?: string;
  label?: string;
  placeholder?: string;
  description?: string;
} & CalendarProps) {
  const widthCalculated = 100;

  const handleChange = (data: string) => {
    if (onChange) {
      onChange(data);
    }
  };

  return (
    <SI.InputMainBox width={width} widthCalculated={widthCalculated} type="date">
      <label>{label}</label>
      <Calendar
        onChange={(e) => {
          handleChange(e.value as string);
        }}
        placeholder={placeholder}
        showButtonBar
        dateFormat="dd/mm/yy"
        {...rest}
      />
      {/* <SI.InputDescriptionBox>{description}</SI.InputDescriptionBox> */}
    </SI.InputMainBox>
  );
}
