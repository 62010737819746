import { BoxTable, Container, Header } from 'components/Containers';
import styled from 'styled-components';

export const VerbasHistoricoMainBox = styled(Container)`
  justify-content: stretch;
  overflow-y: scroll;
  gap: 15px;
`;

export const VerbasHistoricoHeaderBox = styled(Header)`
  gap: 15px;
`;

export const VerbasHistoricoTitle = styled.h5``;

export const VerbasHistoricoTableBox = styled(BoxTable)`
  justify-content: stretch;
  overflow-y: scroll;
  gap: 15px;
`;
