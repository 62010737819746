import { useState } from 'react';
import exportSheetModel from 'utils/exportSheetModel';

export const useImportClients = () => {
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleDownloadSpreadsheet = () => {
    const fileName: string = `ARQUIVO_MODELO_IMPORTAR_CLIENTES_VMAIS`;
    let data: any = [{ CODCLI: '', CNPJ: '' }];
    exportSheetModel(fileName, data);
  };

  const convertKeysToLowerCase = (arr: Array<{ [key: string]: any }>): Array<{ [key: string]: any }> => {
    return arr.map((obj) => {
      const newObj: { [key: string]: any } = {};
      for (const key in obj) {
        newObj[key.toLowerCase()] = obj[key];
      }
      return newObj;
    });
  };

  return {
    handleDownloadSpreadsheet,
    setSelectAll,
    selectedClients,
    setSelectedClients,
    convertKeysToLowerCase,
  };
};
