'use client';

import { createContext, SetStateAction, useContext, useEffect, useState } from 'react';
import {
  CodParListprops,
  ParamsGeralCabObjectProps,
  ParamsGeralCabProps,
  ParamsGeralItemObjectProps,
} from './paramGeral.types';
import { getParametrosGeralCab, getParametrosGeralItem } from 'client/api';
import { useLoaderEffect } from 'providers/loaderEffect';

export interface ProdContextProps {
  paramsGeralCab: ParamsGeralCabObjectProps;
  setParamsGeralCab: React.Dispatch<SetStateAction<ParamsGeralCabObjectProps>>;
  paramsGeralItem: ParamsGeralItemObjectProps;
  setParamsGeralItem: React.Dispatch<SetStateAction<ParamsGeralItemObjectProps>>;
  handleGetCabParamsGeralList: () => void;
  handleGetItemParamsGeralList: () => void;
  codParCabList: CodParListprops[];
  codParItemList: CodParListprops[];
  selectedEmpresas: any[];
  setSelectedEmpresas: React.Dispatch<SetStateAction<any[]>>;
}

export const ParamsGeralContext = createContext({} as ProdContextProps);

export const ParamsGeralProvider = ({ children }: any) => {
  const { setLoaderHeader } = useLoaderEffect();

  const [paramsGeralCab, setParamsGeralCab] = useState<ParamsGeralCabObjectProps>({
    filtered: [],
    unfiltered: [],
  });
  const [paramsGeralItem, setParamsGeralItem] = useState<ParamsGeralItemObjectProps>({
    filtered: [],
    unfiltered: [],
  });

  const [codParCabList, setCodParCabList] = useState<CodParListprops[]>([]);
  const [codParItemList, setCodParItemList] = useState<CodParListprops[]>([]);

  const [selectedEmpresas, setSelectedEmpresas] = useState([]);

  const handleGetCabParamsGeralList = () => {
    getParametrosGeralCab().then((response) => {
      const geralCabList: ParamsGeralCabProps[] = response.data ? response.data : [];
      setParamsGeralCab({
        filtered: geralCabList ?? [],
        unfiltered: geralCabList ?? [],
      });
      setCodParCabList(
        geralCabList?.map((cab) => {
          return {
            label: `[${cab.codpar}] ${cab.descricao}`,
            codpar: cab.codpar,
          };
        }),
      );
    });
  };

  const handleGetItemParamsGeralList = () => {
    setLoaderHeader(true);
    getParametrosGeralItem()
      .then((response) => {
        const paramItemList = response.data ? response.data : [];
        const geralItemList: any[] = selectedEmpresas
          ? paramItemList?.filter(
              (item) => selectedEmpresas && selectedEmpresas.some((emp) => +emp.codEmpresa == +item.codEmpresa),
            )
          : paramItemList;

        setParamsGeralItem({
          filtered: geralItemList ?? [],
          unfiltered: geralItemList ?? [],
        });

        const uniqueItems: CodParListprops[] = [];

        for (let i = 0; i < geralItemList.length; i++) {
          if (!uniqueItems?.some((item) => +item.codpar == +geralItemList[i].codpar)) {
            uniqueItems.push({
              label: `[${geralItemList[i].codpar}] ${geralItemList[i].parametroDescricao}`,
              codpar: geralItemList[i].codpar,
            });
          }
        }
        setCodParItemList(uniqueItems);
      })
      .finally(() => {
        setLoaderHeader(false);
      });
  };

  useEffect(() => {
    handleGetCabParamsGeralList();
  }, []);

  useEffect(() => {
    handleGetItemParamsGeralList();
  }, [selectedEmpresas]);

  return (
    <ParamsGeralContext.Provider
      value={{
        paramsGeralCab,
        setParamsGeralCab,
        paramsGeralItem,
        setParamsGeralItem,
        handleGetCabParamsGeralList,
        handleGetItemParamsGeralList,
        codParCabList,
        codParItemList,
        selectedEmpresas,
        setSelectedEmpresas,
      }}
    >
      {children}
    </ParamsGeralContext.Provider>
  );
};

export const useParamsGeral = () => useContext(ParamsGeralContext);
