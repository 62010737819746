'use client';

import { createContext, SetStateAction, useContext, useEffect, useState } from 'react';
import { useLoaderEffect } from 'providers/loaderEffect';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import { getWebCliente, getWebPedCabList } from 'client/api/pedidos';
import { IPedParams, IWebPedCli, IWebPedSearchParams } from './pedido.types';
import { formatDate } from 'utils/formatDate';
import { toast } from 'react-toastify';

export interface ProdContextProps {
  searchParams: IWebPedSearchParams;
  setSearchParams: React.Dispatch<SetStateAction<IWebPedSearchParams>>;
  SelectEmpresa: any;
  selectedEmpresa: any;
  handleGetPedidos: (codCli?: number, dtIni?: Date, dtFim?: Date) => void;
  pedidos: IPedParams;
  setPedidos: React.Dispatch<SetStateAction<IPedParams>>;
  cliente: IWebPedCli;
  setCliente: React.Dispatch<SetStateAction<IWebPedCli>>;
  handlePedGridSearch: () => void;
  handleResetData: () => void;
  clientes: IWebPedCli[];
  setClientes: React.Dispatch<SetStateAction<IWebPedCli[]>>;
  handleDateDifference: (date1: Date, date2: Date) => { adjustedDate1: Date; adjustedDate2: Date } | null;
}

export const PedidoContext = createContext({} as ProdContextProps);

export const PedidoProvider = ({ children }: any) => {
  const { SelectEmpresa, selectedEmpresa } = UseSelectEmpresa();

  const { setLoaderHeader } = useLoaderEffect();

  const defaultPedidos = {
    filtered: [],
    unfiltered: [],
  };

  const [pedidos, setPedidos] = useState<IPedParams>(defaultPedidos);
  const [cliente, setCliente] = useState<IWebPedCli>(null);
  const [clientes, setClientes] = useState<IWebPedCli[]>(null);

  const defaultSearchParams = {
    client: '',
    text: '',
    dtIni: new Date(),
    dtFim: new Date(),
  };

  const [searchParams, setSearchParams] = useState<IWebPedSearchParams>(defaultSearchParams);

  const handleResetData = () => {
    setCliente(null);
    setPedidos(defaultPedidos);
  };

  const handleGetPedidos = async (codCli?: number, dtIni?: Date, dtFim?: Date) => {
    try {
      const handledCodCli = codCli && codCli != 0 ? codCli : +searchParams.client ? +searchParams.client : -1;
      setLoaderHeader(true);
      if (selectedEmpresa) {
        const pedidosList = await getWebPedCabList(
          selectedEmpresa.codEmpresa,
          handledCodCli,
          formatDate(dtIni ?? searchParams.dtIni, 'BR').replaceAll('/', '-'),
          formatDate(dtFim ?? searchParams.dtFim, 'BR').replaceAll('/', '-'),
        );
        handleGetPedClient(handledCodCli);
        setPedidos({
          unfiltered: pedidosList.data ?? [],
          filtered: pedidosList.data ?? [],
        });
      }
      setLoaderHeader(false);
    } catch (err) {
      setLoaderHeader(false);
      console.log('Err :', err);
    }
  };

  const handleGetPedClient = async (codCli: number) => {
    try {
      setLoaderHeader(true);
      if (selectedEmpresa && codCli) {
        const cliData = await getWebCliente(selectedEmpresa.codEmpresa, codCli ? codCli : +searchParams.client);
        if (cliData.data && cliData.data.length == 1) {
          setCliente(cliData.data[0] ?? null);
          setClientes([]);
        } else {
          setCliente(null);
          if (codCli) {
            setClientes(cliData.data ?? []);
          } else {
            setClientes([]);
          }
        }
      }
      setLoaderHeader(false);
    } catch (err) {
      setLoaderHeader(false);
      console.log('Err :', err);
    }
  };

  useEffect(() => {
    setSearchParams(defaultSearchParams);
  }, [useEffect]);

  useEffect(() => {
    handlePedGridSearch();
  }, [searchParams.text]);

  useEffect(() => {
    if (searchParams.dtIni && searchParams.dtFim) {
      const newDates = handleDateDifference(searchParams.dtIni, searchParams.dtFim);
      if (newDates) {
        setSearchParams((prev) => ({
          ...prev,
          dtIni: newDates.adjustedDate1,
        }));
        toast.error('Período de busca máximo de 30 dias');
      }
      handleGetPedidos(searchParams.client != '' ? +searchParams.client : -1, searchParams.dtIni, searchParams.dtFim);
    }
  }, [searchParams.dtIni, selectedEmpresa]);

  useEffect(() => {
    if (searchParams.dtIni && searchParams.dtFim && selectedEmpresa.codEmpresa) {
      const newDates = handleDateDifference(searchParams.dtIni, searchParams.dtFim);
      if (newDates) {
        setSearchParams((prev) => ({
          ...prev,
          dtFim: newDates.adjustedDate2,
        }));
        toast.error('Período de busca máximo de 30 dias');
      }
      handleGetPedidos(searchParams.client != '' ? +searchParams.client : -1, searchParams.dtIni, searchParams.dtFim);
    }
  }, [searchParams.dtFim]);

  const handlePedGridSearch = () => {
    let pedidosList = [];

    Object.keys(pedidos.unfiltered).forEach((key1) => {
      let itera = false;
      Object.keys(pedidos.unfiltered[key1]).forEach((key2) => {
        if (
          pedidos.unfiltered[key1][key2] != null &&
          pedidos.unfiltered[key1][key2].toString().includes(searchParams.text.toUpperCase())
        ) {
          itera = true;
        }
      });
      if (itera) {
        pedidosList.push(pedidos.unfiltered[key1]);
      }
    });

    setPedidos((prev) => ({
      ...prev,
      filtered: pedidosList,
    }));
  };

  const handleDateDifference = (date1: Date, date2: Date): { adjustedDate1: Date; adjustedDate2: Date } | null => {
    let [earlier, later] = date1 < date2 ? [date1, date2] : [date2, date1];
    if (earlier.getMonth() !== later.getMonth() || earlier.getFullYear() !== later.getFullYear()) {
      const diffInMs = later.getTime() - earlier.getTime();
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

      if (diffInDays > 30) {
        const newLaterDate = new Date(earlier);
        newLaterDate.setDate(newLaterDate.getDate() + 30);
        return { adjustedDate1: earlier, adjustedDate2: newLaterDate };
      }
    }
    return null;
  };

  return (
    <PedidoContext.Provider
      value={{
        searchParams,
        setSearchParams,
        SelectEmpresa,
        selectedEmpresa,
        handleGetPedidos,
        pedidos,
        setPedidos,
        cliente,
        setCliente,
        handlePedGridSearch,
        handleResetData,
        clientes,
        setClientes,
        handleDateDifference,
      }}
    >
      {children}
    </PedidoContext.Provider>
  );
};

export const usePedidos = () => useContext(PedidoContext);
