import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { StringBodyTemplate, styleHeaderCenter, textBodyTemplate } from 'components/datatable/templates';
import { IGroupClient } from 'client/interfaces/groupClient';
import { useGroupClientsFiliais } from 'client/hooks/groupClients';
import { EditGroupClients } from '../../EditGroupClients';
import { DeleteGroupClient } from '../../DeleteGroupClient';
import { useListGroupClients } from 'hooks/useGroupClients/useListGroupClients';
import { useGroupClientMember } from 'storesZustand/groupClientMember';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { trackGCatchError } from 'utils/analytics';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';

import * as S from '../styles';
import { useLoaderEffect } from 'providers/loaderEffect';

interface ITableGroupClients {
  codEmpresa: string;
  codFiliais: any;
  active: any;
  selectedCliente?: number;
  searchText: string;
  handleCloseShowNewGroupModal: () => void;
}

export const TableGroupClients = ({
  codEmpresa,
  codFiliais,
  active,
  searchText,
  selectedCliente,
  handleCloseShowNewGroupModal,
}: ITableGroupClients) => {
  try {
    const {
      isOpenModalEdit,
      setIsOpenModalEdit,
      isOpenModalDelete,
      setIsOpenModalDelete,
      rowGroupClient,
      setRowGroupClient,
    } = useListGroupClients();

    const { setLoaderHeader } = useLoaderEffect();

    const { data: groupClientsFilial, refetch: refetchFilial } = useGroupClientsFiliais(
      codEmpresa,
      codFiliais ? codFiliais?.map((fil) => fil.codFilial) : 0,
      selectedCliente ?? 0,
    );

    useEffect(() => {
      refetchFilial();
    }, [codEmpresa, codFiliais, selectedCliente]);

    useEffect(() => {
      setLoaderHeader(false);
    }, [groupClientsFilial]);

    const [tableDataGroup, setTableDataGroup] = useState([]);

    useEffect(() => {
      const filtered =
        active === 'T' ? groupClientsFilial : groupClientsFilial?.filter((grupo) => grupo?.ativo === active);
      const filteredByName = filtered?.filter((grupo) =>
        grupo?.nomeGrupo.toLowerCase().includes(searchText.toLowerCase()),
      );
      setTableDataGroup(filteredByName);
      handleCloseShowNewGroupModal();
    }, [groupClientsFilial, active, searchText]);

    const setGroupInfo = useGroupClientMember((state) => state.setGroupInfo);

    const navigate = useNavigate();

    const handleEditGroup = (rowData: IGroupClient) => {
      const handleOpenModal = (rowData: IGroupClient) => {
        setRowGroupClient(rowData);
        setIsOpenModalEdit(!isOpenModalEdit);
      };

      return (
        <Button
          color="gray"
          tooltip="Editar"
          icon={<icons.Edit />}
          onClick={() => {
            handleOpenModal(rowData);
          }}
          rounded
        />
      );
    };

    const handleAddContent = async (rowData: IGroupClient) => {
      await setGroupInfo(rowData);

      return navigate(`/grupo-clientes/listar/membros/${codEmpresa}`);
    };

    const handleAddMemberToGroup = (rowData: IGroupClient) => {
      return (
        <Button
          rounded
          color="gray"
          tooltip="Listar/Vincular Clientes"
          icon={
            <>
              <icons.Plus />
              <icons.PeopleFill />
            </>
          }
          onClick={() => {
            handleAddContent(rowData);
          }}
        />
      );
    };

    return (
      <S.GroupCLientTableBox>
        <DataTable
          value={tableDataGroup}
          header=""
          dataKey="id"
          editMode="cell"
          emptyMessage="Nenhum dado encontrado"
          paginator
          rowsPerPageOptions={[10, 25, 50]}
          rows={10}
          size="small"
        >
          <Column
            header="Nome Grupo"
            field="nomeGrupo"
            body={(rowData) => textBodyTemplate(rowData?.nomeGrupo, rowData?.codgrupo)}
          />
          <Column field="qtdClientesGrupo" header="Qtd. Clientes" headerStyle={styleHeaderCenter} />
          <Column field="exibirFv" header="Exibir FV" body={(rowData) => StringBodyTemplate(rowData.exibirFv)} />
          <Column header="Código Empresa" field="codempresa" />
          <Column header="Código Filial" field="codfilial" />
          <Column header="Ativo/Inativo" field="ativo" body={(rowData) => StringBodyTemplate(rowData.ativo)} />
          <Column header="" field="codfilial" body={(rowData) => handleAddMemberToGroup(rowData)} />
          <Column header="" field="codfilial" body={(rowData) => handleEditGroup(rowData)} />
        </DataTable>

        {isOpenModalEdit && (
          <EditGroupClients
            rowGroupClient={rowGroupClient}
            isOpenModalEdit={isOpenModalEdit}
            setIsOpenModalEdit={setIsOpenModalEdit}
          />
        )}

        <DeleteGroupClient
          rowGroupClient={rowGroupClient}
          isOpenModalDelete={isOpenModalDelete}
          setIsOpenModalDelete={setIsOpenModalDelete}
          refetch={refetchFilial}
        />
      </S.GroupCLientTableBox>
    );
  } catch (err) {
    trackGCatchError(err, 'groupClients/ListGroupClients/TableGroupClients/index.tsx');
  }
};
