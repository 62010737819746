import * as S from './ajustaCreditoModal.styles';
import { Dialog } from 'primereact/dialog';
import InputText from 'components/Inputs/InputText/text.input';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import useMediaQuery from 'hooks/useMediaQuery';
import formatToCurrency from 'utils/formatToCurrency';
import { useVerbas } from 'modules/verbas/verbas.context';
import { useState } from 'react';
import { RadioButton } from 'primereact/radiobutton';
import { postVerbaSaldo } from 'client/api/verbas';
import { toast } from 'react-toastify';
import { useUser } from 'client/hooks';

export default function AjustaCreditoModal() {
  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const {
    showAjustaCreditoModal,
    setShowAjustaCreditoModal,
    selectedUsuario,
    handleGetVerbasList,
    toAdjustSaldoVerba,
  } = useVerbas();

  const [selectedOper, setSelectedOper] = useState('INCLUIRSALDO');

  const [saldoDescription, setSaldoDescription] = useState('');

  const [saldoValue, setSaldoValue] = useState('');

  const [allowSaveSaldo, setAllowSaveSaldo] = useState(false);

  const handleValidateFields = () => {
    if (saldoDescription != '' && saldoValue != '' && +saldoValue.replace(',', '') > 0 && toAdjustSaldoVerba) {
      setAllowSaveSaldo(true);
    } else if (allowSaveSaldo) {
      setAllowSaveSaldo(false);
    }
  };

  const handleClearVariables = () => {
    setAllowSaveSaldo(false);
    setSaldoDescription('');
    setSaldoValue('');
  };

  const { data: user } = useUser();

  const handleSaveSaldo = async () => {
    const handledVlCredito = +saldoValue.replaceAll('.', '').replace(',', '.');
    if (selectedOper != 'ZERARSALDO' && handledVlCredito == 0) {
      toast.error('Informe um valor de saldo válido');
      return;
    } else if (saldoDescription == '') {
      toast.error('Campo descrição não informado');
      return;
    }

    postVerbaSaldo(
      selectedOper,
      toAdjustSaldoVerba.NUMVERBA,
      handledVlCredito,
      saldoDescription,
      user ? user.codUsuario : selectedUsuario.codUsuario,
      toAdjustSaldoVerba.COD_EMPRESA,
    )
      .then((res) => {
        if (res.succeeded || res.message.toUpperCase().includes('SUCESSO')) {
          handleClearVariables();
          toast.success('Sucesso ao gravar saldo de verba');
          setShowAjustaCreditoModal(false);
          handleGetVerbasList();
        } else {
          toast.error('Falha inesperada ao gravar saldo de verba');
        }
      })
      .catch((err) => {
        console.log('err :', err);
        toast.error('Falha inesperada ao gravar saldo de verba');
      });
  };

  return (
    <Dialog
      header={() => {
        return <b>{`Ajuste de Crédito`}</b>;
      }}
      visible={showAjustaCreditoModal}
      onHide={() => {
        setShowAjustaCreditoModal(false);
        handleGetVerbasList();
      }}
      style={{
        width: isWebScreen ? '35svw' : '95svw',
      }}
    >
      <S.AjustaCreditoFormBox>
        {toAdjustSaldoVerba && (
          <S.AjustaCreditoFormRow>
            {`Verba: [${toAdjustSaldoVerba.NUMVERBA}] ${toAdjustSaldoVerba.DESCRICAO}`}
          </S.AjustaCreditoFormRow>
        )}

        <S.AjustaCreditoFormRow>
          <InputText
            label="Descrição *"
            width="49%"
            value={saldoDescription}
            onChange={(e) => {
              handleValidateFields();
              setSaldoDescription(e.toUpperCase());
            }}
          />
          <InputText
            label="Valor *"
            number
            width="49%"
            value={selectedOper != 'ZERARSALDO' ? saldoValue : 0}
            mask={formatToCurrency}
            onChange={(e) => {
              handleValidateFields();
              setSaldoValue(formatToCurrency(e));
            }}
          />
        </S.AjustaCreditoFormRow>

        <S.AjustaCreditoFormRow>
          <S.AjustaCreditoRadioButtonBox>
            <label>Incluir Saldo</label>
            <RadioButton
              inputId="include"
              name="Incluir Saldo"
              value="INCLUIRSALDO"
              onChange={(e) => {
                setSelectedOper(e.value);
              }}
              checked={selectedOper === 'INCLUIRSALDO'}
            />
          </S.AjustaCreditoRadioButtonBox>

          <S.AjustaCreditoRadioButtonBox>
            <label>Remover Saldo</label>
            <RadioButton
              inputId="remove"
              name="Remover Saldo"
              value="REMOVERSALDO"
              onChange={(e) => {
                setSelectedOper(e.value);
              }}
              checked={selectedOper === 'REMOVERSALDO'}
            />
          </S.AjustaCreditoRadioButtonBox>

          <S.AjustaCreditoRadioButtonBox>
            <label>Zerar Saldo</label>
            <RadioButton
              inputId="drop"
              name="Zerar Saldo"
              value="ZERARSALDO"
              onChange={(e) => {
                setSelectedOper(e.value);
              }}
              checked={selectedOper === 'ZERARSALDO'}
            />
          </S.AjustaCreditoRadioButtonBox>
        </S.AjustaCreditoFormRow>

        <S.AjustaCreditoFormRow>
          <Button
            text="Gravar crédito"
            icon={<icons.Check />}
            color="green"
            width="100%"
            disabled={!allowSaveSaldo && selectedOper != 'ZERARSALDO'}
            tooltip={!allowSaveSaldo && selectedOper != 'ZERARSALDO' ? 'Preencha todos os campos' : ''}
            onClick={() => {
              (allowSaveSaldo || selectedOper == 'ZERARSALDO') && handleSaveSaldo();
            }}
          />
        </S.AjustaCreditoFormRow>
      </S.AjustaCreditoFormBox>
    </Dialog>
  );
}
