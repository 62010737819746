import styled from 'styled-components';

export const VerbasTableMainBox = styled.section`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export const VerbasBodyBox = styled.section`
  width: 100%;
  background-color: #00000010;
  border-radius: 7.5px;
  border: 1px solid #cccccc70;
  background-color: #fff;
  overflow: scroll;
  flex: 1;

  .p-datatable {
    max-height: 60svh;
  }
  .p-datatable-thead {
    position: sticky;
    top: 0;
  }

  .p-datatable .selected-table-row {
    color: #2e86c1 !important;
    background-color: #2e86c110 !important;
    cursor: pointer !important;
    border-bottom: 1px solid #60606020;
  }
  .selected-table-row:hover {
    background-color: #2e86c130 !important;
  }

  .p-datatable .expirada-table-row {
    color: #cb2a29 !important;
    background-color: #cb2a2910 !important;
    cursor: pointer !important;
    border-bottom: 1px solid #60606020;
  }
  .expirada-table-row:hover {
    background-color: #cb2a2930 !important;
  }

  .p-datatable .vigente-table-row {
    color: #3f7d54 !important;
    background-color: #3f7d5410 !important;
    cursor: pointer !important;
    border-bottom: 1px solid #60606020;
  }
  .vigente-table-row:hover {
    background-color: #3f7d5430 !important;
  }
  .p-datatable .header-center {
    text-align: center !important;
  }
`;

export const VerbasTableIcon = styled.section`
  font-size: 1.5rem;
  padding-right: 10px;
  text-align: center !important;
  svg {
    color: #606060;
  }

  img {
    max-width: 50px;
    max-height: 50px;
    margin: 0 auto;
  }
`;

export const VerbasFuncTemplate = styled.div`
  text-align: left !important;
`;

export const AccordionHeaderTemplateBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  align-items: center;

  .reactivus-btn-success {
    background-color: #3f7d5420 !important;
  }

  .reactivus-btn-success:hover {
    background-color: #3f7d5440 !important;
  }

  .reactivus-btn-success svg {
    font-size: 0.9rem;
  }
`;

export const AccordionHeaderButtonsBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;

  @media (max-width: 1060px) {
    flex-direction: column;
    gap: 10px;
  }
`;
