import * as S from './paramRcaEdit.styles';
import { useEffect, useMemo, useState } from 'react';
import { trackGCatchError, trackGPageView } from 'utils/analytics';
import { alterPageTitle } from 'utils/alterPageTitle';
import { useParamsRca } from '../paramRca.context';
import { useNavigate, useParams } from 'react-router-dom';

import returnParamsRcaInputs from '../paramRca.inputs';
import { ParamsRcaProps, ParamsRcaTableColumn } from '../paramRca.types';
import icons from 'components/Icons/icons.index';
import Button from 'components/Button/button.index';
import { updateParams } from 'client/api';
import { toast } from 'react-toastify';
import { dialog } from 'reactivus';
import useMediaQuery from 'hooks/useMediaQuery';
import { usePageTitle } from 'providers/pageTitle';

export default function ParamRcaEdit() {
  const { tableConfig, selectedParamRca, selectedToEditParamRca, setSelectedToEditParamRca, handleGetSingleParamRca } =
    useParamsRca();
  try {
    const navigate = useNavigate();

    const isWebScreen = useMediaQuery('(min-width: 1024px)');

    useEffect(() => {
      trackGPageView('/prametro/vendedor/edit');
      alterPageTitle('Editar Parâmetros Vendedor');
    }, []);

    const {
      codEmpresa: codEmpresaParam,
      codUsuario: codUsuarioParam,
      codVendedor: codVendedorParam,
    } = useParams<{ codEmpresa: string; codUsuario: string; codVendedor: string }>();

    useEffect(() => {
      if (codVendedorParam && codEmpresaParam) {
        handleGetSingleParamRca(+codEmpresaParam, +codVendedorParam, true, false);
      }
    }, [codVendedorParam, codEmpresaParam]);

    const [actualSection, setActualSection] = useState(0);
    const [allowSaveAction, setAllowSaveAction] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { setPageTitle } = usePageTitle();
    useEffect(() => {
      if (selectedParamRca && selectedParamRca.codUsur && selectedParamRca.nomeVendedor) {
        setPageTitle((prev) => ({
          text: `[${selectedParamRca.codUsur}] ${selectedParamRca.nomeVendedor}`,
          customObject: prev.customObject,
          tree: prev.tree,
        }));
      }
    }, [selectedParamRca]);

    const handleChangeEditParamFieldValue = (value: string | number | Date | boolean, field: string, type: string) => {
      let newValue = value;

      if (type === 'date' && newValue instanceof Date) {
        newValue = newValue.toISOString().slice(0, 19);
      } else if (type === 'number') {
        newValue = newValue.toString().replace(/\D/g, '');
      }

      setSelectedToEditParamRca((prev: ParamsRcaProps) => {
        const updated = { ...prev, [field]: newValue };
        return updated;
      });
    };

    const deepEqual = (obj1: any, obj2: any): boolean => {
      if (obj1 === obj2) return true;
      if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) return false;

      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (keys1.length !== keys2.length) return false;

      return keys1.every((key) => deepEqual(obj1[key], obj2[key]));
    };

    useEffect(() => {
      const paramToCheck = { ...selectedToEditParamRca };
      if (JSON.stringify(selectedParamRca) != JSON.stringify(paramToCheck)) {
        setAllowSaveAction(true);
      } else if (allowSaveAction) {
        setAllowSaveAction(false);
      }
    }, [selectedToEditParamRca]);

    const handleSaveEdit = (returnPageAfterSaving?: boolean) => {
      setIsLoading(true);
      updateParams(selectedToEditParamRca)
        .then((res) => {
          toast.success('Parametros atualizados');
          setAllowSaveAction(false);
          handleGetSingleParamRca(+codEmpresaParam, +codVendedorParam, true, true);
          if (returnPageAfterSaving) {
            setTimeout(() => {
              handleBackButton(true);
            }, 1200);
          }
        })
        .catch((err) => {
          toast.error(err.message ? `Falha inesperada: ${err.message}` : 'Falha inesperada ao atualizar parametros');
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    const handleBackButton = (ignoreUnsavedData?: boolean) => {
      if (allowSaveAction && !ignoreUnsavedData) {
        dialog
          .show({
            allowClose: true,
            showConfirmButton: true,
            confirmButtonText: 'Salvar alterações',
            showCancelButton: true,
            cancelButtonText: 'Descartar alterações',
            icon: 'question',
            title: 'Sair sem salvar?',
            text: 'Ainda existem alterações não salvas',
          })
          .then((res) => {
            if (res.isCanceled) {
              navigate('/parametros/vendedor/lista');
              return;
            } else if (res.isConfirmed) {
              handleSaveEdit(true);
              return;
            }
          });
      } else {
        navigate('/parametros/vendedor/lista');
      }
    };

    const calculateFieldWidth = (col: ParamsRcaTableColumn): string => {
      const isMobile = !isWebScreen;
      const maxMobileWidth = 250;
      const maxWebWidth = 320;
      const { type, field } = col;
      let widthToSet = 130;
      const paddingOffset = 20;
      const letterOffset = 8;

      if (type == 'string' || type == 'date' || type == 'number') {
        if (selectedToEditParamRca && selectedToEditParamRca[field]) {
          if (col.header.length * 7.5 + 20 < selectedToEditParamRca[field].toString().length * 7.5 + 20) {
            widthToSet = selectedToEditParamRca[field].toString().length * letterOffset + paddingOffset;
          } else {
            widthToSet = col.header.length * 7.5 + 20;
          }
        }
      } else {
        widthToSet = 130;
      }
      widthToSet =
        isMobile && widthToSet > maxMobileWidth ? maxMobileWidth : widthToSet > maxWebWidth ? maxWebWidth : widthToSet;

      return `${widthToSet}px`;
    };

    const renderedInputs = useMemo(() => {
      if (actualSection > 0) {
        return (
          <S.ParamRcaToggleContentBox>
            {tableConfig[actualSection]?.columns
              ?.filter((col) => col.display)
              .map((col) => {
                if (typeof col.display == 'function' && col.display && !col.display(selectedToEditParamRca)) {
                  return;
                }
                return (
                  <S.ParamRcaToggleContent key={col.field}>
                    {returnParamsRcaInputs(col.type, {
                      label: col.label ?? col.header,
                      name: col.field,
                      disabled: !col.editable,
                      defaultValue: selectedToEditParamRca ? selectedToEditParamRca[col.field] : undefined,
                      checked:
                        col.type == 'boolean' && selectedToEditParamRca ? selectedToEditParamRca[col.field] : undefined,
                      width: !isWebScreen ? '100%' : calculateFieldWidth(col),
                      mask: col.mask ?? undefined,
                      onChange: (e) => {
                        handleChangeEditParamFieldValue(col.mask ? col.mask(e) : e, col.field, col.type);
                      },
                      className:
                        col.className && typeof col.className == 'function'
                          ? col.className(selectedToEditParamRca)
                          : col.className,
                    })}
                  </S.ParamRcaToggleContent>
                );
              })}
          </S.ParamRcaToggleContentBox>
        );
      } else {
        return (
          <S.ParamRcaToggleRowBox>
            {tableConfig
              ?.filter((group) => group.listOnAll)
              .map((group, groupIndex) => {
                if (typeof group.display == 'function' && group.display && !group.display(selectedToEditParamRca)) {
                  return;
                }
                return (
                  <S.ParamRcaToggleRow key={groupIndex}>
                    {group.label && typeof group.label == 'function' ? (
                      group.label(selectedToEditParamRca)
                    ) : (
                      <S.ParamRcaToggleRowTitle>{group.label ?? group.header}</S.ParamRcaToggleRowTitle>
                    )}
                    <S.ParamRcaToggleRowContent>
                      {group.columns
                        ?.filter((col) => col.display)
                        .map((col) => {
                          if (typeof col.display == 'function' && col.display && !col.display(selectedToEditParamRca)) {
                            return;
                          }
                          return (
                            <S.ParamRcaToggleContent key={col.field}>
                              {returnParamsRcaInputs(col.type, {
                                label: col.label ?? col.header,
                                name: col.field,
                                disabled: !col.editable,
                                defaultValue: selectedToEditParamRca ? selectedToEditParamRca[col.field] : undefined,
                                checked:
                                  col.type == 'boolean' && selectedToEditParamRca
                                    ? selectedToEditParamRca[col.field]
                                    : undefined,
                                width: !isWebScreen ? '100%' : calculateFieldWidth(col),
                                mask: col.mask ?? undefined,
                                onChange: (e) => {
                                  handleChangeEditParamFieldValue(col.mask ? col.mask(e) : e, col.field, col.type);
                                },
                                className:
                                  col.className && typeof col.className == 'function'
                                    ? col.className(selectedToEditParamRca)
                                    : col.className,
                              })}
                            </S.ParamRcaToggleContent>
                          );
                        })}
                    </S.ParamRcaToggleRowContent>
                  </S.ParamRcaToggleRow>
                );
              })}
          </S.ParamRcaToggleRowBox>
        );
      }
    }, [selectedToEditParamRca, actualSection, tableConfig]);

    const paramEditHeaderSaveButton = useMemo(() => {
      return (
        <Button
          icon={<icons.Check />}
          text={'Salvar'}
          tooltip={allowSaveAction ? 'Salvar Alterações' : 'Nenhuma Alteração Efetuada'}
          tooltipPosition="left"
          color={'green'}
          onClick={() => {
            allowSaveAction && handleSaveEdit();
          }}
          disabled={!allowSaveAction}
          loading={isLoading}
          width={isWebScreen ? '' : '100%'}
        />
      );
    }, [isWebScreen, allowSaveAction, selectedToEditParamRca]);

    return (
      <S.ParamRcaEditMainBox>
        <S.ParamRcaEditHeader>
          {tableConfig?.map((group, groupIndex) => {
            if (
              typeof group.listOnHeader == 'function' &&
              group.listOnHeader &&
              !group.listOnHeader(selectedToEditParamRca)
            ) {
              return;
            }
            if (group.listOnHeader) {
              return (
                <S.ParamRcaToggleListHeaderItem
                  active={actualSection == groupIndex}
                  key={`header-${groupIndex}`}
                  onClick={() => {
                    setActualSection(groupIndex);
                  }}
                >
                  {groupIndex == 0 ? 'Todos' : group.labelHeader ?? group.header}
                </S.ParamRcaToggleListHeaderItem>
              );
            }
          })}
          {isWebScreen && paramEditHeaderSaveButton}
        </S.ParamRcaEditHeader>

        {renderedInputs}
        {!isWebScreen && <S.ParamRcaEditHMobileSaveButton>{paramEditHeaderSaveButton}</S.ParamRcaEditHMobileSaveButton>}
      </S.ParamRcaEditMainBox>
    );
  } catch (err) {
    trackGCatchError(err, 'paramRcaEdit.index.tsx');
  }
}
