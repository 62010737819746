import { Dialog } from 'primereact/dialog';
import * as S from './styles';
import { UseEditGroupClient } from 'hooks/useGroupClients/useEditGroupClient';
import { useEffect, useState } from 'react';
import { IGroupClient } from 'client/interfaces/groupClient';
import { trackGCatchError } from 'utils/analytics';
import InputText from 'components/Inputs/InputText/text.input';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import UseSelectUsuario from 'hooks/UseSelectUsuario/selectUsuario';
import UseSelectFilial from 'hooks/UseSelectFilial/selectFilial';
import InputSwitch from 'components/Inputs/InputSwitch/switch.input';
import Button from 'components/Button/button.index';
import icons from 'components/Icons/icons.index';
import useMediaQuery from 'hooks/useMediaQuery';
import { useLoaderEffect } from 'providers/loaderEffect';

interface IEditGroupClient {
  isOpenModalEdit: boolean;
  setIsOpenModalEdit: React.Dispatch<React.SetStateAction<boolean>>;
  rowGroupClient: IGroupClient;
}

export const EditGroupClients = ({ rowGroupClient, isOpenModalEdit, setIsOpenModalEdit }: IEditGroupClient) => {
  const isWebScreen = useMediaQuery('(min-width: 1060px)');
  try {
    const [isValid, setIsValid] = useState(false);

    const { handleSubmit, values, setValue, DefaultValues, handleEditGroupClient } = UseEditGroupClient({
      setIsOpenModalEdit,
      rowGroupClient,
    });

    const { setLoaderHeader } = useLoaderEffect();

    const { SelectEmpresa, selectedEmpresa } = UseSelectEmpresa();
    const { selectedUsuario } = UseSelectUsuario(selectedEmpresa.codEmpresa);
    const { SelectFilial, handleSelectFilialByCode, filiais } = UseSelectFilial(
      selectedEmpresa.codEmpresa,
      selectedUsuario.codUsuario,
    );

    useEffect(() => {
      if (rowGroupClient.codfilial && filiais) {
        handleSelectFilialByCode(+rowGroupClient.codfilial);
      }
    }, [rowGroupClient, isOpenModalEdit, filiais]);

    useEffect(() => {
      if (
        values.codempresa === DefaultValues.COMPANY ||
        values.codfilial === DefaultValues.FILIAL ||
        values.nomeGrupo === '' ||
        values.ativo === 'T'
      ) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    }, []);

    return (
      <Dialog
        header={`Editar Grupo ${rowGroupClient?.nomeGrupo}`}
        visible={isOpenModalEdit}
        onHide={() => {
          setIsOpenModalEdit(!isOpenModalEdit);
        }}
        style={{ width: '40vw' }}
        breakpoints={{ '960px': '75vw', '641px': '90vw' }}
      >
        <S.Form onSubmit={handleSubmit(handleEditGroupClient)}>
          <InputText
            label="Nome do Grupo de Clientes"
            onChange={(e) => {
              setValue('nomeGrupo', e);
            }}
            width="100%"
            value={values.nomeGrupo}
          />
          <SelectEmpresa
            label="Empresa *"
            onChange={(e) => {
              setValue('codfilial', e.codEmpresa);
            }}
            width={!isWebScreen ? '100%' : '47%'}
          />
          <SelectFilial
            label="Filial *"
            onChange={(e) => {
              setValue('codempresa', e.codFilial);
            }}
            width={!isWebScreen ? '100%' : '47%'}
          />

          <S.ItemRow>
            <InputSwitch
              label="Ativo/Inativo"
              onChange={(e) => {
                setValue('ativo', e ? 'S' : 'N');
              }}
              checked={values.ativo == 'S'}
            />
            <InputSwitch
              label="Exibir FV"
              onChange={(e) => {
                setValue('exibirFv', e ? 'S' : 'N');
              }}
              checked={values.exibirFv == 'S'}
            />
          </S.ItemRow>

          <S.ItemRowFooter>
            <Button
              type={'submit'}
              text="Editar"
              disabled={!isValid}
              color="green"
              icon={<icons.Check />}
              onClick={handleSubmit(() => {
                setLoaderHeader(true);
                handleEditGroupClient(values).finally(() => {
                  setLoaderHeader(false);
                });
              })}
            />
          </S.ItemRowFooter>
        </S.Form>
      </Dialog>
    );
  } catch (err) {
    trackGCatchError(err, 'groupClients/EditGroupClients/index.tsx');
  }
};
