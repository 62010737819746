import React from 'react';
import * as SI from 'styles/inputs.styles';

interface InputTextProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  onChange?: (value: string) => void;
  width?: string;
  label?: string;
  placeholder?: string;
  number?: boolean;
  mask?: (value: string) => string;
  icon?: React.ReactNode;
  iconAction?: () => void;
  iconPosition?: 'left' | 'right';
  description?: string;
}

const InputText = React.forwardRef<HTMLInputElement, InputTextProps>(
  (
    {
      onChange,
      width,
      label = '',
      placeholder = '',
      number = false,
      mask,
      icon,
      iconAction,
      iconPosition = 'right',
      description,
      ...rest
    },
    ref,
  ) => {
    const widthCalculated = label.length * 28 > 200 ? 200 : label.length * 28;

    const handleChange = (e: string) => {
      let value = e;

      if (number) {
        value = value
          .replace(/[^0-9,.]/g, '')
          .replace(/(\..*)\./g, '$1')
          .replace(/(,.*),/g, '$1');
      }

      if (onChange) {
        onChange(value);
      }
    };

    return (
      <SI.InputMainBox width={width} widthCalculated={widthCalculated}>
        <label>{label}</label>

        <SI.InputTextBox iconPosition={iconPosition} icon={icon ? true : false}>
          <input
            type="text"
            onChange={(e) => {
              let value = e.target.value;
              if (mask) {
                value = mask(value);
                e.target.value = value;
                handleChange(value);
              } else {
                handleChange(e.target.value);
                e.target.value = number
                  ? e.target.value
                      .replace(/[^0-9,.]/g, '')
                      .replace(/(\..*)\./g, '$1')
                      .replace(/(,.*),/g, '$1')
                  : e.target.value;
              }
            }}
            placeholder={placeholder}
            ref={ref}
            {...rest}
          />

          {icon && (
            <SI.InputTextIconBox
              onClick={() => {
                iconAction && iconAction();
              }}
              iconPosition={iconPosition}
              icon={icon ? true : false}
            >
              {icon}
            </SI.InputTextIconBox>
          )}
        </SI.InputTextBox>
        {/* <SI.InputDescriptionBox>{description}</SI.InputDescriptionBox> */}
      </SI.InputMainBox>
    );
  },
);

export default InputText;
