import { Container, Header } from 'components/Containers';
import styled from 'styled-components';

export const PedidoMainBox = styled(Container)`
  height: 100%;
  align-items: stretch;
  gap: 5px;
`;

export const PedidoHeaderMainBox = styled(Header)`
  @media (max-width: 1060px) {
    min-height: 8svh !important;
  }
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 0%;
  left: 0%;
  height: 2px;
  margin-bottom: 5px;
`;
