import { BoxColumn, BoxRow } from 'components/Containers';
import styled from 'styled-components';

export const AjustaCreditoFormBox = styled(BoxColumn)`
  justify-content: stretch;
  overflow-y: scroll;
  gap: 15px;
  box-shadow: none;
  scrollbar-width: none;
`;

export const AjustaCreditoFormRow = styled(BoxRow)`
  justify-content: space-between;
  overflow-y: scroll;
  gap: 15px;
  box-shadow: none;
  align-items: flex-end;
  width: 100%;
  scrollbar-width: none;
`;

export const AjustaCreditoRadioButtonBox = styled(BoxColumn)`
  justify-content: space-between;
  overflow-y: scroll;
  gap: 15px;
  box-shadow: none;
  align-items: flex-start;
  width: 100%;
  scrollbar-width: none;
`;
