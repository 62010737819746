export const hourMask = (value: string | number) => {
  let cleanValue = value.toString().replace(/\D/g, '');

  if (cleanValue.length === 0) return '';

  let hours = cleanValue.slice(0, 2);
  let minutes = cleanValue.slice(2, 4);

  if (parseInt(hours, 10) > 23) {
    hours = '23';
  }

  if (minutes && parseInt(minutes, 10) > 59) {
    minutes = '59';
  }

  if (cleanValue.length <= 2) {
    return hours;
  }

  return `${hours}:${minutes}`;
};
