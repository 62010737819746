import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useMap } from '../../regioes.context';
import { ClientesProps, PolygonRegionProps } from '../../regioes.types';
import MapCliPopUp from '../mapCliPopUp/mapCliPopUp.index';
import { createRoot } from 'react-dom/client';
import MapEditAddress from '../mapEditAddress/mapEditAddress.index';
import MapVinculoCli from '../mapVinculoCli/mapVinculoCli.index';
import { MapEditVisit } from '../mapEditVisit/mapEditVisit.index';
import { useLoaderEffect } from 'providers/loaderEffect';

import * as turf from '@turf/turf';

import ReactDOM from 'react-dom';

import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import NoDataFound from 'components/NoDataFound/noDataFound.index';

import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw';

import ManagePolygon from './components/managePolygon/managePolygon.index';
import { dialog } from 'reactivus';
import { mapLayersObject } from 'components/MapLayers/mapLayers.index';
import { trackGCatchError } from 'utils/analytics';
import { LoaderEffectIndependentPage } from 'components/LoaderEffect';

const MapContainer = () => {
  const { setLoader } = useLoaderEffect();
  try {
    const {
      clientes,
      selectedCli,
      setSelectedCli,
      isDrawing,
      setoresFilter,
      setores,
      handleRemoveCliSector,
      noSectorControl,
      selectedSetores,
      setShowMapMenu,
      showMapNoDataFound,
      allowMapCenterAndZoom,
      mapInstance,
      updateMapCenter,
      lastCoordinateZoomed,
      zoomLevel,
      setZoomLevel,
      showMapLoading,
    } = useMap();
    const mapRef = useRef(null);

    const canvasLayerRef = useRef(null);

    const [polygonCoordinates, setPolygonCoordinates] = useState([]);
    const polygonLayerRef = useRef(null);

    const [showEditAddressModal, setShowEditAddressModal] = useState<boolean>(false);
    const [showVinculoSetor, setShowVinculoSetor] = useState<boolean>(false);
    const [showEditVisit, setShowEditVisit] = useState<boolean>(false);

    useEffect(() => {
      if (selectedCli) {
        showClientPopup(selectedCli, mapInstance.current);
      } else if (mapInstance.current) {
        mapInstance.current.closePopup();
      }
    }, [selectedCli, mapInstance]);

    useEffect(() => {
      if (!showMapNoDataFound && mapInstance && mapInstance.current) {
        setTimeout(() => {
          renderSavedPolygons();
        }, 100);
      }
    }, [showMapNoDataFound, mapInstance.current]);

    const showClientPopup = (client: ClientesProps, mapInstance: L.Map) => {
      if (!client) return;

      mapInstance.closePopup();

      const { lat, lng } = client;

      const targetPoint = mapInstance.latLngToContainerPoint([lat, lng]);
      const offsetPoint = L.point(targetPoint.x, targetPoint.y - 250);
      const newCenter = mapInstance.containerPointToLatLng(offsetPoint);

      const container = document.createElement('div');
      const root = createRoot(container);
      root.render(
        <MapCliPopUp
          cliente={client}
          setores={setores}
          setoresFilter={setoresFilter}
          handleRemoveCliSector={handleRemoveCliSector}
          showEditAddressModal={showEditAddressModal}
          setShowEditAddressModal={setShowEditAddressModal}
          showVinculoSetor={showVinculoSetor}
          setShowVinculoSetor={setShowVinculoSetor}
          showEditVisit={showEditVisit}
          setShowEditVisit={setShowEditVisit}
        />,
      );

      mapInstance.closePopup();

      const popup = L.popup({
        closeButton: true, // Ensure the close button is present
        autoClose: false, // Prevent auto-closing when another popup opens
        closeOnClick: true, // Close when the map is clicked
        className: 'cli-pop-up',
      })
        .setLatLng([lat, lng])
        .setContent(container)
        .addTo(mapInstance);

      mapInstance.setView(newCenter, mapInstance.getZoom());

      popup.on('popupclose', () => {
        setSelectedCli(null);
      });
      const removePopup = () => {
        if (mapInstance.hasLayer(popup)) {
          mapInstance.removeLayer(popup);
        }
        setSelectedCli(null);
      };

      popup.on('remove', removePopup);
    };

    const drawMarker = useCallback(
      (ctx, lat, lng, radius, color) => {
        const point = mapInstance.current.latLngToContainerPoint([lat, lng]);

        ctx.beginPath();
        ctx.arc(point.x, point.y, radius, 0, 2 * Math.PI);
        ctx.strokeStyle = 'white';
        ctx.lineWidth = 2;
        ctx.stroke();

        ctx.beginPath();
        ctx.arc(point.x, point.y, radius - 1, 0, 2 * Math.PI);
        ctx.fillStyle = color;
        ctx.fill();
      },
      [mapInstance],
    );

    useEffect(() => {
      if (!mapRef.current) {
        return;
      }

      mapInstance.current = L.map(mapRef.current, {
        zoomControl: false,
      }).setView(lastCoordinateZoomed.current ?? [-19.9286, -43.9409], allowMapCenterAndZoom.current ? 5 : 12);

      lastCoordinateZoomed.current =
        allowMapCenterAndZoom && !allowMapCenterAndZoom.current ? [-19.9286, -43.9409] : lastCoordinateZoomed.current;

      // allowMapCenterAndZoom && allowMapCenterAndZoom.current && handleMapStartZoomCentering(mapInstance.current);

      const layers = mapLayersObject;

      const savedLayer = localStorage.getItem('selectedLayer');
      const defaultLayer = 'Voyager';

      const initialLayer = layers[savedLayer] ? savedLayer : defaultLayer;
      layers[initialLayer].addTo(mapInstance.current);

      const layerControl = L.control.layers(layers).addTo(mapInstance.current);

      mapInstance.current.on('baselayerchange', (e) => {
        localStorage.setItem('selectedLayer', e.name);
      });

      L.control.zoom({ position: 'bottomleft' }).addTo(mapInstance.current);

      const redrawCanvas = () => drawCanvas();

      const handleZoomLevel = () => {
        const zoomLevelToStopCluster = 12;
        const zoomLevelNew = mapInstance && mapInstance.current ? mapInstance.current.getZoom() : 0;
        setZoomLevel(zoomLevelNew ? zoomLevelNew : 7);
        if (
          (zoomLevel >= zoomLevelToStopCluster && zoomLevelNew < zoomLevelToStopCluster) ||
          (zoomLevel < zoomLevelToStopCluster && zoomLevelNew >= zoomLevelToStopCluster)
        ) {
          redrawCanvas();
        }
      };

      mapInstance.current.on('moveend', redrawCanvas);
      mapInstance.current.on('resize', redrawCanvas);
      mapInstance.current.on('zoomend', handleZoomLevel);

      drawCanvas();

      mapInstance.current.on('click', (event) => {
        const clickPos = mapInstance.current.mouseEventToContainerPoint(event.originalEvent);

        if (isDrawing) return;
        const { lat, lng } = event.latlng;
        isDrawing && setPolygonCoordinates((prevCoords) => [...prevCoords, [lat, lng]]);

        let showPopUp = true;
        clientes?.filtered?.forEach((client) => {
          const { lat: clientLat, lng: clientLng, radius } = client;
          const point = mapInstance.current.latLngToContainerPoint([clientLat, clientLng]);
          const distance = Math.sqrt((clickPos.x - point.x) ** 2 + (clickPos.y - point.y) ** 2);

          if (distance <= radius) {
            if (noSectorControl.isSelected && noSectorControl.isFetched && showPopUp) {
              setSelectedCli(client);
              setShowMapMenu(false);
              showPopUp = false;
              return;
            } else if (showPopUp) {
              setSelectedCli(client);
              showPopUp = false;
            }
          }
        });
      });

      if (
        noSectorControl.isSelected &&
        ((!noSectorControl.isFetched && clientes.noSector.length === 0) || clientes.noSector.length === 0) &&
        setores?.unfiltered?.length > 0
      ) {
        setLoader({
          show: noSectorControl.error ? false : true,
          text: 'Por favor aguarde...',
        });
      } else {
        setLoader({
          show: false,
          text: 'Por favor aguarde...',
        });
      }

      handlePolygonDrawStart();

      return () => {
        mapInstance.current.off('moveend', redrawCanvas);
        mapInstance.current.off('resize', redrawCanvas);
        mapInstance.current.off('click');
        mapInstance.current.remove();
        mapInstance.current.off('zoomend', handleZoomLevel);
      };
    }, [clientes.filtered, setoresFilter.code]);

    const clusterLayerRef = useRef<L.MarkerClusterGroup | null>(null);

    const clusterLayer = useMemo(() => {
      const zoomLevel = mapInstance && mapInstance.current ? mapInstance.current.getZoom() : 0;
      if (!mapInstance.current || !noSectorControl.isSelected || zoomLevel >= 14) {
        return null;
      }

      const clusterGroup = L.markerClusterGroup({
        iconCreateFunction: (cluster) => {
          const count = cluster.getChildCount();
          const colors = ['#228f6b', '#4caf91', '#80d2b4', '#6ba08f', '#aad2c2'];
          const greenIndexToUse = count < 100 ? 4 : count < 250 ? 3 : count < 500 ? 2 : count < 750 ? 1 : 0;
          return L.divIcon({
            html: `<div style="
              background-color: ${colors[greenIndexToUse]};
              border-radius: 50%;
              width: 36px;
              height: 36px;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              ">
                <div style="
                background-color: ${colors[greenIndexToUse]}70;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-61.5%, -61.5%);
                width: 120%;
                height: 120%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-size: .9rem;
                font-weight: bolder;
                ">
            ${count}
              </div>
          </div>`,
            className: 'marker-cluster',
          });
        },
      });

      const clientsToFilter =
        setoresFilter.code === 'T' || (setoresFilter.code === 'A' && noSectorControl.isSelected)
          ? clientes.filtered
          : setoresFilter.code === 'A' && !noSectorControl.isSelected
          ? clientes.filtered.filter((cli) => cli.SETOR_ATIVO == 'S')
          : setoresFilter.code === 'I'
          ? clientes.filtered.filter((cli) => cli.SETOR_ATIVO == 'N')
          : clientes.filtered;

      clientsToFilter?.forEach((client) => {
        if (!client) {
          return;
        }
        const { lat, lng, color, SETOR_ATIVO } = client;

        const marker = L.marker([lat, lng], {
          icon: L.divIcon({
            className: 'custom-marker',
            html: `<div style="background-color: ${
              SETOR_ATIVO == 'N' && setoresFilter.code === 'A' ? '#000000' : color
            }; width: 16px; height: 16px; border-radius: 50%; border: 3px solid white;"></div>`,
          }),
        });

        marker.on('click', () => {
          setSelectedCli(client);
          setShowMapMenu(false);
        });

        clusterGroup.addLayer(marker);
      });

      return clusterGroup;
    }, [mapInstance.current, noSectorControl.isSelected, clientes.noSector.length, clientes.filtered, zoomLevel]);

    const drawCanvas = () => {
      if (!mapInstance?.current) {
        return;
      }

      const actualZoomLevel = mapInstance.current.getZoom() || 0;
      const blockCluster = actualZoomLevel >= 14 || (!noSectorControl.isSelected && !noSectorControl.isFetched);

      if (clusterLayerRef.current) {
        mapInstance.current.removeLayer(clusterLayerRef.current);
        clusterLayerRef.current = null;
      }

      if (blockCluster) {
        const canvas = document.createElement('canvas');
        const size = mapInstance.current.getSize();
        canvas.width = size.x;
        canvas.height = size.y;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        let bounds = null;
        try {
          bounds = mapInstance.current.getBounds();
        } catch (err) {
          console.log('Err :', err);
        }

        const clientsToFilter =
          setoresFilter.code === 'T' || (setoresFilter.code === 'A' && noSectorControl.isSelected)
            ? clientes.filtered
            : setoresFilter.code === 'A' && !noSectorControl.isSelected
            ? clientes.filtered?.filter((cli) => cli && cli.SETOR_ATIVO == 'S')
            : setoresFilter.code === 'I'
            ? clientes.filtered?.filter((cli) => cli && cli.SETOR_ATIVO == 'N')
            : clientes.filtered;

        clientsToFilter?.forEach(({ lat, lng, radius, color, SETOR_ATIVO }) => {
          if (bounds && bounds.contains([lat, lng])) {
            drawMarker(ctx, lat, lng, radius, SETOR_ATIVO == 'N' && setoresFilter.code === 'A' ? '#000000' : color);
          }
        });

        try {
          if (canvasLayerRef.current) {
            mapInstance.current.removeLayer(canvasLayerRef.current);
          }

          const boundsOverlay = mapInstance.current.getBounds(); // Ensure bounds match the current map view
          canvasLayerRef.current = L.imageOverlay(canvas.toDataURL(), boundsOverlay);
          canvasLayerRef.current.addTo(mapInstance.current);
        } catch (err) {
          console.log('Err :', err);
        }

        return;
      }

      if (canvasLayerRef.current) {
        mapInstance.current.removeLayer(canvasLayerRef.current);
        canvasLayerRef.current = null;
      }

      if (clusterLayer) {
        clusterLayer.addTo(mapInstance.current);
        clusterLayerRef.current = clusterLayer;
      }
    };

    useEffect(() => {
      if (clientes?.filtered?.length > 0 && mapInstance.current) {
        drawCanvas();
        handleMapStartZoomCentering(mapInstance.current);
      }
    }, [clientes.filtered]);

    useEffect(() => {
      if (polygonLayerRef.current) {
        mapInstance.current.removeLayer(polygonLayerRef.current);
      }

      if (polygonCoordinates.length > 1) {
        polygonLayerRef.current = L.polygon(polygonCoordinates, { color: 'blue' }).addTo(mapInstance.current);
      }
    }, [polygonCoordinates]);

    const handleMapStartZoomCentering = (mapInstance: L.Map) => {
      if (!allowMapCenterAndZoom.current) {
        allowMapCenterAndZoom.current = !allowMapCenterAndZoom.current ? true : false;
        return;
      }
      let markersCli = clientes?.unfiltered;

      if (selectedSetores && selectedSetores.length == 1 && selectedSetores[0].CODSETOR == -1) {
        for (let i = 0; i < clientes?.noSector?.length; i++) {
          if (+clientes?.noSector[i].LATITUDE != 0 && +clientes?.noSector[i].LONGITUDE != 0) {
            updateMapCenter(clientes?.noSector[i], mapInstance, 6);
            return;
          }
        }
      }

      if (clientes?.unfiltered?.length > 0 && selectedSetores && selectedSetores[selectedSetores.length - 1]) {
        for (let i = 0; i < clientes?.unfiltered?.length; i++) {
          if (clientes?.unfiltered[i]?.CODSETOR == selectedSetores[selectedSetores.length - 1].CODSETOR) {
            updateMapCenter(clientes?.unfiltered[i], mapInstance, 6);
            const ktBody = document.getElementById('kt_body');
            if (ktBody) {
              ktBody.setAttribute('data-kt-aside-minimize', 'on');
            }
            return;
          }
        }
      }
      if (clientes?.unfiltered?.length > 0) {
        updateMapCenter(markersCli[0], mapInstance, 6);
      } else if (markersCli.length > 0) {
        markersCli = markersCli.reverse();
        for (let i = 0; i < markersCli.length; i++) {
          let cliCoordData: any = markersCli[i];
          if (markersCli[0] && +cliCoordData.LATITUDE !== 0 && +cliCoordData.LONGITUDE !== 0) {
            updateMapCenter(cliCoordData, mapInstance);
            break;
          }
        }
      }
    };

    const [selectedPolygon, setSelectedPolygon] = useState<any>(null);

    useEffect(() => {
      if (selectedPolygon) {
        handleShowManagePolygonPopUp();
      }
    }, [selectedPolygon]);

    const enableEditing = () => {
      if (selectedPolygon) {
        handleRemovePolygonById(selectedPolygon._leaflet_id.toString(), true);
      }
    };

    const handlePolygonRemoval = () => {
      if (selectedPolygon) {
        dialog
          .show({
            showConfirmButton: true,
            confirmButtonText: 'Sim, remover',
            showCancelButton: true,
            cancelButtonText: 'Não, voltar',
            icon: 'question',
            title: 'Confirmar exclusão',
            text: 'Tem certeza que deseja remover esse polygono?',
          })
          .then((res) => {
            if (res.isConfirmed) {
              handleRemovePolygonById(selectedPolygon._leaflet_id.toString(), true);
              removeAllPopups();
              setSelectedPolygon(null);
            }
          });
      }
    };
    const openPopups = [];
    const handleShowManagePolygonPopUp = () => {
      const popupContainer = document.createElement('div');
      let popup;
      const removePopUp = () => {
        ReactDOM.unmountComponentAtNode(popupContainer);
        if (popup) popup.remove();
        const index = openPopups.indexOf(popup);
        if (index !== -1) openPopups.splice(index, 1); // Remove popup from the list
      };

      ReactDOM.render(
        <ManagePolygon
          enableEditing={enableEditing}
          handlePolygonRemoval={handlePolygonRemoval}
          removePopUp={removePopUp}
          polygon={selectedPolygon._leaflet_id}
        />,
        popupContainer,
      );

      if (mapInstance.current && selectedPolygon) {
        const polygonBounds = selectedPolygon.getBounds();
        const rightEdgeLatLng = L.latLng(polygonBounds.getSouth(), polygonBounds.getEast());

        const targetPoint = mapInstance.current.latLngToContainerPoint(rightEdgeLatLng);
        const offsetPoint = L.point(targetPoint.x + 170, targetPoint.y - 100);
        const newCenter = mapInstance.current.containerPointToLatLng(offsetPoint);

        popup = L.popup({
          closeButton: true,
          autoClose: true,
          closeOnClick: false,
          className: 'custom-popup',
        })
          .setLatLng(newCenter)
          .setContent(popupContainer)
          .openOn(mapInstance.current);

        openPopups.push(popup);

        popup.on('popupclose', () => {
          ReactDOM.unmountComponentAtNode(popupContainer);
          const index = openPopups.indexOf(popup);
          if (index !== -1) openPopups.splice(index, 1);
          setSelectedPolygon(null);
        });

        popup.on('remove', () => {
          setSelectedPolygon(null);
        });

        setTimeout(() => {
          const currentZoom = mapInstance.current.getZoom();
          const offsetPoint2 = L.point(targetPoint.x + 200, targetPoint.y - 325);
          const newCenter2 = mapInstance.current.containerPointToLatLng(offsetPoint2);
          mapInstance.current.setView(newCenter2, currentZoom);
        }, 100);
      }
    };

    const removeAllPopups = () => {
      openPopups.forEach((popup) => popup.remove());
      openPopups.length = 0;
    };

    const polygonsRef = useRef<L.FeatureGroup | null>(null);

    const drawControlRef = useRef<L.Control.Draw | null>(null);

    const handlePolygonDrawStart = () => {
      if (!mapInstance.current) return;

      if (polygonsRef.current === null) {
        polygonsRef.current = new L.FeatureGroup();
        mapInstance.current.addLayer(polygonsRef.current);
      }

      drawControlRef.current = new L.Control.Draw({
        edit: {
          featureGroup: polygonsRef.current,
        },
        draw: {
          polygon: {
            allowIntersection: false,
            shapeOptions: { color: '#42bb7d' },
          },
          polyline: false,
          circle: false,
          rectangle: false,
          marker: false,
          circlemarker: false,
        },
      });

      if (mapInstance.current) {
        mapInstance.current.addControl(drawControlRef.current);
      }

      mapInstance.current.on(L.Draw.Event.CREATED, (e: any) => {
        const layer = e.layer;
        polygonsRef.current?.addLayer(layer);

        const newPolygon = savePolygon(layer); // Save the newly created polygon
        savePolygonsToLocalStorage(newPolygon); // Immediately save it to localStorage
      });
    };

    function savePolygon(layer: L.Layer) {
      if (layer instanceof L.Polygon) {
        const latLngs = layer.getLatLngs() as L.LatLng[][];
        const outerRing = latLngs[0];
        const geoJsonCoordinates = convertLatLngsToGeoJSON(outerRing);

        return {
          color: '#42bb7d',
          coordinates: outerRing,
          area: calculatePolygonArea({
            type: 'Polygon',
            coordinates: [geoJsonCoordinates],
          }),
          id: (layer as L.Polygon & { _leaflet_id: number })._leaflet_id,
        };
      }
    }

    function savePolygonsToLocalStorage(newPolygon: PolygonRegionProps) {
      const existingPolygons = JSON.parse(localStorage.getItem('vmais_polygons') || '[]');
      const allPolygons = [...existingPolygons, newPolygon]; // Include the newly created polygon

      localStorage.setItem('vmais_polygons', JSON.stringify(allPolygons));
      renderSavedPolygons();
    }

    function convertLatLngsToGeoJSON(latLngs: L.LatLng[]): number[][] {
      const coordinates = latLngs.map((latLng) => [latLng.lng, latLng.lat]);
      if (
        coordinates[0][0] !== coordinates[coordinates.length - 1][0] ||
        coordinates[0][1] !== coordinates[coordinates.length - 1][1]
      ) {
        coordinates.push(coordinates[0]);
      }

      return coordinates;
    }

    function calculatePolygonArea(polygon: turf.Polygon): number {
      const areaInSquareMeters = turf.area(polygon);
      return areaInSquareMeters;
    }

    function updatePolygonsInLocalStorage() {
      const existingPolygons = JSON.parse(localStorage.getItem('vmais_polygons') || '[]');
      const polygons: PolygonRegionProps[] = existingPolygons?.filter(
        (poly: PolygonRegionProps) => poly.id != selectedPolygon._leaflet_id,
      );
      localStorage.setItem('vmais_polygons', JSON.stringify(polygons));
      renderSavedPolygons();
    }

    const polygonRefs = useRef<Map<string, L.Polygon>>(new Map());

    const renderSavedPolygons = () => {
      handleRemoveAllPolygons();
      const savedPolygons = localStorage.getItem('vmais_polygons');
      const polygonsArray = JSON.parse(savedPolygons);

      if (!savedPolygons) {
        return;
      }

      if (!Array.isArray(polygonsArray)) {
        console.log('Invalid polygons data in localStorage');
        return;
      }

      polygonsArray.forEach((polygonData: PolygonRegionProps) => {
        if (!polygonData.coordinates || !polygonData.id) {
          console.log('Invalid polygon data:', polygonData);
          return;
        }

        const polygon: L.Polygon = L.polygon(polygonData.coordinates, {
          weight: 1,
          color: polygonData.color,
          className: 'polygon-vm' + polygonData.id,
        }).addTo(mapInstance.current);
        (polygon as any)._leaflet_id = String(polygonData.id);

        polygonRefs.current.set(String(polygonData.id), polygon);

        polygon.on('click', () => {
          setSelectedPolygon(polygon);
        });
      });
    };

    const handleRemovePolygonById = (polygonId: string, update: boolean) => {
      const pathElement = document.querySelector(`.polygon-vm${polygonId}`);
      pathElement.remove();

      if (update) {
        updatePolygonsInLocalStorage();
      }
    };

    const handleRemoveAllPolygons = () => {
      const pathElements = document.querySelectorAll('[class*="polygon-vm"]');
      pathElements.forEach((pathElement) => {
        pathElement.remove();
      });
    };

    return (
      <>
        {/* <LoaderEffectIndependentPage show={showMapLoading} text="Por favor aguarde, buscando clientes do mapa..." /> */}
        {!showMapNoDataFound && <div ref={mapRef} style={{ height: '100vh', width: '100%' }} />}

        {showMapNoDataFound && <NoDataFound />}

        {showEditAddressModal && (
          <MapEditAddress
            showModal={showEditAddressModal}
            setShowModal={setShowEditAddressModal}
            cliData={selectedCli}
          />
        )}
        {showVinculoSetor && (
          <MapVinculoCli showVinculoSetor={showVinculoSetor} setShowVinculoSetor={setShowVinculoSetor} />
        )}
        {showEditVisit && <MapEditVisit showModal={showEditVisit} setShowModal={setShowEditVisit} />}
      </>
    );
  } catch (err) {
    trackGCatchError(err, 'mapContainer.index.tsx');
  }
};

export default MapContainer;
