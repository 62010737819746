import { useQueryClient } from '@tanstack/react-query';
import { postGroupClient } from 'client/api/groupClients';
import { useLoaderEffect } from 'providers/loaderEffect';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

interface FormAddGroupClients {
  codEmpresa: string;
  codFilial: string;
  nomeGrupo: string;
}

export const useAddGroupClients = () => {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormAddGroupClients>({});

  const values = watch();
  const navigate = useNavigate();

  enum DefaultValues {
    COMPANY = 'Selecione uma empresa.',
    FILIAL = 'Selecione uma filial.',
  }
  const queryClient = useQueryClient();
  const handleAddGroupClients = async () => {
    try {
      await postGroupClient({
        codempresa: Number(values.codEmpresa),
        codfilial: Number(values.codFilial),
        nomeGrupo: values.nomeGrupo,
        ativo: 'S',
      });
      queryClient.invalidateQueries(['group-clients-filiais']);
      toast.success('Grupo criado com sucesso!');
      navigate('/grupo-clientes');
    } catch {
      toast.error('Não foi possível criar Grupo.');
    }
  };

  const handleClearFilial = () => {
    setValue('codFilial', DefaultValues.FILIAL);
  };

  const isDefaultCompany = (value) => value !== DefaultValues.COMPANY;
  const isDefaultFilial = (value) => value !== DefaultValues.FILIAL;

  return {
    isDefaultCompany,
    isDefaultFilial,
    DefaultValues,
    handleSubmit,
    control,
    values,
    setValue,
    errors,
    handleAddGroupClients,
    handleClearFilial,
  };
};
