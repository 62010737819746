import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useMenuUsuario } from 'client/hooks';
import { setMenus } from 'modules/auth/reducer/auth';
import { MainLayout } from 'modules/main/components';
import { useAppDispatch } from 'store';
import EditMapForm from 'modules/mapa/pages/EditMapa/EditMapForm';
import { ListGroupClients } from 'modules/groupClients/pages/ListGroupClients';
import { ListGroupClientMembers } from 'modules/groupClients/pages/GroupClientMembers/ListGroupClientMembers';
import { AnaliseForms } from 'modules/formulario/pages/AnaliseFormularios/AnaliseForms.index';
import { AcompanhamentoDiario, CadastroMapa, CadastroMapaForm, CadastroPonto, Roteirizador } from 'modules/mapa/pages';
import { AgendaFeriados, FotosProdutos } from 'modules/cadastro/pages';
import { DashboardWrapper } from 'modules/main/pages';
import { CalendarRoterizador } from 'components/FullCalendar';
import { NovoPedido, Pedido } from 'modules/pedido/pages';
import { EditUserProfile } from 'modules/usuario/pages/EditUserProfile';
import { AddCompany } from 'modules/companies/AddCompany';
import { EditCompany } from 'modules/companies/EditCompany';
import {
  Relatorios7000List,
  Relatorios7000Designer,
  Relatorios7000New,
  Relatorios7000Player,
  Relatorios7000Edit,
} from 'modules/relatorios/pages';
import { SqlPage } from 'modules/vmaissql/pages';
import { ItensMenu } from 'modules/itensMenu/pages';
import { ResetUserAccess, UsuarioItensMenu } from 'modules/acessoUsuario/pages';
import { Companies } from 'modules/companies';
import { RegraComercialPage } from 'modules/regrasComerciais/pages';
import { KpiList } from 'modules/kpi';
import KpiView from 'modules/kpi/pages/kpiView/kpiView.index';
import { KpiProvider } from 'modules/kpi/kpi.context';
import { VerbasHistorico, VerbasList } from 'modules/verbas';
import { VerbasProvider } from 'modules/verbas/verbas.context';
import { AnaliseRoteiroProvider } from 'modules/mapa/pages/AnaliseRoteiro/analiseRoteiro.context';
import AnaliseRoteiro from 'modules/mapa/pages/AnaliseRoteiro/analiseRoteiro.index';
import { MapProvider } from 'modules/mapa/pages/Regioes/regioes.context';
import RegioesV2 from 'modules/mapa/pages/Regioes/regioes.index';
import ControleVisitas from 'modules/mapa/pages/ControleVisitas/controleVisitas.index';
import AnaliseVisitas from 'modules/mapa/pages/AnaliseVisitas/analiseVisitas.index';
import { ParamsRcaProvider } from 'modules/parametrosVendedor/pages/paramRca.context';
import { ParamRcaList, ParamRcaEdit } from 'modules/parametrosVendedor/pages';
import { CadastroFormularios, RepostaFormularios, RespostaFormularioItem } from 'modules/formulario/pages';
import { ParamsGeralProvider } from 'modules/parametrosGeral/paramGeral.context';
import { ParamGeralConfig, ParamGeralList } from 'modules/parametrosGeral/pages';
import { PedidoProvider } from 'modules/pedido/pedidos.context';

const PrivateRoutes = () => {
  const dispatch = useAppDispatch();
  const { data } = useMenuUsuario();

  useEffect(() => {
    if (data) {
      dispatch(setMenus(data));
    }
  }, [data]);

  return (
    <Routes>
      <Route element={<MainLayout />}>
        {/*  */}
        <Route path="dashboard" element={<DashboardWrapper />} />
        {/*  */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        {/*  */}
        <Route path="sql" element={<SqlPage />} />
        {/*  */}
        <Route path="foto-produto" element={<FotosProdutos />} />
        {/*  */}
        <Route
          path="parametros/vendedor/*"
          element={
            <ParamsRcaProvider>
              <Routes>
                <Route path="lista" element={<ParamRcaList />} />
                <Route path="editar/:codEmpresa/:codUsuario/:codVendedor" element={<ParamRcaEdit />} />
              </Routes>
            </ParamsRcaProvider>
          }
        />
        {/*  */}
        <Route
          path="parametros/geral/*"
          element={
            <ParamsGeralProvider>
              <Routes>
                <Route path="lista" element={<ParamGeralList />} />
                <Route path="configurar" element={<ParamGeralConfig />} />
              </Routes>
            </ParamsGeralProvider>
          }
        />
        {/*  */}
        <Route path="cadastro/agenda-feriados" element={<AgendaFeriados />} />
        {/*  */}
        <Route path="formulario" element={<CadastroFormularios />} />
        <Route path="respostas" element={<RepostaFormularios />} />
        <Route path="respostas/itens/:id" element={<RespostaFormularioItem />} />
        <Route path="pesquisas/analise-dados" element={<AnaliseForms />} />
        {/*  */}
        <Route path="itens-menu" element={<ItensMenu />} />
        {/*  */}
        <Route path="acesso-usuarios" element={<UsuarioItensMenu />} />
        {/*  */}
        <Route path="mapa" element={<CadastroMapa />} />
        <Route path="mapa/editar/:codEmpresa/:id" element={<EditMapForm />} />
        <Route path="mapa/novo/:codEmpresa" element={<CadastroMapaForm />} />
        <Route path="mapa/acompanhamento-diario" element={<AcompanhamentoDiario />} />
        {/*  */}
        <Route
          path="regioes/*"
          element={
            <MapProvider>
              <Routes>
                <Route path=":codEmpresa/:codUser/:codMapa" element={<RegioesV2 />} />
              </Routes>
            </MapProvider>
          }
        />
        {/*  */}
        <Route
          path="mapa/regioes/*"
          element={
            <MapProvider>
              <Routes>
                <Route path=":codEmpresa/:codUser/:codMapa" element={<RegioesV2 />} />
              </Routes>
            </MapProvider>
          }
        />
        {/*  */}
        <Route path="roteirizador/:codMapa/:codSetor/:codVendedor/:codEmpresa/:codUsuario" element={<Roteirizador />} />
        {/*  */}
        <Route
          path="cadastro/ponto/:codEmpresa/:codMapa/:codVendedor/:codSetor/:codUsuario"
          element={<CadastroPonto />}
        />
        {/*  */}
        <Route
          path="agenda-vendedor/:codEmpresa/:codVendedor/:codMapa/:codSetor/:codUsuario"
          element={<CalendarRoterizador />}
        />
        {/*  */}
        <Route
          path="mapa/analise-roteiro/*"
          element={
            <AnaliseRoteiroProvider>
              <Routes>
                <Route path="" element={<AnaliseRoteiro />} />
                <Route path=":codVendedor" element={<AnaliseRoteiro />} />
              </Routes>
            </AnaliseRoteiroProvider>
          }
        />
        {/*  */}
        <Route path="controle-visitas" element={<ControleVisitas />} />
        {/*  */}
        <Route path="analise-visitas" element={<AnaliseVisitas />} />
        {/*  */}
        <Route path="grupo-clientes" element={<ListGroupClients />} />
        <Route path="grupo-clientes/listar/membros/:codEmpresa" element={<ListGroupClientMembers />} />
        {/*  */}
        <Route
          path="pedido/*"
          element={
            <PedidoProvider>
              <Routes>
                <Route path="" element={<Pedido />} />
                <Route path="novo" element={<NovoPedido />} />
              </Routes>
            </PedidoProvider>
          }
        />
        {/*  */}
        <Route path="usuario" element={<EditUserProfile />} />
        {/*  */}
        <Route path="empresas" element={<Companies />} />
        <Route path="empresas/adicionar" element={<AddCompany />} />
        <Route path="empresas/editar" element={<EditCompany />} />
        {/*  */}
        <Route path="relatorios" element={<Relatorios7000List />} />
        <Route path="relatorios/novo" element={<Relatorios7000New />} />
        <Route path="relatorios/editar/:id" element={<Relatorios7000Edit />} />
        <Route path="relatorios/apurar/:id" element={<Relatorios7000Player />} />
        <Route path="relatorios/:id" element={<Relatorios7000Player />} />
        <Route path="relatorios/designer" element={<Relatorios7000Designer />} />
        {/*  */}
        <Route
          path="kpi/*"
          element={
            <KpiProvider>
              <Routes>
                <Route path="configurar" element={<KpiList />} />
                <Route path=":id" element={<KpiView />} />
                <Route path="" element={<KpiView />} />
              </Routes>
            </KpiProvider>
          }
        />
        {/*  */}
        <Route
          path="verbas/*"
          element={
            <VerbasProvider>
              <Routes>
                <Route path="" element={<VerbasList />} />
                <Route path="extrato" element={<VerbasHistorico />} />
                <Route path="extrato/supervisor" element={<VerbasHistorico />} />
                <Route path="extrato/vendedor" element={<VerbasHistorico />} />
              </Routes>
            </VerbasProvider>
          }
        />
        {/*  */}
        <Route path="regras-comerciais/:id" element={<RegraComercialPage />} />
        {/*  */}
        <Route path="acesso-usuarios/redefinir-acesso" element={<ResetUserAccess />} />
        {/*  */}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Route>
    </Routes>
  );
};

export { PrivateRoutes };
