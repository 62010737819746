import { BoxColumn, BoxRow } from 'components/Containers';
import styled, { keyframes } from 'styled-components';

const displayAnim = keyframes`
  0% {
    opacity: 0;
    scale: 0.85;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
`;

const rotate90 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
`;

interface IDisplayProps {
  display: boolean;
}

export const ClientMainBox = styled(BoxColumn)<IDisplayProps>`
  display: flex;
  flex-direction: column;
  box-shadow: none;
  background-color: transparent;
  padding: 0px;
  gap: 1px;
  transition: all 0.4s ease;
  height: ${(props) => (props.display ? 'auto' : 'auto')};
`;

export const ClientHeaderBox = styled(BoxRow)`
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  svg {
    font-size: 1.3rem;
    animation: ${rotate90} 0.5s ease forwards;
  }
`;

export const ClientHeaderTitle = styled(BoxRow)<IDisplayProps>`
  background-color: transparent;
  padding: 0px;
  box-shadow: none;
  font-size: 1.3rem;
  transition: all 0.6 ease;
  animation: ${displayAnim} 0.5s ease forwards;
`;

export const ClientContentBox = styled(BoxRow)<IDisplayProps>`
  background-color: transparent;
  box-shadow: none;
  width: 100%;
  justify-content: stretch;
  opacity: ${(props) => (props.display ? '1' : '0')};
  max-height: ${(props) => (props.display ? '152px' : '1px')};
  padding: ${(props) => (props.display ? '5px 1px' : '0px')};
  overflow: hidden;
  transition: all 0.6 ease;

  @media (max-width: 1060px) {
    max-height: ${(props) => (props.display ? '172px' : '1px')};
    font-size: 0.9rem;
  }
`;

export const ClientInfoBox = styled(BoxColumn)`
  flex: 1;
  gap: 5px;
  height: 100%;
`;

export const ClientInfoTitle = styled(BoxRow)`
  background-color: transparent;
  padding: 0px;
  box-shadow: none;
  font-size: 1.1rem;
  gap: 5px;
  width: 100%;
  padding-bottom: 5px;
  border-bottom: 1px solid #4d51561f;
`;

export const ClientInfoContent = styled(BoxColumn)`
  flex: 1;
  box-shadow: none;
  padding: 0px;
  gap: 5px;
`;

export const ClientInfoContentRow = styled(BoxRow)`
  background-color: transparent;
  padding: 0px;
  box-shadow: none;
`;
